
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useArenaTicketAdd } from '@/modules/arena/ticket/model/add';
import { ArenaTicketTypeList } from '@/modules/arena/ticket/model/mixins';

export default defineComponent({
    name: 'ArenaTicketAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, create } = useArenaTicketAdd();

        return { v$, model, create, ArenaTicketTypeList };
    }
});
