import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const ChallengeAdd = () => import(/* webpackChunkName: "exoty" */ './view/add.vue');
const ChallengeEdit = () => import(/* webpackChunkName: "exoty" */ './view/edit.vue');
const ChallengeList = () => import(/* webpackChunkName: "exoty" */ './view/index.vue');
const ChallengeTranslate = () => import(/* webpackChunkName: "exoty" */ './view/translate.vue');

export default [
    {
        path: '/app/challenge',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'ChallengeList',
                component: ChallengeList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'ChallengeAdd',
                component: ChallengeAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                name: 'ChallengeEdit',
                component: ChallengeEdit,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/translate',
                name: 'ChallengeTranslate',
                component: ChallengeTranslate,
                meta: { requiresAuth: true }
            }
        ]
    }
];

export const CHALLENGE_ROUTER = {
    INDEX: () => router.resolve({ name: 'ChallengeList' }),
    ADD: () => router.resolve({ name: 'ChallengeAdd' }),
    EDIT: (id: any) => router.resolve({ name: 'ChallengeEdit', params: { id } }),
    TRANSLATE: (id: any) => router.resolve({ name: 'ChallengeTranslate', params: { id } })
};
