
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppSelect, AppCard, AppButton } from '@/components';
import { usePromotionItemTranslate } from '@/modules/promotion/item/model/translate';

export default defineComponent({
    name: 'PromotionItemTranslate',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppButton },
    setup() {
        const { model, v$, fetch, getListSupported, actions, localeSupportedOptions } = usePromotionItemTranslate();

        onMounted(async () => {
            await getListSupported();
        });

        return { v$, model, fetch, actions, localeSupportedOptions };
    }
});
