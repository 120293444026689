
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker } from '@/components';
import { useArenaItemAdd } from '@/modules/arena/item/model/add';
import { ArenaTicketTypeList } from '@/modules/arena/ticket/model/mixins';

export default defineComponent({
    name: 'ArenaItemAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker },
    setup() {
        const { model, v$, fetchAllBets, betOptions, actions } = useArenaItemAdd();

        onMounted(async () => {
            await fetchAllBets();
        });

        return { v$, model, actions, betOptions, ArenaTicketTypeList };
    }
});
