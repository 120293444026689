import { useAvatarColorList } from '@/modules/avatar/color/model/list';
import { useAvatarLayerList } from '@/modules/avatar/layer/model/list';
import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetch';
import { reactive, toRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useAvatarImageList() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const avatarImageStore = {
        getPage: () => store.getters['avatarImage/page'],
        setPage: (page: number) => store.dispatch('avatarImage/changePage', page)
    };

    const model = reactive({
        itemId: route.params.itemId,
        itemName: null,
        categoryId: null
    });

    const { colorOption, fetchList: fetchColors } = useAvatarColorList();
    const { layerOption, fetchList: fetchLayers } = useAvatarLayerList();

    const { itemList, total, currentPage, changePage } = useFetchList(avatarService.image.list, {
        pagingStore: avatarImageStore,
        options: toRef(model, 'itemId')
    });

    const beforeFetch = async () => {
        await Promise.all([fetchColors(), fetchLayers()]);

        const response = await avatarService.item.get(model.itemId);
        if (response.code === 200) {
            model.itemName = response.data.name;
            model.categoryId = response.data.categoryId;
        } else {
            toast.error(response.data);
            router.push(AVATAR_ROUTER.ITEM.INDEX());
        }
    };

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this image?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.image.remove(model.itemId, id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await changePage(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    return { model, itemList, total, currentPage, changePage, beforeFetch, colorOption, layerOption, actions };
}
