
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useQuestTypeList } from '@/modules/quest/type/model/list';
import { formatDate, renderTimeUnit } from '@/utils/helpers';
import { QUEST_ROUTER } from '@/modules/quest/router';

export default defineComponent({
    name: 'QuestTypeList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, fetchList, actions } = useQuestTypeList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Status', display: (row: any) => renderOptionItem(displayTypeList, row.displayType) },
                { label: 'Time Unit', display: (row: any) => renderTimeUnit(row.timeUnit) },
                { label: 'Time Duration', field: 'timeDuration' },
                { label: 'Open Cycle', field: 'openCycle' },
                { label: 'Rest Cycle', field: 'restCycle' },
                { label: 'Status', display: (row: any) => renderOptionItem(statusList, row.isEnabled) },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'Next Start Time', display: (row: any) => formatDate(row.nextStartTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: QUEST_ROUTER.TYPE.DETAIL(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'info',
                        tooltip: 'Enable next',
                        isShow: row.canEnable,
                        onclick: () => actions.enableNext(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    }),
                    group: 2
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'info',
                        tooltip: 'Disable next',
                        isShow: row.canDisableNext,
                        onclick: () => actions.disableNext(row.id)
                    }),
                    group: 2
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, statusList, displayTypeList, renderOptionItem, QUEST_ROUTER };
    }
});

const statusList = [
    { label: 'Disabled', value: false, background: 'danger' },
    { label: 'Enabled', value: true, background: 'success' }
];

const displayTypeList = [
    { label: 'Special', value: 1, background: 'success' },
    { label: 'Normal', value: 0, background: 'info' }
];

function renderOptionItem(list: Array<any>, value: any) {
    const type = list.find(item => item.value === value);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
