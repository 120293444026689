import { useArenaItemMixins } from '@/modules/arena/item/model/mixins';
import { ARENA_ROUTER } from '@/modules/arena/router';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { arenaService } from '@/services/arena';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useArenaItemDetail() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { fetchAllBets, betMap } = useGameBetList();

    const model = reactive({
        id: null,
        name: null,
        betId: null,
        maxRejection: null,
        price: null,
        jackpotInit: null,
        jackpotWinPercent: null,
        jackpotDonation: null,
        prizes: null,
        status: null,
        ticketType: null,
        closeTime: null,
        startTime: null,
        endTime: null,
        publishTime: null,
        unpublishTime: null,
        canEnable: false,
        canDisable: false,
        canEdit: false,
        canDelete: false,
        canArchive: false,
        canClone: false,

        get betName() {
            return betMap.value.get(this.betId)?.label ?? '';
        }
    });

    const fetch = async () => {
        await fetchAllBets();

        const response = await arenaService.item.get(route.params.id);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(ARENA_ROUTER.ITEM.INDEX());
        }
    };

    const { archive, disable, enable, remove } = useArenaItemMixins().arenaActionMixins;
    const actions = {
        archive: () => archive(model.id, fetch),
        disable: () => disable(model.id, fetch),
        enable: () => enable(model.id, fetch),
        remove: () => remove(model.id, fetch)
    };

    return { model, fetch, actions };
}
