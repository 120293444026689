import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const DuplicateCycleDetail = () => import(/* webpackChunkName: "exoty" */ './cycle/view/detail.vue');
const DuplicateCycleList = () => import(/* webpackChunkName: "exoty" */ './cycle/view/index.vue');
const DuplicateCycleDeck = () => import(/* webpackChunkName: "exoty" */ './cycle/item/view/deck.vue');
const DuplicateCycleRank = () => import(/* webpackChunkName: "exoty" */ './cycle/item/view/rank.vue');
const DuplicateItemAdd = () => import(/* webpackChunkName: "exoty" */ './item/view/add.vue');
const DuplicateItemDetail = () => import(/* webpackChunkName: "exoty" */ './item/view/detail.vue');
const DuplicateItemEdit = () => import(/* webpackChunkName: "exoty" */ './item/view/edit.vue');
const DuplicateItemList = () => import(/* webpackChunkName: "exoty" */ './item/view/index.vue');
const DuplicateItemTranslate = () => import(/* webpackChunkName: "exoty" */ './item/view/translate.vue');
const DuplicateSetting = () => import(/* webpackChunkName: "exoty" */ './setting/view/edit.vue');

export default [
    {
        path: '/app/duplicate/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'setting',
                name: 'DuplicateSetting',
                component: DuplicateSetting,
                meta: { requiresAuth: true }
            },
            {
                path: 'item',
                component: EmptyRouterView,
                children: [
                    {
                        path: '',
                        name: 'DuplicateItemList',
                        component: DuplicateItemList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'DuplicateItemAdd',
                        component: DuplicateItemAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'DuplicateItemEdit',
                        component: DuplicateItemEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'DuplicateItemDetail',
                        component: DuplicateItemDetail,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/translate',
                        name: 'DuplicateItemTranslate',
                        component: DuplicateItemTranslate,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'cycle',
                component: EmptyRouterView,
                children: [
                    {
                        path: '',
                        name: 'DuplicateCycleList',
                        component: DuplicateCycleList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':cycleId/detail',
                        name: 'DuplicateCycleDetail',
                        component: DuplicateCycleDetail,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':cycleId/item/:itemId/deck',
                        name: 'DuplicateCycleDeck',
                        component: DuplicateCycleDeck,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':cycleId/item/:itemId/rank',
                        name: 'DuplicateCycleRank',
                        component: DuplicateCycleRank,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const DUPLICATE_ROUTER = {
    ITEM: {
        INDEX: () => router.resolve({ name: 'DuplicateItemList' }),
        ADD: () => router.resolve({ name: 'DuplicateItemAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'DuplicateItemEdit', params: { id } }),
        DETAIL: (id: any) => router.resolve({ name: 'DuplicateItemDetail', params: { id } }),
        TRANSLATE: (id: any) => router.resolve({ name: 'DuplicateItemTranslate', params: { id } })
    },
    CYCLE: {
        INDEX: () => router.resolve({ name: 'DuplicateCycleList' }),
        DETAIL: (cycleId: any) => router.resolve({ name: 'DuplicateCycleDetail', params: { cycleId } }),
        DECK: (cycleId: any, itemId: any) =>
            router.resolve({ name: 'DuplicateCycleDeck', params: { cycleId, itemId } }),
        RANK: (cycleId: any, itemId: any) => router.resolve({ name: 'DuplicateCycleRank', params: { cycleId, itemId } })
    },
    SETTING: () => router.resolve({ name: 'DuplicateSetting' })
};
