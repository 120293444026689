import { useVDialog } from '@/plugins/dialog/v-dialog';
import { roleService } from '@/services/role';
import { useFetchList } from '@/utils/useFetch';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useSysRoleList() {
    const dialog = useVDialog();
    const toast = useToast();
    const store = useStore();

    const roleStore = {
        getPage: () => store.getters['role/page'],
        setPage: (page: number) => store.dispatch('role/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(roleService.listRole, {
        pagingStore: roleStore
    });

    const actions = {
        remove: (accountId: any, role: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async result => {
                    if (result) {
                        const response = await roleService.removeRole(accountId, role);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, changePage, actions };
}
