
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useDuplicateCycleList } from '@/modules/duplicate/cycle/model/list';
import { RouterLink } from 'vue-router';
import { formatDate } from '@/utils/helpers';
import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { GAME_ROUTER } from '@/modules/game/router';

export default defineComponent({
    name: 'DuplicateItemList',
    components: { AppTable, AppCard },
    setup() {
        const { itemList, total, currentPage, beforeFetch, fetchList } = useDuplicateCycleList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                {
                    label: 'Item',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.itemName,
                        props: { to: DUPLICATE_ROUTER.ITEM.DETAIL(row.duplicateItemId) }
                    })
                },
                { label: 'Cycle', field: 'id' },
                {
                    label: 'Bet',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.betName,
                        props: { to: GAME_ROUTER.BET.DETAIL(row.betId ?? -1) }
                    })
                },
                { label: 'Unlocked Game', display: (row: any) => `${row.unlockedGame}/${row.itemGame}` },
                { label: 'Open Time', display: (row: any) => formatDate(row.openTime) },
                { label: 'Close Time', display: (row: any) => formatDate(row.closeTime) },
                { label: 'Rank Time', display: (row: any) => formatDate(row.rankTime) },
                { label: 'Archive Time', display: (row: any) => formatDate(row.archiveTime) },
                { label: 'Status', display: (row: any) => renderStatus(row.status) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: DUPLICATE_ROUTER.CYCLE.DETAIL(row.id)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-ticket-alt',
                        theme: 'info',
                        tooltip: 'Show deck',
                        href: DUPLICATE_ROUTER.CYCLE.DECK(row.id, row.duplicateItemId)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-crown',
                        theme: 'info',
                        tooltip: 'Show ranking',
                        href: DUPLICATE_ROUTER.CYCLE.RANK(row.id, row.duplicateItemId)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await beforeFetch();
            await fetchList(currentPage.value);
        });

        return { table, fetchList };
    }
});

const statusList = [
    { label: 'None', value: 0, background: 'secondary' },
    { label: 'Started', value: 1, background: 'success' },
    { label: 'Opened', value: 2, background: 'info' },
    { label: 'Closed', value: 3, background: 'warning' },
    { label: 'Ranked', value: 4, background: 'info' },
    { label: 'Archived', value: 5, background: 'success' },
    { label: 'Disabled', value: 6, background: 'secondary' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
