
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { formatDate, renderTimeUnit } from '@/utils/helpers';
import { useItemSettingList } from '@/modules/item/app/setting/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { ITEM_ROUTER } from '@/modules/item/router';

export default defineComponent({
    name: 'ItemSettingList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, canAddNewItem, fetch, actions } = useItemSettingList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Time Unit', display: (row: any) => renderTimeUnit(row.timeUnit) },
                { label: 'Time Duration', field: 'timeDuration' },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'Next Start Time', display: (row: any) => formatDate(row.nextStartTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        href: ITEM_ROUTER.APP.SETTING.EDIT(row.id)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetch();
        });

        return { canAddNewItem, table, ITEM_ROUTER };
    }
});
