import { useVDialog } from '@/plugins/dialog/v-dialog';
import { duplicateService } from '@/services/duplicate';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';

export default function () {
    const dialog = useVDialog();
    const toast = useToast();
    const router = useRouter();

    const model = reactive({
        canRemove: false,
        gameLeavePoint: null,
        deckType: null
    });

    const fetch = async () => {
        const response = await duplicateService.setting.get();
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(DUPLICATE_ROUTER.ITEM.INDEX());
        }
    };

    const actions = {
        update: async () => {
            const response = await duplicateService.setting.update(model.deckType, model.gameLeavePoint);
            if (response.code === 200) {
                toast.success('Update successfully');
            }

            fetch();
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await duplicateService.setting.remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                        }

                        fetch();
                    }
                }
            });
        }
    };

    return { model, fetch, actions };
}
