
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useTournamentLeaderboardList } from '@/modules/tournament/leaderboard/model/list';
import { formatDate, renderTimeUnit } from '@/utils/helpers';
import { TOURNAMENT_ROUTER } from '@/modules/tournament/router';

export default defineComponent({
    name: 'TournamentLeaderboardList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, currentPage, changePage, actions } = useTournamentLeaderboardList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Time Unit', display: (row: any) => renderTimeUnit(row.timeUnit) },
                { label: 'Time Duration', field: 'timeDuration' },
                { label: 'Publish Time', display: (row: any) => formatDate(row.publishTime) },
                { label: 'Unpublish Time', display: (row: any) => formatDate(row.unpublishTime) },
                { label: 'Status', display: (row: any) => renderStatus(row.status) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: TOURNAMENT_ROUTER.LEADERBOARD.DETAIL(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: TOURNAMENT_ROUTER.LEADERBOARD.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-medal',
                        theme: 'info',
                        tooltip: 'Show medal',
                        href: TOURNAMENT_ROUTER.LEADERBOARD.MEDAL(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            changePage(currentPage.value);
        });

        return { table, changePage, TOURNAMENT_ROUTER };
    }
});

const statusList = [
    { label: 'None', value: 0, background: 'secondary' },
    { label: 'Published', value: 1, background: 'success' },
    { label: 'Unpublished', value: 2, background: 'secondary' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
