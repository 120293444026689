import { TOURNAMENT_ROUTER } from '@/modules/tournament/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { tournamentService } from '@/services/tournament';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useTournamentLeaderboardDetail() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: route.params.id,
        name: '',
        status: null,
        timeUnit: 0,
        timeDuration: null,
        publishTime: null,
        unpublishTime: null,
        canEdit: false,
        canDelete: false
    });

    const fetch = async () => {
        const response = await tournamentService.leaderboard.get(model.id);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(TOURNAMENT_ROUTER.LEADERBOARD.INDEX());
        }
    };

    const actions = {
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await tournamentService.leaderboard.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(TOURNAMENT_ROUTER.LEADERBOARD.INDEX());
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, fetch, actions };
}
