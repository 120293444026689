export enum TransactionType {
    Credit = 0,
    Payment = 1,
    Ruby = 2
}

export const ActivityFilterType = {
    AccountId: { value: 0, label: 'Account Id' },
    IpAddress: { value: 1, label: 'Ip Address' },
    DeviceId: { value: 2, label: 'Device Id' }
};

export const AccountFilterType = {
    All: { value: 0, label: 'No filter' },
    Name: { value: 1, label: 'Name' },
    Email: { value: 2, label: 'Email' },
    FacebookID: { value: 3, label: 'Facebook Id' },
    AccountID: { value: 4, label: 'Account Id' },
    CreditBuy: { value: 5, label: 'Bought credit' }
};

export const Store = {
    Apple: { value: 0, label: 'Apple' },
    Google: { value: 1, label: 'Google' },
    Facebook: { value: 3, label: 'Facebook' },
    Website: { value: 4, label: 'Website' }
};

export const Options = {
    ActivityFilterType: Object.entries(ActivityFilterType).map(([, data]) => data),
    AccountFilterType: Object.entries(AccountFilterType).map(([, data]) => data),
    Store: Object.entries(Store).map(([, data]) => data)
};
