import { duplicateService } from '@/services/duplicate';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

export function useDuplicateCycleDeck() {
    const route = useRoute();
    const params = route.params;

    const deckId = ref({ itemId: params.itemId, cycleId: params.cycleId });
    const { itemList, fetchList } = useFetchList(duplicateService.cycle.getDeck, {
        options: deckId
    });

    return { itemList, fetchList };
}
