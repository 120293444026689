import { useVDialog } from '@/plugins/dialog/v-dialog';
import { arenaService } from '@/services/arena';
import { useToast } from 'vue-toastification';

export function useArenaItemMixins() {
    const toast = useToast();
    const dialog = useVDialog();

    const arenaActionMixins = {
        archive: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to archive this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.item.archive(id);
                        if (response.code === 200) {
                            toast.success('Archive successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        disable: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.item.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enable: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.item.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { arenaActionMixins };
}

export const ArenaStatusList = [
    { label: 'None', value: 0, background: 'secondary' },
    { label: 'Enabled', value: 1, background: 'success' },
    { label: 'Published', value: 2, background: 'info' },
    { label: 'Started', value: 3, background: 'warning' },
    { label: 'Closed', value: 4, background: 'secondary' },
    { label: 'Ended', value: 5, background: 'secondary' },
    { label: 'Unpublished', value: 6, background: 'success' },
    { label: 'Disabled', value: 7, background: 'info' },
    { label: 'Rewarded', value: 8, background: 'warning' },
    { label: 'Archive', value: 9, background: 'secondary' }
];
