
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useAccountActivity } from '@/modules/account/app/model/activity';
import { formatDate, renderOption } from '@/utils/helpers';
import { ActivityFilterType, Options } from '@/modules/account/app/model/mixins';
import { useRoute } from 'vue-router';
import { ACCOUNT_ROUTER } from '../../router';

export default defineComponent({
    name: 'AppAccountActivityList',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton },
    setup() {
        const route = useRoute();
        const { model, v$, itemList, total, currentPage, fetchList, actions } = useAccountActivity();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Account ID', field: 'accountId' },
                { label: 'Device ID', field: 'deviceId' },
                { label: 'IP Address', field: 'ipAddress' },
                { label: 'Platform', field: 'platform' },
                { label: 'Device OS', field: 'deviceOS' },
                { label: 'Store', display: (row: any) => renderOption(Options.Store, row.store) },
                { label: 'Version', field: 'version' },
                { label: 'Create Time', display: row => formatDate(row.createTime) },
                { label: 'Actions', field: 'accountId' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-map-marker-alt',
                        theme: 'info',
                        tooltip: 'Filter by address',
                        href: ACCOUNT_ROUTER.APP.ACTIVITY(ActivityFilterType.IpAddress.value, row.ipAddress)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-tablet-alt',
                        theme: 'info',
                        tooltip: 'Filter by device',
                        href: ACCOUNT_ROUTER.APP.ACTIVITY(ActivityFilterType.DeviceId.value, row.deviceId)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            model.filter.type = route.query?.type ? Number(route.query.type) : null;
            model.filter.criteria = (route.query?.criteria ?? '') as string;
            fetchList(currentPage.value);
        });

        return { model, itemList, v$, table, fetchList, actions, Options, ActivityFilterType };
    }
});
