
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton } from '@/components';
import useSubscription from '@/modules/subscription/item/model/detail';
import { formatDate, renderTimeUnit } from '@/utils/helpers';
import { SUBSCRIPTION_ROUTER } from '@/modules/subscription/router';

export default defineComponent({
    name: 'SubscriptionDetait',
    components: { AppCard, AppButton },
    setup() {
        const { model, fetch, remove } = useSubscription();

        onMounted(async () => {
            await fetch();
        });

        return { model, formatDate, renderTimeUnit, remove, SUBSCRIPTION_ROUTER };
    }
});
