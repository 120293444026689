import { ROLE_ROUTER } from '@/modules/role/router';
import { roleService } from '@/services/role';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const roleOption = [
    { value: 'ROLE_TESTER', label: 'Tester' },
    { value: 'ROLE_MODERATOR', label: 'Moderator' },
    { value: 'ROLE_MANAGER', label: 'Manager' },
    { value: 'ROLE_ADMIN', label: 'Admin' }
];

export default function () {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        email: null,
        role: null
    });

    const validations = {
        email: { required },
        role: { required }
    };

    const v$ = useVuelidate(validations, model);

    const addAppRole = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const isAccountId = /^\d+$/.test(model.email);
        const accountId = isAccountId ? model.email : null;
        const email = isAccountId ? null : model.email;

        const response = await roleService.addAppRole(accountId, email, model.role);
        if (response.code === 200) {
            toast.success('create successfully', { timeout: 2000 });
            router.push(ROLE_ROUTER.APP.INDEX());
        }
    };

    return { v$, model, roleOption, validations, fetch, addAppRole };
}
