
import { defineComponent, onMounted } from 'vue';
import {
    AppFormRow,
    AppCard,
    AppButton,
    AppSwitch,
    AppSelect,
    AppFileUpload,
    AppImageHolder,
    AppAvatarBreadcrumb
} from '@/components';
import { useAvatarImageEdit } from '@/modules/avatar/item/image/model/edit';

export default defineComponent({
    name: 'AvatarItemImageEdit',
    components: {
        AppFormRow,
        AppCard,
        AppButton,
        AppSwitch,
        AppSelect,
        AppFileUpload,
        AppImageHolder,
        AppAvatarBreadcrumb
    },
    setup() {
        const { model, colorOption, layerOption, beforeFetch, fetch, actions } = useAvatarImageEdit();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { model, colorOption, layerOption, actions };
    }
});
