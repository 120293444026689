import { BONUS_ROUTER } from '@/modules/bonus/router';
import { bonusService } from '@/services/bonus';
import { validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useBonusLoginAdd() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        name: '',
        type: null,
        value: '',
        adsBoost: '',
        adsRescue: 0,
        timeUnit: 0,
        timeDuration: 0,
        startTime: new Date()
    });

    const validations = {
        type: { required },
        timeDuration: { required, min: minValue(1), max: maxValue(32000) },
        value: { required, format: validateFormat(/^\d+(,\d+)*$/) },
        adsBoost: { required, format: validateFormat(/^\d+:\d+(,\d+:\d+)*$/) }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await bonusService.login.create(
            model.name,
            model.type,
            model.value,
            model.adsBoost,
            model.adsRescue,
            model.timeUnit,
            model.timeDuration,
            model?.startTime?.getTime() ?? null
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(BONUS_ROUTER.LOGIN.INDEX());
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, create };
}
