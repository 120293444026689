import http from '@/utils/axios';

const item = {
    create(
        name: any,
        cloneId: any,
        forAppId: any,
        fromTime: any,
        toTime: any,
        displayType: any,
        expectedPackage: any,
        prototypeId: any,
        image: any
    ) {
        return http.doPost(
            'admin/promotion',
            {
                name: name,
                cloneId: cloneId,
                forAppId: forAppId,
                fromTime: fromTime,
                toTime: toTime,
                displayType: displayType,
                expectedPackage: expectedPackage,
                prototypeId: prototypeId
            },
            {
                image: image
            }
        );
    },

    update(id: any, name: any, image: any) {
        return http.doPost(
            'admin/promotion/' + id,
            {
                name: name
            },
            {
                image: image
            }
        );
    },

    list(paging: { offset: any; limit: any }, filter: { status: number; rfmPersonaId: number }) {
        return http.doGet('admin/promotion', {
            ...paging,
            ...filter
        });
    },

    get(id: any) {
        return http.doGet('admin/promotion/' + id);
    },

    remove(id: any) {
        return http.doDelete('admin/promotion/' + id);
    },

    disable(id: any) {
        return http.doPut('admin/promotion/' + id + '/disable');
    },

    enable(id: any) {
        return http.doPut('admin/promotion/' + id + '/enable');
    },

    export() {
        return http.doGet('admin/promotion/export');
    }
};

const text = {
    get(id: any, locale: string) {
        return http.doGet('admin/promotion/' + id + '/text/' + locale);
    },

    update(id: any, locale: string, title: any, description: any) {
        return http.doPut('admin/promotion/' + id + '/text/' + locale, {
            title: title,
            description: description
        });
    },

    remove(id: any, locale: string) {
        return http.doDelete('admin/promotion/' + id + '/text/' + locale);
    }
};

const prototype = {
    create(name: any, personaList: any, packageList: any, priceList: any, creditList: any, rateList: any) {
        return http.doPost('admin/promotion/prototype', {
            name: name,
            personaList: personaList,
            packageList: packageList,
            priceList: priceList,
            creditList: creditList,
            rateList: rateList
        });
    },

    list(paging: { offset: any; limit: any }) {
        return http.doGet('admin/promotion/prototype', {
            ...paging
        });
    },

    listAll(forAppId: any) {
        return http.doGet('admin/promotion/prototype/all', {
            appId: forAppId
        });
    },

    get(prototypeId: any) {
        return http.doGet('admin/promotion/prototype/' + prototypeId);
    },

    remove(prototypeId: any) {
        return http.doDelete('admin/promotion/prototype/' + prototypeId);
    }
};

const code = {
    create(
        name: any,
        code: any,
        feature: any,
        data: any,
        quantity: any,
        receiveLimit: any,
        startTime: any,
        endTime: any
    ) {
        return http.doPost('admin/promotion/code', {
            name: name,
            code: code,
            feature: feature,
            data: data,
            quantity: quantity,
            receiveLimit: receiveLimit,
            startTime: startTime,
            endTime: endTime
        });
    },

    enable(itemId: any) {
        return http.doPut('admin/promotion/code/' + itemId + '/enable');
    },

    disable(itemId: any) {
        return http.doPut('admin/promotion/code/' + itemId + '/disable');
    },

    remove(itemId: any) {
        return http.doDelete('admin/promotion/code/' + itemId);
    },

    list(offset: any, limit: any) {
        return http.doGet('admin/promotion/code', {
            offset: offset,
            limit: limit
        });
    },

    get(itemId: any) {
        return http.doGet('admin/promotion/code/' + itemId);
    }
};

export const promotionService = {
    prototype,
    item,
    code,
    text
};
