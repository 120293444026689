import { ARENA_ROUTER } from '@/modules/arena/router';
import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { CREDIT_ROUTER } from '@/modules/credit/ticket/router';
import { QUEST_ROUTER } from '@/modules/quest/router';
import { SCRATCH_ROUTER } from '@/modules/scratch/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { itemService } from '@/services/item';
import { App } from '@/store/applications';
import { useFetchList } from '@/utils/useFetch';
import useVuelidate from '@vuelidate/core';
import { minLength } from '@vuelidate/validators';
import { reactive, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useSysItemList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const itemStore = {
        getPage: () => store.getters['item/page'],
        setPage: (page: number) => store.dispatch('item/changePage', page),
        getFilter: (): { forAppId: number; name: string } => store.getters['item/filter'],
        setFilter: () => store.dispatch('item/changeFilter', model.filter)
    };

    const model = reactive({
        filter: itemStore.getFilter(),
        selectedFeature: null
    });

    const { itemList, total, currentPage, changePage } = useFetchList(itemService.item.list, {
        pagingStore: itemStore,
        options: toRef(model, 'filter'),
        onSuccess: () => itemStore.setFilter()
    });

    const validations = {
        filter: {
            name: { minLength: minLength(3) }
        }
    };

    const v$ = useVuelidate(validations, model);

    const fetchList = async (page: number) => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        await changePage(page);
    };

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await itemService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            model.filter = itemStore.getFilter();
                            await changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        routerByFeature: () => {
            const data = featureList.find(feature => feature.value == model.selectedFeature);
            if (data) {
                router.push(data.route);
            }
        }
    };

    const appList = [
        { label: 'All', value: '' },
        ...store.getters['application/apps'].map((app: App) => ({ label: app.name, value: app.id }))
    ];

    const featureList = [
        { label: 'AvatarBundle', value: 1, background: 'info', route: AVATAR_ROUTER.BUNDLE.INDEX() },
        { label: 'ArenaTicket', value: 2, background: 'success', route: ARENA_ROUTER.TICKET.INDEX() },
        { label: 'QuestBoost', value: 3, background: 'info', route: QUEST_ROUTER.BOOST.INDEX() },
        { label: 'CreditTicket', value: 4, background: 'success', route: CREDIT_ROUTER.TICKET.INDEX() },
        { label: 'ScratchTicket', value: 5, background: 'info', route: SCRATCH_ROUTER.TICKET.INDEX() }
    ];

    return { model, v$, itemList, total, currentPage, fetchList, actions, appList, featureList };
}
