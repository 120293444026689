
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useAvatarDefaultList } from '@/modules/avatar/default/model/list';
import { AVATAR_ROUTER } from '@/modules/avatar/router';

export default defineComponent({
    name: 'SysItem',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppButton },
    setup() {
        const { model, itemList, total, currentPage, changePage, actions } = useAvatarDefaultList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Name', field: 'name' },
                { label: 'Type', display: (row: any) => renderOptionColumn(row.type, typeList) },
                { label: 'Gender', display: (row: any) => renderOptionColumn(row.gender, genderList) },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Is published', display: (row: any) => renderOptionColumn(row.isPublished, booleanList) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: AVATAR_ROUTER.COLOR.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            changePage(currentPage.value);
        });

        return { model, table, changePage, actions, typeList, AVATAR_ROUTER };
    }
});

const typeList = [
    { label: 'All', value: '', background: 'info' },
    { label: 'Bot', value: '1', background: 'info' },
    { label: 'Admin', value: '2', background: 'info' },
    { label: 'DefaultAvatar', value: '3', background: 'info' },
    { label: 'FakePlayer', value: '4', background: 'info' }
];

const genderList = [
    { label: 'Female', value: 0, background: 'success' },
    { label: 'Male', value: 1, background: 'info' }
];

const booleanList = [
    { label: 'Yes', value: true, background: 'success' },
    { label: 'No', value: false, background: 'secondary' }
];

function renderOptionColumn(value: any, list: Array<any>) {
    const data = list.find(item => item.value == value);
    return `<span class='badge badge-${data?.background}'>${data?.label ?? ''}</span>`;
}
