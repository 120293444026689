
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useDuplicateList } from '@/modules/duplicate/item/model/list';
import { RouterLink } from 'vue-router';
import { formatDate } from '@/utils/helpers';
import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { GAME_ROUTER } from '@/modules/game/router';

export default defineComponent({
    name: 'DuplicateItemList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, currentPage, beforeFetch, fetchListIncludedBetName, actions, selectableValues } =
            useDuplicateList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Type', display: (row: any) => selectableValues.render(selectableValues.types, row.type) },
                { label: 'Cost', field: 'cost' },
                {
                    label: 'Bet',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.betName,
                        props: { to: GAME_ROUTER.BET.DETAIL(row.betId) }
                    })
                },
                { label: 'Game', field: 'game' },
                { label: 'Publish Time', display: (row: any) => formatDate(row.publishTime) },
                { label: 'Unpublish Time', display: (row: any) => formatDate(row.unpublishTime) },
                {
                    label: 'Thumbnail',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.thumbnail, status: row.thumbnailStatus }
                    })
                },
                { label: 'Status', display: (row: any) => renderStatus(row.status) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: DUPLICATE_ROUTER.ITEM.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: DUPLICATE_ROUTER.ITEM.DETAIL(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        href: DUPLICATE_ROUTER.ITEM.TRANSLATE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable && row.status === 0,
                        onclick: () => actions.enable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await beforeFetch();
            await fetchListIncludedBetName(currentPage.value);
        });

        return { table, fetchListIncludedBetName, DUPLICATE_ROUTER };
    }
});

const statusList = [
    { label: 'None', value: 0, background: 'secondary' },
    { label: 'Enabled', value: 1, background: 'success' },
    { label: 'Published', value: 2, background: 'info' },
    { label: 'Unpublished', value: 3, background: 'warning' },
    { label: 'Disabled', value: 4, background: 'secondary' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
