import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { avatarService } from '@/services/avatar';
import useVuelidate from '@vuelidate/core';
import { numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarBackgroundEdit() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: route.params?.id,
        name: null,
        price: 0,
        isPublished: false,
        isDefault: false,
        isNew: false,
        image: null,
        imageFile: null,
        imageStatus: null,
        canEditDefault: true
    });

    const validations = {
        name: { required },
        price: { required, numeric }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await avatarService.background.get(model.id);
        if (response.code === 200) {
            Object.assign(model, response.data);
            model.canEditDefault = !model.isDefault;
        } else {
            router.push(AVATAR_ROUTER.BACKGROUND.INDEX());
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await avatarService.background.update(
            model.id,
            model.name,
            model.price,
            model.isPublished,
            model.isDefault,
            model.isNew,
            model.imageFile
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(AVATAR_ROUTER.BACKGROUND.INDEX());
        }
    };

    return { model, v$, fetch, update };
}
