
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useTransactionList } from '@/modules/transaction/text/model/list';
import { TRANSACTION_ROUTER } from '@/modules/transaction/router';

export default defineComponent({
    name: 'TransactionList',
    components: { AppTable, AppCard, AppFormRow, AppSelect },
    setup() {
        const { model, itemList, total, currentPage, changePage, beforeFetch } = useTransactionList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Code', field: 'type' },
                { label: 'Name', field: 'name' },
                { label: 'Content', field: 'content' },
                { label: 'Actions', field: 'type' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: TRANSACTION_ROUTER.TEXT.EDIT(row.type, model.locale)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await beforeFetch();
            await changePage(currentPage.value);
        });

        return { model, table, changePage };
    }
});
