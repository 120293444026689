import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const LeagueAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/league/view/add.vue');
const LeagueEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/league/view/edit.vue');
const LeagueList = () => import(/* webpackChunkName: "exoty" */ '@/modules/league/view/index.vue');
const LeagueRewardAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/league/tier/reward/view/add.vue');
const LeagueRewardEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/league/tier/reward/view/edit.vue');
const LeagueRewardList = () => import(/* webpackChunkName: "exoty"*/ '@/modules/league/tier/reward/view/index.vue');
const LeagueTierAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/league/tier/view/add.vue');
const LeagueTierEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/league/tier/view/edit.vue');
const LeagueTierList = () => import(/* webpackChunkName: "exoty" */ '@/modules/league/tier/view/index.vue');

export default [
    {
        path: '/app/league/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'LeagueList',
                component: LeagueList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'LeagueAdd',
                component: LeagueAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/edit',
                name: 'LeagueEdit',
                component: LeagueEdit,
                meta: { requiresAuth: true }
            },
            {
                path: ':itemId/tier',
                component: EmptyRouterView,
                children: [
                    {
                        path: '',
                        name: 'LeagueTierList',
                        component: LeagueTierList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'LeagueTierAdd',
                        component: LeagueTierAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':tier/edit',
                        name: 'LeagueTierEdit',
                        component: LeagueTierEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: ':itemId/tier/:tier/reward',
                component: EmptyRouterView,
                children: [
                    {
                        path: '',
                        name: 'LeagueRewardList',
                        component: LeagueRewardList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'LeagueRewardAdd',
                        component: LeagueRewardAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':position',
                        name: 'LeagueRewardEdit',
                        component: LeagueRewardEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const LEAGUE_ROUTER = {
    INDEX: () => router.resolve({ name: 'LeagueList' }),
    ADD: () => router.resolve({ name: 'LeagueAdd' }),
    EDIT: (id: any) => router.resolve({ name: 'LeagueEdit', params: { id } }),
    TIER: {
        INDEX: (itemId: any) => router.resolve({ name: 'LeagueTierList', params: { itemId } }),
        ADD: (itemId: any) => router.resolve({ name: 'LeagueTierAdd', params: { itemId } }),
        EDIT: (itemId: any, tier: any) => router.resolve({ name: 'LeagueTierEdit', params: { itemId, tier } }),

        REWARD: {
            INDEX: (itemId: any, tier: any) => router.resolve({ name: 'LeagueRewardList', params: { itemId, tier } }),
            ADD: (itemId: any, tier: any) => router.resolve({ name: 'LeagueRewardAdd', params: { itemId, tier } }),
            EDIT: (itemId: any, tier: any, position: any) =>
                router.resolve({ name: 'LeagueRewardEdit', params: { itemId, tier, position } })
        }
    }
};
