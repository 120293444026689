import { LEAGUE_ROUTER } from '@/modules/league/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { leagueService } from '@/services/league';
import { timeUnits } from '@/utils/helpers';
import { validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useLeagueEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: route.params.id,
        timeUnit: 1,
        timeDuration: 1,
        game: null,
        maxPlayer: null,
        basePoint: null,
        defaultPoint: null,
        rankRatio: null,
        startTime: new Date(),
        season: null,
        canDelete: false
    });

    const validations = {
        timeDuration: { required, numeric, min: minValue(1), max: maxValue(3200) },
        game: { required, numeric, min: minValue(0), max: maxValue(3200) },
        maxPlayer: { required, numeric, min: minValue(5), max: maxValue(200) },
        basePoint: { required, numeric, min: minValue(0), max: maxValue(3200) },
        defaultPoint: { required, numeric, min: minValue(0), max: maxValue(3200) },
        rankRatio: { format: validateFormat(/^((-?\d+)(,-?\d+)*)(;(-?\d+)(,-?\d+)*)*$/g) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await leagueService.item.get(model.id);
        if (response.code === 200) {
            response.data.startTime = new Date(response.data.startTime);
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(LEAGUE_ROUTER.INDEX());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await leagueService.item.update(
                model.id,
                model.timeUnit,
                model.timeDuration,
                model.game,
                model.maxPlayer,
                model.basePoint,
                model.defaultPoint,
                model.rankRatio,
                model.startTime.getTime()
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(LEAGUE_ROUTER.INDEX());
            } else {
                toast.error(response.data);
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await leagueService.item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(LEAGUE_ROUTER.INDEX());
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions, timeUnits };
}
