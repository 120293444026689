import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import useVuelidate from '@vuelidate/core';
import { minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarItemEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: route.params.itemId,
        categoryId: null,
        categoryName: null,
        name: '',
        price: 0,
        isPublished: false,
        isNew: false,
        isDefault: false,
        isSpecial: false,
        limitSale: 0,
        totalSale: null,
        canDelete: false
    });

    const validations = {
        name: { required },
        isPublished: { required },
        isNew: { required },
        isDefault: { required },
        isSpecial: { required },
        price: { required, min: minValue(0) },
        limitSale: { required, min: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        let response = await avatarService.item.get(model.id);
        if (response.code === 200) {
            Object.assign(model, response.data);

            response = await avatarService.category.get(model.categoryId);
            if (response.code === 200) {
                model.categoryName = response.data.name;
            } else {
                toast.error(response.data);
                router.push(AVATAR_ROUTER.CATEGORY.INDEX());
            }
        } else {
            toast.error(response.data);
            router.push(AVATAR_ROUTER.CATEGORY.INDEX());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await avatarService.item.update(
                model.id,
                model.name,
                model.price,
                model.isPublished,
                model.isNew,
                model.isDefault,
                model.isSpecial,
                model.limitSale
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(AVATAR_ROUTER.ITEM.INDEX({ categoryId: model.categoryId }));
            } else {
                toast.error(response.data);
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(AVATAR_ROUTER.ITEM.INDEX({ categoryId: model.categoryId }));
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
