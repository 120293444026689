
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker } from '@/components';
import { useArenaItemEdit } from '@/modules/arena/item/model/edit';
import { ArenaTicketTypeList } from '@/modules/arena/ticket/model/mixins';

export default defineComponent({
    name: 'ArenaItemEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker },
    setup() {
        const { model, v$, fetch, betOptions, actions } = useArenaItemEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, betOptions, ArenaTicketTypeList };
    }
});
