import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ './main.vue');

export default [
    {
        path: '/',
        name: 'Home',
        component: Main,
        meta: { requiresAuth: true }
    }
];

export const HOME_PAGE_ROUTER = () => router.resolve({ name: 'Home' });
