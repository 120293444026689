import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { promotionService } from '@/services/promotion';
import { validateDate, validateDateAfter } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { FEATURES, Options } from './mixins';

export function usePromotionCodeAdd() {
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const model = reactive({
        id: null,
        feature: route.query?.feature ? Number(route.query?.feature) : null,
        data: route.query?.data ? Number(route.query?.data) : null,
        name: '',
        code: '',
        status: null,
        quantity: 0,
        receiveLimit: -1,
        receiveCounter: 0,
        startTime: new Date(),
        endTime: new Date(),
        canEnable: false,
        canDisable: false,
        canDelete: false,

        get dataOption(): Array<any> {
            switch (this.feature) {
                case FEATURES.ARENA_TICKET:
                    return Options.arenas;
                case FEATURES.SCRATCH_TICKET:
                    return Options.scratchTickets;
                default:
                    return [{ label: this.data, value: this.data }];
            }
        }
    });

    const validations = {
        feature: { required },
        data: { required },
        name: { required },
        code: { required },
        quantity: { required, integer, minValue: minValue(1) },
        receiveLimit: { required, integer, minValue: minValue(-1) },
        startTime: { required, validateDate: validateDate(), validateDateAfter: validateDateAfter() },
        endTime: { required, validateDate: validateDate(), validateDateAfter: validateDateAfter('startTime') }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await promotionService.code.create(
            model.name,
            model.code,
            model.feature,
            model.data,
            model.quantity,
            model.receiveLimit,
            model.startTime.getTime(),
            model.endTime.getTime()
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(PROMOTION_ROUTER.CODE.INDEX());
        }
    };

    return { model, v$, Options, create };
}
