
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { useLeagueRewardList } from '@/modules/league/tier/reward/model/list';
import { IAction, IColumn } from '@/components/table/model';

export default defineComponent({
    name: 'LeagueTierList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, tierItem, fetchList, beforeFetch, actions, routes } = useLeagueRewardList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'Position', field: 'position' },
                { label: 'Tier Reward', field: 'tierReward' },
                { label: 'Star Reward', field: 'starReward' },
                { label: 'Ruby Reward', field: 'rubyReward' },
                { label: 'Actions', field: 'position' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        href: routes.edit(row.position)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.position)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await beforeFetch();
            await fetchList();
        });

        return { table, tierItem, fetchList, beforeFetch, routes };
    }
});
