import { TOURNAMENT_ROUTER } from '@/modules/tournament/router';
import { tournamentService } from '@/services/tournament';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useTournamentCycle() {
    const router = useRouter();
    const toast = useToast();

    const store = useStore();
    const tournamentCycleStore = {
        getPage: () => store.getters['tournamentCycle/page'],
        setPage: (page: number) => store.dispatch('tournamentCycle/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(tournamentService.cycle.list, {
        pagingStore: tournamentCycleStore
    });

    const fetchList = async (page: number) => {
        await changePage(page);

        const itemIds = new Set(itemList.value.map(cycle => cycle.tournamentLeaderboardId));
        const response = await tournamentService.leaderboard.getList(Array.from(itemIds).join(','));
        if (response.code !== 200) {
            toast.error(response.data);
            router.push(TOURNAMENT_ROUTER.LEADERBOARD.INDEX());
            return;
        }

        const itemInfo = {} as any;
        response.data.forEach((item: any) => (itemInfo[item.id] = item.name));

        itemList.value = itemList.value.map(cycle => ({
            ...cycle,
            leaderboardName: itemInfo[cycle.tournamentLeaderboardId]
        }));
    };

    return { itemList, total, currentPage, fetchList };
}
