export enum FEATURES {
    AVATAR_BUNDLE = 1,
    ARENA_TICKET = 2,
    QUEST_BOOST = 3,
    CREDIT_TICKET = 4,
    SCRATCH_TICKET = 5,
    ADD_CHIPS = 10
}

export const Options = {
    features: [
        // { label: 'AvatarBundle', value: 1, background: 'info', routeName: 'SysAvatarBundleList' },
        { label: 'ArenaTicket', value: 2, background: 'success', routeName: 'ArenaTicketList' },
        // { label: 'QuestBoost', value: 3, background: 'info', routeName: 'QuestBoostList' },
        { label: 'CreditTicket', value: 4, background: 'success', routeName: 'CreditTicketList' },
        { label: 'ScratchTicket', value: 5, background: 'info', routeName: 'ScratchTicketList' },
        { label: 'AddChips', value: 10, background: 'primary', routeName: null }
    ],
    arenas: [
        { label: 'ArenaStandard', value: 1, background: 'success' },
        { label: 'ArenaPremium', value: 2, background: 'info' }
    ],
    scratchTickets: [
        { label: 'ScratchSilver', value: 1, background: 'success' },
        { label: 'ScratchGolden', value: 2, background: 'info' }
    ],
    statusList: [
        { value: 0, label: 'None', background: 'secondary' },
        { value: 1, label: 'Enabled', background: 'info' },
        { value: 2, label: 'Started', background: 'success' },
        { value: 3, label: 'Ended', background: 'warning' },
        { value: 4, label: 'Disabled', background: 'warning' }
    ]
};
