
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useNewsList } from '@/modules/news/model/list';
import { NEWS_ROUTER } from '@/modules/news/router';

export default defineComponent({
    name: 'NewsList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, currentPage, changePage, actions, options } = useNewsList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Name', field: 'name' },
                {
                    label: 'Thumbnail',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.thumbnail, status: row.imageStatus }
                    })
                },
                { label: 'Status', display: (row: any) => options.render(options.statusList, row.status) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-pen-square',
                        theme: 'info',
                        tooltip: 'Edit content',
                        href: NEWS_ROUTER.CONTENT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        href: NEWS_ROUTER.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        href: NEWS_ROUTER.TRANSLATE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-copy',
                        theme: 'info',
                        tooltip: 'Clone',
                        href: NEWS_ROUTER.CLONE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable,
                        onclick: () => actions.enable(row.id)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    })
                },

                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            changePage(currentPage.value);
        });

        return { table, changePage, NEWS_ROUTER };
    }
});
