import { SUBSCRIPTION_ROUTER } from '@/modules/subscription/router';
import { subscriptionService } from '@/services/subscription';
import useVuelidate from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useSubscriptionEdit() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: route.params.id as string,
        name: '',
        maxReceivableTime: '',
        cycleChip: 0
    });

    const validations = {
        name: { required },
        maxReceivableTime: { required, integer, minValue: minValue(1) },
        cycleChip: { required, integer, minValue: minValue(1) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await subscriptionService().item.get(model.id);
        if (response.code === 200) {
            const result = response.data;
            model.name = result.name;
            model.maxReceivableTime = result.maxReceivableTime;
            model.cycleChip = result.cycleChip;
        } else {
            toast.error('Not found');
            router.push(SUBSCRIPTION_ROUTER.INDEX());
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await subscriptionService().item.update(
            model.id,
            model.name,
            model.maxReceivableTime,
            model.cycleChip
        );
        if (response.code === 200) {
            toast.success('Update successfully');
            router.push(SUBSCRIPTION_ROUTER.INDEX());
        }
    };

    return { model, v$, fetch, update };
}
