
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton } from '@/components';
import { useMixins } from '@/modules/promotion/code/model/detail';
import { formatDate, renderOption } from '@/utils/helpers';

export default defineComponent({
    name: 'PromotionCodeDetail',
    components: { AppCard, AppButton },
    setup() {
        const { model, Options, fetch, actions } = useMixins();

        onMounted(async () => {
            await fetch();
        });

        return { model, Options, formatDate, renderOption, actions };
    }
});
