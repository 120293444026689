import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { duplicateService } from '@/services/duplicate';
import { gameService } from '@/services/game';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useDuplicateCycleDetail() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const bets = {} as any;
    const model = reactive({ cycle: null as any });

    const beforeFetch = async () => {
        const [variantResponse, betResponse] = await Promise.all([
            gameService.variant.list(),
            gameService.bet.list({}, null)
        ]);

        if (variantResponse.code !== 200 || betResponse.code !== 200) {
            const errMessage = variantResponse.code !== 200 ? variantResponse.data : betResponse.data;
            toast.error(errMessage);
            router.push(DUPLICATE_ROUTER.CYCLE.INDEX());
            return;
        }

        const tmpVariants = variantResponse.data;
        const tmpBets = betResponse.data;

        for (let i = 0; i < tmpBets.length; i++) {
            const bet = tmpBets[i];
            for (let j = 0; j < tmpVariants.length; j++) {
                const variant = tmpVariants[j];
                if (bet.variantId === variant.id) {
                    bets[bet.id] = variant.name + ' - ' + bet.name;
                    break;
                }
            }
        }
    };

    const fetch = async () => {
        const cycleResponse = await duplicateService.cycle.get(route.params.cycleId);
        if (cycleResponse.code !== 200) {
            router.push(DUPLICATE_ROUTER.CYCLE.INDEX());
            return;
        }

        const cycle = cycleResponse.data;

        const duplicateResponse = await duplicateService.item.get(cycle.duplicateItemId);
        if (duplicateResponse.code !== 200) {
            router.push(DUPLICATE_ROUTER.CYCLE.INDEX());
            return;
        }

        const duplicate = duplicateResponse.data;
        cycle.itemName = duplicate.name;
        cycle.itemGame = duplicate.game;
        cycle.betId = duplicate.betId;
        cycle.betName = bets[duplicate.betId];

        model.cycle = cycle;
    };

    return { model, beforeFetch, fetch };
}
