import { LEAGUE_ROUTER } from '@/modules/league/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { leagueService } from '@/services/league';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useLeagueTierEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        itemId: route.params.itemId,
        tier: route.params.tier,
        name: '',
        point: 0,
        imageFile: null,
        image: null,
        imageStatus: null,
        season: null,
        canDelete: false
    });

    const validations = {
        name: { required },
        tier: { required, numeric, min: minValue(1), max: maxValue(3200) },
        point: { required, numeric, min: minValue(0), max: maxValue(3200) },
        imageFile: {}
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await leagueService.tier.get(model.itemId, model.tier);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(LEAGUE_ROUTER.INDEX());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await leagueService.tier.update(
                model.itemId,
                model.tier,
                model.name,
                model.point,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push({ name: 'LeagueTierList', params: { itemId: model.itemId } });
            } else {
                toast.error(response.data);
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await leagueService.tier.remove(model.itemId, model.tier);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(LEAGUE_ROUTER.TIER.INDEX(model.itemId));
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
