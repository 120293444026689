
import { defineComponent, computed, PropType } from 'vue';
import { RouteLocation, useRouter } from 'vue-router';
import * as bootstrap from 'admin-lte/plugins/bootstrap/js/bootstrap.min.js';

export default defineComponent({
    name: 'app-button',
    props: {
        icon: { type: String },
        block: { type: String, default: '' },
        theme: { type: String, default: '' },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        name: { type: String, default: '' },
        size: { type: String, default: 'md' },
        tooltip: { type: String, default: '' },
        href: { type: [String, Object] as PropType<string | (RouteLocation & { href: string })>, default: null }
    },

    setup(props) {
        const router = useRouter();

        const getIcon = computed(() => `fas ${props.icon}`);
        const getTheme = computed(() => `btn-${props.theme}`);
        const getSize = computed(() => `btn-${props.size}`);
        const getHref = computed(() => (typeof props.href === 'string' ? props.href : props.href?.href));
        const isDisabled = computed(() => props.loading || props.disabled);

        const handleClick = (event: any) => {
            if (props.href) {
                event.preventDefault();
                router.push(props.href);
            }
        };

        return { getIcon, getTheme, getSize, getHref, isDisabled, handleClick };
    },

    mounted() {
        if (this.tooltip.length) {
            new bootstrap.Tooltip(this.$el, {
                trigger: 'hover'
            });

            this.$el.addEventListener('click', () => {
                document.querySelectorAll('.tooltip').forEach(el => el.remove());
            });
        }
    }
});
