import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const SendMessage = () => import(/* webpackChunkName: "exoty" */ '@/modules/message/view/send.vue');

export default [
    {
        path: '/app/message',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'send',
                name: 'SendMessage',
                component: SendMessage,
                meta: { requiresAuth: true }
            }
        ]
    }
];

export const MESSAGE_ROUTER = {
    SEND: (accountIds: any) => router.resolve({ name: 'SendMessage', query: { accountIds } })
};
