import useVuelidate from '@vuelidate/core';
import { minValue, numeric, required, requiredIf } from '@vuelidate/validators';
import { reactive } from 'vue';

export function useMixins() {
    const model = reactive({
        id: null,
        name: null,
        feature: null,
        credit: null,
        price: null,
        priceId: null,
        packageId: null
    });

    const validations = {
        name: { required },
        packageId: { required },
        feature: { required },
        price: { required, numeric, minValue: minValue(0.01) },
        credit: { required, numeric, minValue: minValue(0) },
        priceId: { required: requiredIf(() => model.feature === 4) }
    };

    const v$ = useVuelidate(validations, model);

    return { v$, model, features };
}

const features = [
    { value: 0, label: 'Shop' },
    { value: 1, label: 'Promotion' },
    { value: 2, label: 'Quest' },
    { value: 3, label: 'Piggy Bank' },
    { value: 4, label: 'Subscription' },
    { value: 5, label: 'Scratch Ticket' }
];
