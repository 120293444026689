import { QUEST_ROUTER } from '@/modules/quest/router';
import { questService } from '@/services/quest';
import { validateDateAfter } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useQuestItemTime() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: route.params.itemId,
        name: null,
        startTime: null,
        endTime: null
    });

    const validations = {
        name: { required },
        startTime: { required },
        endTime: { validateDateAfer: validateDateAfter('startTime') }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await questService.item.get(model.id);
        if (response.code === 200) {
            const data = response.data;
            model.name = data.name;
            model.startTime = data.startTime ? new Date(data.startTime) : null;
            model.endTime = data.endTime ? new Date(data.endTime) : null;
        } else {
            toast.error(response.data);
            router.push(QUEST_ROUTER.ITEM.INDEX());
        }
    };

    const actions = {
        updateTime: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const startTime = model.startTime.getTime();
            const endTime = model?.endTime ? model.endTime.getTime() : null;

            const response = await questService.item.updateTime(model.id, startTime, endTime);
            if (response.code === 200) {
                toast.success('Successfully');
                router.push(QUEST_ROUTER.ITEM.INDEX());
            } else {
                toast.error(response.data);
            }
        }
    };

    return { model, v$, fetch, actions };
}
