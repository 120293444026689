import { LEAGUE_ROUTER } from '@/modules/league/router';
import { leagueService } from '@/services/league';
import { timeUnits } from '@/utils/helpers';
import { validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useLeagueAdd() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        timeUnit: 1,
        timeDuration: 1,
        game: 2,
        maxPlayer: 100,
        basePoint: 100,
        defaultPoint: 1000,
        rankRatio: '',
        startTime: new Date()
    });

    const validations = {
        timeDuration: { required, numeric, min: minValue(1), max: maxValue(3200) },
        game: { required, numeric, min: minValue(0), max: maxValue(3200) },
        maxPlayer: { required, numeric, min: minValue(5), max: maxValue(200) },
        basePoint: { required, numeric, min: minValue(0), max: maxValue(3200) },
        defaultPoint: { required, numeric, min: minValue(0), max: maxValue(3200) },
        rankRatio: { format: validateFormat(/^((-?\d+)(,-?\d+)*)(;(-?\d+)(,-?\d+)*)*$/g) }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await leagueService.item.create(
            model.timeUnit,
            model.timeDuration,
            model.game,
            model.maxPlayer,
            model.basePoint,
            model.defaultPoint,
            model.rankRatio,
            model.startTime.getTime()
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(LEAGUE_ROUTER.INDEX());
        }
    };

    return { model, v$, create, timeUnits };
}
