import { useIAPList } from '@/modules/iap/model/list';
import { SHOP_ROUTER } from '@/modules/shop/router';
import { shopService } from '@/services/shop';
import useVuelidate from '@vuelidate/core';
import { decimal, minValue, required } from '@vuelidate/validators';
import { reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useMixins() {
    const route = useRoute();
    const router = useRouter();

    const { iapOptions, fetchIapOptions } = useIAPList();

    const model = reactive({
        id: route.params?.id ?? null,
        name: '',
        price: null,
        packageId: null,
        selectedIAP: null,
        credit: null,
        isPublished: false,
        isBestseller: false,
        image: null,
        imageFile: null,
        thumbnail: null,
        thumbnailFile: null,
        canDelete: false,
        canClone: false
    });

    const validations = {
        name: { required },
        price: { required, decimal, minValue: minValue(0) },
        credit: { required, decimal, minValue: minValue(0) },
        isPublished: { required },
        isBestseller: { required },
        imageFile: {},
        thumbnailFile: {}
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchIapOptions();

        if (model.id) {
            const response = await shopService().item.get(model.id);
            if (response.code === 200) {
                Object.assign(model, response.data);
            } else {
                router.push(SHOP_ROUTER.INDEX());
            }
        }
    };

    const changeIAP = (iapId: number) => {
        iapOptions.value.forEach(item => {
            if (item.value == iapId) {
                model.packageId = item.data.packageId;
                model.price = item.data.price;
            }
        });
    };

    watch(() => model.selectedIAP, changeIAP);

    return { model, v$, fetch, iapOptions };
}
