import { useIAPList } from '@/modules/iap/model/list';
import { PIGGYBANK_ROUTER } from '@/modules/piggybank/router';
import { piggybankService } from '@/services/piggybank';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, numeric, required, requiredIf } from '@vuelidate/validators';
import { computed, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePiggybankAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { iapOptions, fetchIapOptions } = useIAPList();

    const model = reactive({
        id: null,
        name: null,
        packageId: '',
        price: 0,
        value: 0,
        baseLog: 0,
        fromAvgPayment: -1,
        toAvgPayment: 0,
        adsBoost: 0,
        adsBoostPercent: 0,
        adsBoostMin: 0,
        adsBoostSeconds: 0,
        image: null,
        imageStatus: null,
        imageFile: null,
        thumbnail: null,
        thumbnailStatus: null,
        thumbnailFile: null
    });

    const validations = {
        name: { required },
        packageId: { required },
        price: { required, numeric, min: minValue(1) },
        baseLog: { required, numeric, min: minValue(1) },
        value: { required, numeric, min: minValue(1) },
        adsBoost: { required, integer, min: minValue(0) },
        adsBoostPercent: { required, integer, min: minValue(0) },
        adsBoostMin: { required, integer, min: minValue(0) },
        adsBoostSeconds: { required, integer, min: minValue(0) },
        fromAvgPayment: { integer, min: minValue(-1) },
        toAvgPayment: {
            required: requiredIf(() => model.fromAvgPayment && model.fromAvgPayment >= 0),
            validValue: computed(() => minValue(model.fromAvgPayment + 1))
        }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchIapOptions();

        // clone case
        if (route.params.id) {
            model.id = route.params.id;

            const response = await piggybankService.item.get(model.id);
            if (response.code === 200) {
                const data = response.data;
                if (data.fromAvgPayment === -1) {
                    data.fromAvgPayment = null;
                }

                if (data.toAvgPayment === -1) {
                    data.toAvgPayment = null;
                }

                Object.assign(model, data);
            } else {
                toast.error(response.data);
                router.push(PIGGYBANK_ROUTER.ITEM.INDEX());
            }
        }
    };

    const actions = {
        createOrClone: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await piggybankService.item.create(
                model.id,
                model.name,
                model.packageId,
                model.price,
                model.value,
                model.baseLog,
                model.fromAvgPayment,
                model.toAvgPayment,
                model.adsBoost,
                model.adsBoostPercent,
                model.adsBoostMin,
                model.adsBoostSeconds,
                model.imageFile,
                model.thumbnailFile
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push(PIGGYBANK_ROUTER.ITEM.INDEX());
            } else {
                toast.error(response.data);
            }
        },
        selectIAP: (iapId: any) => {
            iapOptions.value.forEach(item => {
                if (item.value == iapId) {
                    model.packageId = item.data.packageId;
                    model.price = item.data.price;
                    model.value = item.data.credit;
                }
            });
        }
    };

    return { model, v$, fetch, actions, iapOptions };
}
