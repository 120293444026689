import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_error = _resolveComponent("app-error")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      type: "file",
      accept: _ctx.accept,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onValueChange($event)))
    }, null, 40, _hoisted_1),
    _createVNode(_component_app_error, { validations: _ctx.validations }, null, 8, ["validations"])
  ]))
}