import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const TournamentCycle = () => import(/* webpackChunkName: "exoty" */ './cycle/view/index.vue');
const TournamentLeaderboardAdd = () => import(/* webpackChunkName: "exoty" */ './leaderboard/view/add.vue');
const TournamentLeaderboardDetail = () => import(/* webpackChunkName: "exoty" */ './leaderboard/view/detail.vue');
const TournamentLeaderboardEdit = () => import(/* webpackChunkName: "exoty" */ './leaderboard/view/edit.vue');
const TournamentLeaderboardList = () => import(/* webpackChunkName: "exoty" */ './leaderboard/view/index.vue');
const TournamentLeaderboardMedal = () => import(/* webpackChunkName: "exoty" */ './leaderboard/view/medal.vue');
const TournamentMedalAdd = () => import(/* webpackChunkName: "exoty" */ './medal/view/add.vue');
const TournamentMedalEdit = () => import(/* webpackChunkName: "exoty" */ './medal/view/edit.vue');
const TournamentMedalList = () => import(/* webpackChunkName: "exoty" */ './medal/view/index.vue');

export default [
    {
        path: '/app/tournament/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'cycle/',
                name: 'TournamentCycle',
                component: TournamentCycle,
                meta: { requiresAuth: true }
            },
            {
                path: 'leaderboard/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'TournamentLeaderboardList',
                        component: TournamentLeaderboardList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'TournamentLeaderboardAdd',
                        component: TournamentLeaderboardAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'TournamentLeaderboardEdit',
                        component: TournamentLeaderboardEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'TournamentLeaderboardDetail',
                        component: TournamentLeaderboardDetail,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/medal',
                        name: 'TournamentLeaderboardMedal',
                        component: TournamentLeaderboardMedal,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'medal/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'TournamentMedalList',
                        component: TournamentMedalList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'TournamentMedalAdd',
                        component: TournamentMedalAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'TournamentMedalEdit',
                        component: TournamentMedalEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const TOURNAMENT_ROUTER = {
    CYCLE: () => router.resolve({ name: 'TournamentCycle' }),
    LEADERBOARD: {
        INDEX: () => router.resolve({ name: 'TournamentLeaderboardList' }),
        ADD: () => router.resolve({ name: 'TournamentLeaderboardAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'TournamentLeaderboardEdit', params: { id } }),
        DETAIL: (id: any) => router.resolve({ name: 'TournamentLeaderboardDetail', params: { id } }),
        MEDAL: (id: any) => router.resolve({ name: 'TournamentLeaderboardMedal', params: { id } })
    },
    MEDAL: {
        INDEX: () => router.resolve({ name: 'TournamentMedalList' }),
        ADD: () => router.resolve({ name: 'TournamentMedalAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'TournamentMedalEdit', params: { id } })
    }
};
