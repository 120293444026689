
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard } from '@/components';
import { useAccountTransaction } from '@/modules/account/app/model/transaction';
import { formatDate } from '@/utils/helpers';
import { IColumn } from '@/components/table/model';

export default defineComponent({
    name: 'AppAccountTransactionList',
    components: { AppTable, AppCard },
    setup() {
        const { model, itemList, total, currentPage, changePage } = useAccountTransaction();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Type', field: 'type' },
                { label: 'Title', field: 'title' },
                { label: 'Amount', field: 'amount' },
                { label: model.displayInfo.label, field: model.displayInfo.field },
                { label: 'Store', field: 'store' },
                { label: 'Date Time', display: row => formatDate(row.createTime) },
                { label: 'Detail', field: 'detail' }
            ] as Array<IColumn>
        });

        onMounted(() => {
            changePage(currentPage.value);
        });

        return { itemList, table, changePage, model };
    }
});
