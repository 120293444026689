import { useVDialog } from '@/plugins/dialog/v-dialog';
import { applicationService } from '@/services/applications';
import { chatService } from '@/services/chat';
import { useFetchList } from '@/utils/useFetch';
import useVuelidate from '@vuelidate/core';
import { email, minLength, numeric, requiredIf } from '@vuelidate/validators';
import { computed, reactive, toRef } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { AccountFilterType } from './mixins';

export function useAccountList() {
    const dialog = useVDialog();
    const store = useStore();
    const toast = useToast();

    const accountStore = {
        getPage: () => store.getters['account/page'],
        setPage: (page: number) => store.dispatch('account/changePage', page),
        getFilter: () => store.getters['account/filter'],
        setFilter: () => store.dispatch('account/changeFilter', { ...model.filter })
    };

    const model = reactive({
        filter: { ...accountStore.getFilter() }
    });

    const validations = {
        filter: {
            criteria: {
                requiredIf: requiredIf(() => model.filter.type !== AccountFilterType.All.value),
                valueValid: computed(() => {
                    switch (model.filter.type) {
                        case AccountFilterType.All.value:
                            return true;
                        case AccountFilterType.Name.value:
                            return minLength(3);
                        case AccountFilterType.Email.value:
                            return email;
                        case AccountFilterType.FacebookID.value:
                        case AccountFilterType.AccountID.value:
                        case AccountFilterType.CreditBuy.value:
                            return numeric;
                        default:
                            return false;
                    }
                })
            }
        }
    };

    const v$ = useVuelidate(validations, model);

    const { itemList, total, currentPage, changePage } = useFetchList(applicationService.account.list, {
        pagingStore: accountStore,
        options: toRef(model, 'filter'),
        onSuccess: () => accountStore.setFilter()
    });

    const fetchList = (page: number) => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        changePage(page);
    };

    const actions = {
        delete: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to delete this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.delete(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        undelete: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to undelete this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.undelete(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        ban: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to ban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.ban(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        unban: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to unban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.unban(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        mute: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to mute this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.mute(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        unmute: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to unmute this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.unmute(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        banChat: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to ban this account from chat?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.ban(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        unbanChat: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to unban this account from chat?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.unban(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        renderStatus: (row: any) => {
            const data = { label: '', background: 'warning' };
            if (row.isBanned) data.label = 'Ban';
            if (row.isBanChat) data.label = 'Ban chat';
            if (row.isMute) data.label = 'Mute';
            if (row.isDeleting) {
                data.background = 'danger';
                data.label = 'Deleting';
            }

            return `<span class='badge badge-${data.background}'>${data.label}</span>`;
        }
    };

    return { v$, model, itemList, total, currentPage, fetchList, actions };
}
