import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { rfmService } from '@/services/rfm';
import { useFetchList } from '@/utils/useFetch';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { ref } from 'vue';

export function usePromotionPrototypeList() {
    const dialog = useVDialog();
    const toast = useToast();
    const store = useStore();

    const promotionPrototypeStore = {
        getPage: () => store.getters['promotionPrototype/page'],
        setPage: (page: number) => store.dispatch('promotionPrototype/changePage', page)
    };

    const {
        itemList: itemsInPage,
        total,
        currentPage,
        changePage
    } = useFetchList(promotionService.prototype.list, {
        pagingStore: promotionPrototypeStore
    });

    const fetchItemsInPage = async (page: any) => {
        await changePage(page);

        let personaList: any;
        const personaResponse = await rfmService().persona.list();
        if (personaResponse.code === 200) {
            personaList = personaResponse.data;
        }

        itemsInPage.value.forEach(function (item: any) {
            item.personas.forEach(function (per1: any) {
                personaList.forEach(function (per2: any) {
                    if (per1.rfmPersonaId == per2.id) {
                        per1.rfmPersonaName = per2.name;
                    }
                });
            });
        });
    };

    const itemAll = ref([]);

    const fetchAll = async () => {
        let personaList: any;
        const personaResponse = await rfmService().persona.list();
        if (personaResponse.code === 200) {
            personaList = personaResponse.data;
        }

        const itemResponse = await promotionService.prototype.listAll(null);

        itemAll.value = itemResponse.data.map((item: any) => {
            item.personas.forEach(function (per1: any) {
                personaList.forEach(function (per2: any) {
                    if (per1.rfmPersonaId == per2.id) {
                        per1.rfmPersonaName = per2.name;
                    }
                });
            });

            return item;
        });
    };

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.prototype.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchItemsInPage(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    return { itemsInPage, fetchItemsInPage, total, currentPage, changePage, actions, itemAll, fetchAll };
}
