import { useQuestTypeList } from '@/modules/quest/type/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { questService } from '@/services/quest';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';

export function useQuestItemList() {
    const dialog = useVDialog();
    const toast = useToast();

    const typeId = ref('');
    const typeList = ref([]);
    const { itemList: questTypeList, fetchList: fetchQuestTypeList } = useQuestTypeList();
    const { itemList, total, fetchList } = useFetchList(questService.item.list, { options: typeId });

    const beforeFetch = async () => {
        await fetchQuestTypeList();

        typeList.value = questTypeList.value.map(type => {
            return { label: type.name, value: type.id };
        });
        typeList.value.unshift({ label: 'All', value: '' });
    };

    const actions = {
        disable: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.disable(itemId);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        disableNext: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.disableNext(itemId);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enable: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.enable(itemId);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enableNext: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.enableNext(itemId);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.remove(itemId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, fetchList, beforeFetch, typeId, typeList, actions };
}
