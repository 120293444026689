import { PROGRESS_ROUTER } from '@/modules/progress/router';
import { progressService } from '@/services/progress';
import { validateDateAfter } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useProgressAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        cloneId: null,
        name: '',
        fromTime: new Date(),
        toTime: new Date()
    });

    const validations = {
        name: { required },
        fromTime: { required, validateDate: validateDateAfter() },
        toTime: { required, validateDate: validateDateAfter('fromTime') }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        // case: clone progress
        const cloneId = Number(route.params.id ?? 0);
        if (cloneId > 0) {
            const response = await progressService.item.get(cloneId);
            if (response.code === 200) {
                const data = response.data;

                model.cloneId = cloneId;
                model.name = data.name;
                model.fromTime = new Date(data.fromTime);
                model.toTime = new Date(data.toTime);
            } else {
                toast.error(response.data);
                router.push(PROGRESS_ROUTER.ITEM.INDEX());
            }
        }
    };

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await progressService.item.create(
            model.cloneId,
            model.name,
            model.fromTime.getTime(),
            model.toTime.getTime()
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(PROGRESS_ROUTER.ITEM.INDEX());
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, fetch, create };
}
