
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useArenaTicketAdd } from '@/modules/arena/ticket/model/edit';
import { ArenaTicketTypeList } from '@/modules/arena/ticket/model/mixins';

export default defineComponent({
    name: 'ArenaTicketAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, fetch, actions } = useArenaTicketAdd();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, ArenaTicketTypeList };
    }
});
