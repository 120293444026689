
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton } from '@/components';
import { useAccountDetail } from '@/modules/account/app/model/detail';
import { formatDate, toDuration } from '@/utils/helpers';
import { ActivityFilterType, TransactionType } from '@/modules/account/app/model/mixins';
import * as bootstrap from 'admin-lte/plugins/bootstrap/js/bootstrap.min.js';
import { ACCOUNT_ROUTER } from '@/modules/account/router';
import { MESSAGE_ROUTER } from '@/modules/message/router';

export default defineComponent({
    name: 'Account Profile',
    components: { AppCard, AppButton },
    setup() {
        const { model, fetch, actions } = useAccountDetail();

        onMounted(async () => {
            await fetch();
            document.querySelectorAll('[data-toggle="tooltip"]').forEach(el => new bootstrap.Tooltip(el));
        });

        return {
            model,
            actions,
            formatDate,
            toDuration,
            TransactionType,
            ActivityFilterType,
            ACCOUNT_ROUTER,
            MESSAGE_ROUTER
        };
    }
});
