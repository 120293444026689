
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSwitch, AppAvatarBreadcrumb } from '@/components';
import { useAvatarItemAdd } from '@/modules/avatar/item/model/add';

export default defineComponent({
    name: 'AvatarItemAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSwitch, AppAvatarBreadcrumb },
    setup() {
        const { model, v$, fetch, create } = useAvatarItemAdd();

        onMounted(() => {
            fetch();
        });

        return { v$, model, create };
    }
});
