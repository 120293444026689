import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const ArenaSetting = () => import(/* webpackChunkName: "exoty" */ './setting/view/Edit.vue');
const ArenaItemAdd = () => import(/* webpackChunkName: "exoty" */ './item/view/Add.vue');
const ArenaItemClone = () => import(/* webpackChunkName: "exoty" */ './item/view/Clone.vue');
const ArenaItemDetail = () => import(/* webpackChunkName: "exoty" */ './item/view/Detail.vue');
const ArenaItemEdit = () => import(/* webpackChunkName: "exoty" */ './item/view/Edit.vue');
const ArenaItemList = () => import(/* webpackChunkName: "exoty" */ './item/view/Index.vue');
const ArenaItemMedal = () => import(/* webpackChunkName: "exoty" */ './item/view/Medal.vue');
const ArenaItemRank = () => import(/* webpackChunkName: "exoty" */ './item/view/Rank.vue');
const ArenaMedalAdd = () => import(/* webpackChunkName: "exoty" */ './medal/view/Add.vue');
const ArenaMedalEdit = () => import(/* webpackChunkName: "exoty" */ './medal/view/Edit.vue');
const ArenaMedalList = () => import(/* webpackChunkName: "exoty" */ './medal/view/Index.vue');
const ArenaTicketAdd = () => import(/* webpackChunkName: "exoty" */ './ticket/view/Add.vue');
const ArenaTicketEdit = () => import(/* webpackChunkName: "exoty" */ './ticket/view/Edit.vue');
const ArenaTicketList = () => import(/* webpackChunkName: "exoty" */ './ticket/view/Index.vue');

export default [
    {
        path: '/app/arena/',
        name: 'Arena',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'setting',
                name: 'ArenaSetting',
                component: ArenaSetting,
                meta: { requiresAuth: true }
            },
            {
                path: 'item',
                name: 'ArenaItem',
                component: EmptyRouterView,
                children: [
                    {
                        path: '',
                        name: 'ArenaItemList',
                        component: ArenaItemList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'ArenaItemAdd',
                        component: ArenaItemAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'ArenaItemEdit',
                        component: ArenaItemEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/clone',
                        name: 'ArenaItemClone',
                        component: ArenaItemClone,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'ArenaItemDetail',
                        component: ArenaItemDetail,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/medal',
                        name: 'ArenaItemMedal',
                        component: ArenaItemMedal,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/rank',
                        name: 'ArenaItemRank',
                        component: ArenaItemRank,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'medal/',
                name: 'ArenaMedal',
                component: EmptyRouterView,
                children: [
                    {
                        path: '',
                        name: 'ArenaMedalList',
                        component: ArenaMedalList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'ArenaMedalAdd',
                        component: ArenaMedalAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'ArenaMedalEdit',
                        component: ArenaMedalEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'ticket/',
                name: 'ArenaTicket',
                component: EmptyRouterView,
                children: [
                    {
                        path: '',
                        name: 'ArenaTicketList',
                        component: ArenaTicketList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'ArenaTicketAdd',
                        component: ArenaTicketAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':type/edit',
                        name: 'ArenaTicketEdit',
                        component: ArenaTicketEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const ARENA_ROUTER = {
    SETTING: () => router.resolve({ name: 'ArenaSetting' }),
    ITEM: {
        INDEX: () => router.resolve({ name: 'ArenaItemList' }),
        ADD: () => router.resolve({ name: 'ArenaItemAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'ArenaItemEdit', params: { id } }),
        CLONE: (id: any) => router.resolve({ name: 'ArenaItemClone', params: { id } }),
        DETAIL: (id: any) => router.resolve({ name: 'ArenaItemDetail', params: { id } }),
        MEDAL: (id: any) => router.resolve({ name: 'ArenaItemMedal', params: { id } }),
        RANK: (id: any) => router.resolve({ name: 'ArenaItemRank', params: { id } })
    },
    MEDAL: {
        INDEX: () => router.resolve({ name: 'ArenaMedalList' }),
        ADD: () => router.resolve({ name: 'ArenaMedalAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'ArenaMedalEdit', params: { id } })
    },
    TICKET: {
        INDEX: () => router.resolve({ name: 'ArenaTicketList' }),
        ADD: () => router.resolve({ name: 'ArenaTicketAdd' }),
        EDIT: (type: any) => router.resolve({ name: 'ArenaTicketEdit', params: { type } })
    }
};
