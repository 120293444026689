
import { defineComponent } from 'vue';
import { AppInput, AppButton } from '@/components';
import { useForgotPassword } from '@/modules/forgot-password/forgot-password';
import { LOGIN_ROUTER } from '@/modules/login/router';

export default defineComponent({
    name: 'ForgotPassword',
    components: { AppInput, AppButton },
    setup() {
        const { model, v$, actions } = useForgotPassword();

        return { v$, model, actions, LOGIN_ROUTER };
    }
});
