import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const SurpriseItemAdd = () => import(/* webpackChunkName: "exoty" */ './category/item/view/add.vue');
const SurpriseItemEdit = () => import(/* webpackChunkName: "exoty" */ './category/item/view/edit.vue');
const SurpriseItemList = () => import(/* webpackChunkName: "exoty" */ './category/item/view/index.vue');
const SurpriseCategoryAdd = () => import(/* webpackChunkName: "exoty" */ './category/view/add.vue');
const SurpriseCategoryEdit = () => import(/* webpackChunkName: "exoty" */ './category/view/edit.vue');
const SurpriseCategoryList = () => import(/* webpackChunkName: "exoty" */ './category/view/index.vue');
const SurpriseSetting = () => import(/* webpackChunkName: "exoty" */ './setting/view/edit.vue');

export default [
    {
        path: '/app/surprise/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'setting',
                name: 'SurpriseSetting',
                component: SurpriseSetting,
                meta: { requiresAuth: true }
            },
            {
                path: 'category/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'SurpriseCategoryList',
                        component: SurpriseCategoryList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'SurpriseCategoryAdd',
                        component: SurpriseCategoryAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'SurpriseCategoryEdit',
                        component: SurpriseCategoryEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':categoryId/item/',
                        component: EmptyRouterView,
                        meta: { requiresAuth: true },
                        children: [
                            {
                                path: '',
                                name: 'SurpriseItemList',
                                component: SurpriseItemList,
                                meta: { requiresAuth: true }
                            },
                            {
                                path: 'add',
                                name: 'SurpriseItemAdd',
                                component: SurpriseItemAdd,
                                meta: { requiresAuth: true }
                            },
                            {
                                path: ':itemId',
                                name: 'SurpriseItemEdit',
                                component: SurpriseItemEdit,
                                meta: { requiresAuth: true }
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export const SURPRISE_ROUTER = {
    SETTING: () => router.resolve({ name: 'SurpriseSetting' }),
    CATEGORY: {
        INDEX: () => router.resolve({ name: 'SurpriseCategoryList' }),
        ADD: () => router.resolve({ name: 'SurpriseCategoryAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'SurpriseCategoryEdit', params: { id } })
    },
    ITEM: {
        INDEX: (categoryId: any) => router.resolve({ name: 'SurpriseItemList', params: { categoryId } }),
        ADD: (categoryId: any) => router.resolve({ name: 'SurpriseItemAdd', params: { categoryId } }),
        EDIT: (categoryId: any, itemId: any) =>
            router.resolve({ name: 'SurpriseItemEdit', params: { categoryId, itemId } })
    }
};
