import { useAvatarColorList } from '@/modules/avatar/color/model/list';
import { useAvatarLayerList } from '@/modules/avatar/layer/model/list';
import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { avatarService } from '@/services/avatar';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarImageAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { colorOption, fetchList: fetchColors } = useAvatarColorList();
    const { layerOption, fetchList: fetchLayers } = useAvatarLayerList();

    const model = reactive({
        itemId: route.params.itemId,
        itemName: null,
        categoryId: null,
        colorId: null,
        layerId: null,
        isDefault: false,
        imageFile: null
    });

    const fetch = async () => {
        await Promise.all([fetchColors(), fetchLayers()]);

        const response = await avatarService.item.get(route.params.itemId);
        if (response.code === 200) {
            model.itemId = route.params.itemId;
            model.itemName = response.data.name;
            model.categoryId = response.data.categoryId;
            model.colorId = colorOption.value?.[0]?.value;
            model.layerId = layerOption.value?.[0]?.value;
        } else {
            toast.error(response.data);
            router.push(AVATAR_ROUTER.ITEM.IMAGE.INDEX());
        }
    };

    const create = async () => {
        const response = await avatarService.image.add(
            model.itemId,
            model.colorId,
            model.layerId,
            model.isDefault,
            model.imageFile
        );

        if (response.code === 200) {
            toast.success('Add successfully');
            router.push(AVATAR_ROUTER.ITEM.IMAGE.INDEX(model.itemId));
        }
    };

    return { model, colorOption, layerOption, fetch, create };
}
