import { CHALLENGE_ROUTER } from '@/modules/challenge/router';
import { useLocale } from '@/modules/locale/model/locale';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { challengeService } from '@/services/challenge';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useChallengeTranslate() {
    const dialog = useVDialog();
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const { localeSupportedOptions, getListSupported } = useLocale();

    const model = reactive({
        id: route.params.id,
        locale: null,
        title: '',
        hasData: false
    });

    const validations = {
        title: { required },
        locale: { required }
    };

    const v$ = useVuelidate(validations, model);

    const beforeFetch = async () => {
        await getListSupported();
        if (!localeSupportedOptions.value.length) {
            router.push(CHALLENGE_ROUTER.INDEX());
            return;
        }
    };

    const fetch = async () => {
        const response = await challengeService.getContent(model.id, model.locale);
        if (response.code === 200) {
            model.hasData = true;
            model.title = response.data.title;
        } else if (response.code === 404) {
            model.hasData = false;
            model.title = '';
        } else {
            router.push(CHALLENGE_ROUTER.INDEX());
        }
    };

    const actions = {
        updateText: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await challengeService.updateContent(model.id, model.locale, model.title);
            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },
        removeText: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await challengeService.removeContent(model.id, model.locale);

                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, beforeFetch, actions, localeSupportedOptions };
}
