
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppSelect, AppCard, AppInput, AppButton } from '@/components';
import useDuplicateSetting from '@/modules/duplicate/setting/model/edit';

export default defineComponent({
    name: 'DuplicateSetting',
    components: { AppFormRow, AppSelect, AppCard, AppInput, AppButton },
    setup() {
        const { model, fetch, actions } = useDuplicateSetting();

        onMounted(async () => {
            await fetch();
        });

        return { model, deckTypeList, actions };
    }
});

const deckTypeList = [
    { label: 'Belote', value: 1 },
    { label: 'Tarot', value: 2 },
    { label: 'Canasta', value: 3 },
    { label: 'Rummy', value: 4 }
];
