import { useVDialog } from '@/plugins/dialog/v-dialog';
import { subscriptionService } from '@/services/subscription';
import { useFetchList } from '@/utils/useFetch';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useSubscriptionList() {
    const dialog = useVDialog();
    const toast = useToast();
    const store = useStore();

    const subscriptionItemStore = {
        getPage: () => store.getters['subscriptionItem/page'],
        setPage: (page: number) => store.dispatch('subscriptionItem/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(subscriptionService().item.list, {
        pagingStore: subscriptionItemStore
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await subscriptionService().item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, changePage, actions };
}
