import { useRfmGroupList } from '@/modules/rfm/group/model/list';
import { RFM_ROUTER } from '@/modules/rfm/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { rfmService } from '@/services/rfm';
import useVuelidate from '@vuelidate/core';
import { minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useRfmRecency() {
    const dialog = useVDialog();
    const toast = useToast();
    const router = useRouter();
    const store = useStore();

    const { rfmGroupOptions, fetchRfmGroupOptions } = useRfmGroupList();
    const model = reactive({
        id: null,
        fromDay: 0,
        groupId: store.getters['rfm/groupId']
    });

    const validations = {
        fromDay: { required, numeric, minValue: minValue(0) },
        groupId: { required }
    };

    const v$ = useVuelidate(validations, model);

    const beforeFetch = async () => {
        await fetchRfmGroupOptions();
    };

    const fetch = async (id: any) => {
        const response = await rfmService().recency.get(id);
        if (response.code === 200) {
            model.id = response.data.id;
            model.fromDay = response.data.fromDay;
            model.groupId = response.data.groupId;
        } else if (response.code === 404) {
            model.id = null;
            model.fromDay = 0;
            model.groupId = null;
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await rfmService().recency.create(model.fromDay, model.groupId);
            if (response.code === 200) {
                toast.success('create successfully');
                router.push(RFM_ROUTER.RECENCY.INDEX());
            }
        },

        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await rfmService().recency.update(model.id, model.fromDay);
            if (response.code === 200) {
                toast.success('Update successfully');
                router.push(RFM_ROUTER.RECENCY.INDEX());
            }
        },

        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await rfmService().recency.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(RFM_ROUTER.RECENCY.INDEX());
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, rfmGroupOptions, beforeFetch, actions };
}
