import { chatService } from '@/services/chat';
import { useFetchList } from '@/utils/useFetch';
import { useMixins } from './mixins';

export function useChatSentenceList() {
    const { actions } = useMixins();
    const { itemList, total, fetchList } = useFetchList(chatService.sentence.list, null);

    return { itemList, total, fetchList, actions };
}
