import { useIAPList } from '@/modules/iap/model/list';
import { SUBSCRIPTION_ROUTER } from '@/modules/subscription/router';
import { subscriptionService } from '@/services/subscription';
import { timeUnits } from '@/utils/helpers';
import useVuelidate from '@vuelidate/core';
import { decimal, integer, maxValue, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useSubscriptionAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { iapOptions, fetchIapOptions } = useIAPList();

    const model = reactive({
        id: null,
        name: '',
        packageId: '',
        priceId: '',
        price: 0,
        maxReceivableTime: '',
        cycleChip: 0,
        timeUnit: 0,
        timeDuration: 0,
        totalLinked: null,
        startTime: new Date(),
        canDelete: false,
        canEdit: false
    });

    const validations = {
        name: { required },
        price: { required, decimal, minValue: minValue(0) },
        maxReceivableTime: { required, integer, minValue: minValue(1) },
        cycleChip: { required, integer, minValue: minValue(1) },
        timeDuration: { required, integer, minValue: minValue(1), maxValue: maxValue(59) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchIapOptions();

        // clone case:
        if (route.params.id) {
            const response = await subscriptionService().item.get(route.params.id as string);
            if (response.code === 200) {
                const result = response.data;
                result.startTime = new Date(result.startTime);
                Object.assign(model, result);
            } else {
                toast.error('Not found');
                router.push(SUBSCRIPTION_ROUTER.INDEX());
            }
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await subscriptionService().item.create(
                model.id,
                null,
                model.name,
                model.packageId,
                model.priceId,
                model.price,
                model.maxReceivableTime,
                model.cycleChip,
                model.timeUnit,
                model.timeDuration,
                model.startTime?.getTime() ?? null
            );

            if (response.code === 200) {
                toast.success('Success');
                router.push(SUBSCRIPTION_ROUTER.INDEX());
            }
        },
        selectIAP: (iapId: any) => {
            iapOptions.value.forEach(item => {
                if (item.value == iapId) {
                    model.packageId = item.data.packageId;
                    model.price = item.data.price;
                }
            });
        }
    };

    return { model, v$, timeUnits, iapOptions, fetch, actions };
}
