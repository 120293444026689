import { GAME_ROUTER } from '@/modules/game/router';
import { gameService } from '@/services/game';
import useVuelidate from '@vuelidate/core';
import { helpers, integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useGameVariantAdd() {
    const toast = useToast();
    const router = useRouter();

    const model = reactive({
        variantId: null,
        name: '',
        minPlayer: null,
        maxPlayer: null,
        hasTeam: false
    });

    const validations = {
        variantId: { required, integer, minValue: minValue(0) },
        name: { required },
        minPlayer: { required, integer, minValue: minValue(0) },
        maxPlayer: {
            required,
            validateConfirmPlayer: helpers.withMessage(
                'Max player must be greater than or equal min player',
                (value: number, vm: any) => value >= vm.minPlayer
            )
        }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await gameService.variant.create(
            model.variantId,
            model.name,
            model.minPlayer,
            model.maxPlayer,
            model.hasTeam
        );

        if (response.code === 200) {
            toast.success('Update successfully', { timeout: 2000 });
            router.push(GAME_ROUTER.VARIANT.INDEX());
        }
    };

    return { model, v$, create };
}
