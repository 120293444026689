import { duplicateService } from '@/services/duplicate';
import { useFetchList } from '@/utils/useFetch';
import { reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export function useDuplicateRank() {
    const route = useRoute();
    const store = useStore();

    const duplicateRankStore = {
        getPage: () => store.getters['duplicateRank/page'],
        setPage: (page: number) => store.dispatch('duplicateRank/changePage', page)
    };

    const model = reactive({
        filter: {
            accountId: null,
            duplicateItemId: route.params.itemId,
            duplicateCycleId: route.params.cycleId
        }
    });

    const { itemList, total, changePage, currentPage } = useFetchList(duplicateService.account.getRankList, {
        pagingStore: duplicateRankStore,
        options: toRef(model, 'filter')
    });

    return { model, itemList, total, currentPage, changePage };
}
