import { Option } from '@/components/Select.vue';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { gameService } from '@/services/game';
import { useFetchList } from '@/utils/useFetch';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';

export function useGameVariantList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { itemList, total, fetchList } = useFetchList(gameService.variant.list, null);

    const variantOptions = computed(() =>
        itemList.value.map((item: any) => {
            return { label: item.name, value: item.id } as Option;
        })
    );

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await gameService.variant.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            fetchList();
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await gameService.variant.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully', { timeout: 2000 });
                            fetchList();
                        }
                    }
                }
            });
        },
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await gameService.variant.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully', { timeout: 2000 });
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, fetchList, variantOptions, actions };
}
