import { Option } from '@/components/Select.vue';
import { useGameVariantList } from '@/modules/game/variant/model/list';
import { gameService } from '@/services/game';
import { useFetchList } from '@/utils/useFetch';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useMixins } from './mixins';

export function useGameBetList() {
    const route = useRoute();

    const variantId = ref(route.query?.variantId ? Number(route.query?.variantId) : null);
    const { variantOptions, fetchList: fetchVariantList } = useGameVariantList();
    const { options, remove } = useMixins();

    const { itemList, total, fetchList } = useFetchList(gameService.bet.list, {
        options: variantId
    });

    const fetch = async () => {
        if (variantId.value != null) {
            fetchList();
        }
    };

    // to use {betMap, betOptions}, fetchAllBets first
    const betMap = ref(new Map<number, Option>());
    const betOptions = computed(() => Array.from(betMap.value.values()));
    const fetchAllBets = async () => {
        betMap.value.clear();

        const [betResponse] = await Promise.all([gameService.bet.list({}, null), fetchVariantList()]);
        if (betResponse.code !== 200) {
            return;
        }

        const variantMap = new Map(variantOptions.value.map(({ value, label }) => [value, label]));

        for (const bet of betResponse.data) {
            const variantName = variantMap.get(bet.variantId);
            if (variantName) {
                const item = { value: bet.id, label: `${variantName} - ${bet.name}` };
                betMap.value.set(bet.id, item);
            }
        }
    };

    return {
        variantId,
        fetchVariantList,
        variantOptions,
        fetch,
        itemList,
        total,
        options,
        fetchAllBets,
        betMap,
        betOptions,
        remove
    };
}
