
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppInput, AppFormRow, AppAvatarBreadcrumb, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useAvatarCategorytList } from '@/modules/avatar/category/model/list';
import { onBeforeRouteUpdate } from 'vue-router';
import { AVATAR_ROUTER } from '@/modules/avatar/router';

export default defineComponent({
    name: 'AvatarCategory',
    components: { AppTable, AppCard, AppButton, AppInput, AppFormRow, AppAvatarBreadcrumb },
    setup() {
        const { model, v$, itemList, total, currentPage, fetchCategories, actions } = useAvatarCategorytList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Name', field: 'name' },
                { label: 'Position', field: 'position' },
                { label: 'Gender', display: (row: any) => renderOptionColumn(row.gender, genderList) },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Is body', display: (row: any) => renderOptionColumn(row.isBody, booleanList) },
                { label: 'Is published', display: (row: any) => renderOptionColumn(row.isPublished, booleanList) },
                { label: 'Is required', display: (row: any) => renderOptionColumn(row.isRequired, booleanList) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-leaf',
                        theme: 'info',
                        tooltip: 'View items',
                        href: AVATAR_ROUTER.ITEM.INDEX({ categoryId: row.id })
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: AVATAR_ROUTER.CATEGORY.EDIT(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-indent',
                        theme: 'info',
                        tooltip: 'View sub categories',
                        href: AVATAR_ROUTER.CATEGORY.SUB(row.id)
                    }),
                    group: 2
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchCategories(currentPage.value);
        });

        onBeforeRouteUpdate(to => {
            model.query.parentId = to.query.parentId;
            model.query.name = to.query.name as string;
            fetchCategories(1);
        });

        return { model, v$, table, fetchCategories, actions, AVATAR_ROUTER };
    }
});

const genderList = [
    { label: 'Female', value: 0, background: 'success' },
    { label: 'Male', value: 1, background: 'info' }
];

const booleanList = [
    { label: 'Yes', value: true, background: 'success' },
    { label: 'No', value: false, background: 'secondary' }
];

function renderOptionColumn(value: any, list: Array<any>) {
    const data = list.find(item => item.value == value);
    return `<span class='badge badge-${data?.background}'>${data?.label ?? ''}</span>`;
}
