
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppImageHolder } from '@/components';
import { useProgressLevelEdit } from '@/modules/progress/level/model/edit';

export default defineComponent({
    name: 'ProgressLevelEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppImageHolder },
    setup() {
        const { model, v$, update, fetch } = useProgressLevelEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, update };
    }
});
