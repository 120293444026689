
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppSelect, AppButton } from '@/components';
import { usePromotionCode } from '@/modules/promotion/code/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { formatDate, renderOption, pageSize } from '@/utils/helpers';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';

export default defineComponent({
    name: 'PromotionCodeList',
    components: { AppTable, AppCard, AppSelect, AppButton },
    setup() {
        const { featureId, itemList, total, currentPage, changePage, actions, Options } = usePromotionCode();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Code', field: 'code' },
                { label: 'Feature', display: (row: any) => renderOption(Options.features, row.feature) },
                { label: 'Quantity', field: 'quantity' },
                { label: 'Receive Counter', field: 'receiveCounter' },
                { label: 'Status', display: (row: any) => renderOption(Options.statusList, row.status) },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: PROMOTION_ROUTER.CODE.DETAIL(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable,
                        onclick: () => actions.enable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await changePage(currentPage.value);
        });

        return { featureId, table, changePage, actions, Options, pageSize, PROMOTION_ROUTER };
    }
});
