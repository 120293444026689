import { useAvatarColorList } from '@/modules/avatar/color/model/list';
import { useAvatarLayerList } from '@/modules/avatar/layer/model/list';
import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarImageEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { colorOption, fetchList: fetchColors } = useAvatarColorList();
    const { layerOption, fetchList: fetchLayers } = useAvatarLayerList();

    const model = reactive({
        id: route.params.imageId,
        itemId: route.params.itemId,
        itemName: null,
        categoryId: null,
        colorId: null,
        layerId: null,
        isDefault: false,
        image: null,
        imageStatus: null,
        imageFile: null,
        canDelete: false
    });

    const beforeFetch = async () => {
        await Promise.all([fetchColors(), fetchLayers()]);
    };

    const fetch = async () => {
        const errMsg = [];
        const [itemResponse, imageResponse] = await Promise.all([
            avatarService.item.get(model.itemId),
            avatarService.image.get(model.itemId, model.id)
        ]);

        if (itemResponse.code === 200) {
            model.itemName = itemResponse.data.name;
            model.categoryId = itemResponse.data.categoryId;
        } else {
            errMsg.push(itemResponse.data);
        }

        if (imageResponse.code === 200) {
            Object.assign(model, imageResponse.data);
        } else {
            errMsg.push(imageResponse.data);
        }

        if (errMsg.length) {
            errMsg.forEach(msg => toast.error(msg));
            router.push(AVATAR_ROUTER.ITEM.IMAGE.INDEX());
        }
    };

    const actions = {
        update: async () => {
            const response = await avatarService.image.update(
                model.itemId,
                model.id,
                model.colorId,
                model.layerId,
                model.isDefault,
                model.imageFile
            );
            if (response.code === 200) {
                toast.success('Update successfully');
                router.push(AVATAR_ROUTER.ITEM.IMAGE.INDEX(model.itemId));
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this image?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.image.remove(model.itemId, model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(AVATAR_ROUTER.ITEM.IMAGE.INDEX(model.itemId));
                        }
                    }
                }
            });
        }
    };

    return { model, colorOption, layerOption, beforeFetch, fetch, actions };
}
