import { Role } from '@/interfaces/state';
import { APPLICATION_ROUTER } from '@/modules/apps/router';
import { applicationService } from '@/services/applications';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { useMixins } from './mixins';

export default function () {
    const toast = useToast();
    const store = useStore();

    const isSuperUser = computed((): boolean => store.getters['auth/roles'].includes(Role.SUPER_USER));
    const { model, v$, currencyOptions } = useMixins();

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await applicationService.app.create(
            model.name,
            model.currency,
            model.creditInit,
            model.friendLimit,
            model.popupRate,
            model.winsForRating,
            model.bindSuggestTime,
            model.remindApple,
            model.remainVipMinutes,
            model.vipTermMinutes,
            model.creditWelcomeLimit,
            model.thumbnailFile
        );

        if (response.code === 200) {
            await store.dispatch('application/loadApps');
            toast.success('Create successfully');
            useRouter().push(APPLICATION_ROUTER.INDEX());
        }
    };

    return { isSuperUser, model, v$, currencyOptions, create };
}
