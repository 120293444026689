
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker } from '@/components';
import { AppFileUpload, AppImageHolder } from '@/components';
import { useDuplicateItemEdit } from '@/modules/duplicate/item/model/edit';

export default defineComponent({
    name: 'DuplicateItemEdit',
    components: {
        AppFormRow,
        AppInput,
        AppCard,
        AppButton,
        AppSelect,
        AppDateTimePicker,
        AppFileUpload,
        AppImageHolder
    },
    setup() {
        const { model, v$, beforeFetch, fetch, betList, timeUnits, actions } = useDuplicateItemEdit();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { v$, model, actions, betList, timeUnits, gameTypeList };
    }
});

const gameTypeList = [
    { label: 'Win', value: 0, background: 'info' },
    { label: 'Complete', value: 1, background: 'success' },
    { label: 'ArenaWin', value: 2, background: 'info' },
    { label: 'ArenaComplete', value: 3, background: 'success' },
    { label: 'SlotWin', value: 4, background: 'info' },
    { label: 'SlotSpin', value: 5, background: 'success' },
    { label: 'ScratchCardComplete', value: 6, background: 'success' },
    { label: 'ScratchCardWin', value: 7, background: 'info' },
    { label: 'DuplicateGameComplete', value: 8, background: 'success' }
];
