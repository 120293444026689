import { Role } from '@/interfaces/state';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { applicationService } from '@/services/applications';
import { App } from '@/store/applications';
import { useFetchList } from '@/utils/useFetch';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useAppList() {
    const dialog = useVDialog();
    const toast = useToast();
    const store = useStore();

    const isSuperUser = computed((): boolean => store.getters['auth/roles'].includes(Role.SUPER_USER));
    const allApps = computed(() => store.getters['application/apps'] as Array<App>);
    const selectedAppId = computed(() => store.getters['application/appId']);
    const currentAppInfo = computed(() => allApps.value.find(app => app.id == selectedAppId.value));
    const appOption = computed(() => allApps.value.map(app => ({ label: app.name, value: app.id })));

    const { itemList, total, fetchList } = useFetchList(applicationService.app.list, null);

    const actions = {
        remove: (appId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.app.remove(appId);
                        if (response.code === 200) {
                            await store.dispatch('application/loadApps');
                            toast.success('Delete successfully');
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    return {
        itemList,
        total,
        fetchList,
        isSuperUser,
        allApps,
        selectedAppId,
        currentAppInfo,
        appOption,
        actions
    };
}
