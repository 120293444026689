import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { Options } from './mixins';

export function usePromotionCode() {
    const dialog = useVDialog();
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const promotionCodeStore = {
        getPage: () => store.getters['promotionCode/page'],
        setPage: (page: number) => store.dispatch('promotionCode/changePage', page),
        getFeature: () => store.getters['promotionCode/feature'],
        setFeature: (feature: number) => store.dispatch('promotionCode/changeFeature', feature)
    };

    const featureId = ref(promotionCodeStore.getFeature());
    const { itemList, total, currentPage, changePage } = useFetchList(promotionService.code.list, {
        pagingStore: promotionCodeStore
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        routerByFeature: () => {
            const data = Options.features.find(feature => feature.value == featureId.value);
            if (data) {
                router.push({ name: data.routeName });
            }
        }
    };

    return { featureId, itemList, total, currentPage, actions, Options, changePage };
}
