import { useIAPList } from '@/modules/iap/model/list';
import { QUEST_ROUTER } from '@/modules/quest/router';
import { questService } from '@/services/quest';
import { useVuelidate } from '@vuelidate/core';
import { integer, maxValue, minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useQuestBoostEdit() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { iapOptions, fetchIapOptions } = useIAPList();

    const model = reactive({
        id: route.params.boostId,
        packageId: '',
        price: 0,
        ratio: 0,
        timeUnit: 0,
        timeDuration: 0
    });

    const validations = {
        packageId: { required },
        price: { required, numeric, min: minValue(1) },
        ratio: { required, integer, min: minValue(1) },
        timeDuration: { required, integer, min: minValue(1), max: maxValue(32000) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchIapOptions();

        const response = await questService.boost.get(model.id);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(QUEST_ROUTER.BOOST.INDEX());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await questService.boost.update(
                model.id,
                model.packageId,
                model.price,
                model.ratio,
                model.timeUnit,
                model.timeDuration
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push(QUEST_ROUTER.BOOST.INDEX());
            } else {
                toast.error(response.data);
            }
        },
        selectIAP: (iapId: any) => {
            iapOptions.value.forEach(item => {
                if (item.value == iapId) {
                    model.packageId = item.data.packageId;
                    model.price = item.data.price;

                    v$.value.$touch();
                }
            });
        }
    };

    return { model, v$, fetch, actions, iapOptions };
}
