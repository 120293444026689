import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const SysRoleList = () => import(/* webpackChunkName: "exoty" */ './system/view/index.vue');
const SysRoleAdd = () => import(/* webpackChunkName: "exoty" */ './system/view/add.vue');
const AppRoleList = () => import(/* webpackChunkName: "exoty" */ './app/view/index.vue');
const AppRoleAdd = () => import(/* webpackChunkName: "exoty" */ './app/view/add.vue');

export default [
    {
        path: '/system/role',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'SysRoleList',
                component: SysRoleList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'SysRoleAdd',
                component: SysRoleAdd,
                meta: { requiresAuth: true }
            }
        ]
    },
    {
        path: '/app/role',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'AppRoleList',
                component: AppRoleList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'AppRoleAdd',
                component: AppRoleAdd,
                meta: { requiresAuth: true }
            }
        ]
    }
];

export const ROLE_ROUTER = {
    SYSTEM: {
        INDEX: () => router.resolve({ name: 'SysRoleList' }),
        ADD: () => router.resolve({ name: 'SysRoleAdd' })
    },
    APP: {
        INDEX: () => router.resolve({ name: 'AppRoleList' }),
        ADD: () => router.resolve({ name: 'AppRoleAdd' })
    }
};
