
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton } from '@/components';
import { useTournamentLeaderboardDetail } from '@/modules/tournament/leaderboard/model/detail';
import { formatDate, renderTimeUnit } from '@/utils/helpers';
import { TOURNAMENT_ROUTER } from '@/modules/tournament/router';

export default defineComponent({
    name: 'TournamentLeaderboardDetail',
    components: { AppCard, AppButton },
    setup() {
        const { model, fetch, actions } = useTournamentLeaderboardDetail();

        onMounted(() => {
            fetch();
        });

        return { model, formatDate, actions, renderTimeUnit, renderStatus, TOURNAMENT_ROUTER };
    }
});

const statusList = [
    { label: 'None', value: 0, background: 'secondary' },
    { label: 'Published', value: 1, background: 'success' },
    { label: 'Unpublished', value: 2, background: 'secondary' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
