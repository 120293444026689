import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const ApplicationAdd = () => import(/* webpackChunkName: "exoty" */ './view/add.vue');
const ApplicationEdit = () => import(/* webpackChunkName: "exoty" */ './view/edit.vue');
const ApplicationList = () => import(/* webpackChunkName: "exoty" */ './view/index.vue');

export default [
    {
        path: '/apps',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'ApplicationList',
                component: ApplicationList,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/edit',
                name: 'ApplicationEdit',
                component: ApplicationEdit,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'ApplicationAdd',
                component: ApplicationAdd,
                meta: { requiresAuth: true }
            }
        ]
    }
];

export const APPLICATION_ROUTER = {
    INDEX: () => router.resolve({ name: 'ApplicationList' }),
    ADD: () => router.resolve({ name: 'ApplicationAdd' }),
    EDIT: (id: any) => router.resolve({ name: 'ApplicationEdit', params: { id } })
};
