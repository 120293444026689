import { useRfmGroupList } from '@/modules/rfm/group/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { rfmService } from '@/services/rfm';
import { useFetchList } from '@/utils/useFetch';
import { ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useRfmFrequencyList() {
    const dialog = useVDialog();
    const toast = useToast();
    const store = useStore();

    const selectedGroupId = ref(null);
    watch(selectedGroupId, value => store.dispatch('rfm/changeGroupId', value));

    const { rfmGroupOptions, fetchRfmGroupOptions } = useRfmGroupList();
    const { itemList, total, fetchList } = useFetchList(rfmService().frequency.list, { options: selectedGroupId });

    const beforeFetch = async () => {
        await fetchRfmGroupOptions();
        selectedGroupId.value = store.getters['rfm/groupId'];
        if (!rfmGroupOptions.value.map(group => group.value).includes(selectedGroupId.value)) {
            selectedGroupId.value = Number(rfmGroupOptions.value?.[0]?.value);
        }
    };

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await rfmService().frequency.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, fetchList, beforeFetch, rfmGroupOptions, selectedGroupId, actions };
}
