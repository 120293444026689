import { ITEM_ROUTER } from '@/modules/item/router';
import { FEATURES } from '@/modules/promotion/code/model/mixins';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { SCRATCH_ROUTER } from '@/modules/scratch/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { scratchTicketService } from '@/services/scratch';
import { useFetchList } from '@/utils/useFetch';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useTicketList() {
    const dialog = useVDialog();
    const toast = useToast();
    const store = useStore();

    const ticketStore = {
        getPage: () => store.getters['ticket/page'],
        setPage: (page: number) => store.dispatch('ticket/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(scratchTicketService().list, {
        pagingStore: ticketStore
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await scratchTicketService().remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        } else {
                            toast.error(response.data, { timeout: 2000 });
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => SCRATCH_ROUTER.TICKET.ADD(),
        edit: (id: any) => SCRATCH_ROUTER.TICKET.EDIT(id),
        specialShop: (type: any) => ITEM_ROUTER.SYSTEM.SPECIAL_SHOP(FEATURES.SCRATCH_TICKET, type),
        promoCode: (type: any) => PROMOTION_ROUTER.CODE.ADD_TO_FEATURE(FEATURES.SCRATCH_TICKET, type)
    };

    return { itemList, total, currentPage, changePage, actions, routes };
}
