
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useAccountList } from '@/modules/account/app/model/list';
import { ActivityFilterType, Options, TransactionType } from '@/modules/account/app/model/mixins';
import { ACCOUNT_ROUTER } from '@/modules/account/router';
import { MESSAGE_ROUTER } from '@/modules/message/router';

export default defineComponent({
    name: 'AppAccountList',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton },
    setup() {
        const { model, v$, itemList, total, currentPage, fetchList, actions } = useAccountList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Account ID', field: 'accountId' },
                { label: 'Name', field: 'name' },
                { label: 'Email', field: 'email' },
                { label: 'Facebook ID', field: 'facebookId' },
                { label: 'Ruby', field: 'ruby' },
                { label: 'Credit', field: 'credit' },
                { label: 'Bought credit', field: 'boughtCredit' },
                { label: 'Status', display: (row: any) => actions.renderStatus(row) },
                { label: 'Actions', field: 'accountId' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: ACCOUNT_ROUTER.APP.DETAIL(row.accountId)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-plus',
                        theme: 'info',
                        tooltip: 'Add chip',
                        href: MESSAGE_ROUTER.SEND(row.accountId)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-hand-holding-heart',
                        theme: 'info',
                        tooltip: 'Credit transaction',
                        href: ACCOUNT_ROUTER.APP.TRANSACTION(TransactionType.Credit, row.accountId)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-hand-holding-usd',
                        theme: 'success',
                        tooltip: 'Payment transaction',
                        href: ACCOUNT_ROUTER.APP.TRANSACTION(TransactionType.Payment, row.accountId)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-hand-holding',
                        theme: 'info',
                        tooltip: 'Ruby transaction',
                        href: ACCOUNT_ROUTER.APP.TRANSACTION(TransactionType.Ruby, row.accountId)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-history',
                        theme: 'info',
                        tooltip: 'Login history',
                        href: ACCOUNT_ROUTER.APP.ACTIVITY(ActivityFilterType.AccountId.value, row.accountId)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-user-lock',
                        theme: 'warning',
                        tooltip: 'Ban account',
                        isShow: !row.isBanned,
                        onclick: () => actions.ban(row.accountId)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-user-check',
                        theme: 'success',
                        tooltip: 'Unban account',
                        isShow: row.isBanned,
                        onclick: () => actions.unban(row.accountId)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-comment-slash',
                        theme: 'warning',
                        tooltip: 'Ban chat',
                        isShow: !row.isBanChat,
                        onclick: () => actions.banChat(row.accountId)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-comment',
                        theme: 'success',
                        tooltip: 'Unban chat',
                        isShow: row.isBanChat,
                        onclick: () => actions.unbanChat(row.accountId)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-microphone-slash',
                        theme: 'warning',
                        tooltip: 'Mute',
                        isShow: !row.isMute,
                        onclick: () => actions.mute(row.accountId)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-microphone',
                        theme: 'success',
                        tooltip: 'Unmute',
                        isShow: row.isMute,
                        onclick: () => actions.unmute(row.accountId)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-user-clock',
                        theme: 'danger',
                        tooltip: 'Delete',
                        isShow: !row.isDeleting,
                        onclick: () => actions.delete(row.accountId)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-user-md',
                        theme: 'info',
                        tooltip: 'Undelete',
                        isShow: row.isDeleting,
                        onclick: () => actions.undelete(row.accountId)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList(currentPage.value);
        });

        return { model, itemList, v$, table, fetchList, Options };
    }
});
