import { SHOP_ROUTER } from '@/modules/shop/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { shopService } from '@/services/shop';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function useShopItemEdit() {
    const toast = useToast();
    const dialog = useVDialog();
    const router = useRouter();

    const { model, v$, fetch, iapOptions } = useMixins();

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await shopService().item.update(
                model.id,
                model.name,
                model.price,
                model.credit,
                model.isPublished,
                model.packageId,
                model.isBestseller,
                model.imageFile,
                model.thumbnailFile
            );
            if (response.code === 200) {
                toast.success('Update successfully');
                router.push(SHOP_ROUTER.INDEX());
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await shopService().item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(SHOP_ROUTER.INDEX());
                        }
                    }
                }
            });
        }
    };

    return { model, v$, iapOptions, fetch, actions };
}
