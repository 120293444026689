import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { duplicateService } from '@/services/duplicate';
import { gameService } from '@/services/game';
import { timeUnits } from '@/utils/helpers';
import { validateDateAfter, validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function useDuplicateItemAdd() {
    const router = useRouter();
    const toast = useToast();
    const { selectableValues } = useMixins();

    const betList = ref([]);

    const beforeFetch = async () => {
        const [variantResponse, betResponse] = await Promise.all([
            gameService.variant.list(),
            gameService.bet.list({}, null)
        ]);

        if (variantResponse.code !== 200 || betResponse.code !== 200) {
            const errMessage = variantResponse.code !== 200 ? variantResponse.data : betResponse.data;
            toast.error(errMessage);
            router.push({ name: 'Home' });
            return;
        }

        const variants = variantResponse.data;
        const bets = betResponse.data;
        const result = [] as Array<any>;

        bets.filter((bet: any) => bet.type === 3).forEach((bet: any) => {
            const variant = variants.find((vari: any) => vari.id === bet.variantId);
            result.push({
                value: bet.id,
                label: `${bet.name}: ${variant.name} - ${bet.maxRound} ${bet.maxRound > 1 ? 'rounds' : 'round'}`,
                cost: bet.cost,
                round: bet.maxRound
            });
        });

        betList.value = result;
    };

    const model = reactive({
        name: null,
        type: 0,
        betId: '',
        cost: 0,
        prizes: '',
        round: 0,
        game: 1,
        timeUnit: 0,
        timeDuration: 0,
        prepareSeconds: 0,
        openSeconds: 0,
        closeSeconds: 0,
        rankingSeconds: 0,
        publishTime: new Date(),
        unpublishTime: null,
        thumbnail: null,
        thumbnailFile: null
    });

    const validations = {
        name: { required },
        type: { required },
        betId: { required },
        cost: { required, integer, min: minValue(0) },
        openSeconds: { required, integer, min: minValue(1) },
        closeSeconds: { required, integer, min: minValue(1) },
        rankingSeconds: { required, integer, min: minValue(0) },
        round: { required, integer, min: minValue(1) },
        game: { required, integer, min: minValue(1) },
        prizes: { required, format: validateFormat(/^\d+:\d+(;\d+:\d+)*$/) },
        publishTime: { required, validateDate: validateDateAfter() }
    };

    const v$ = useVuelidate(validations, model);

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await duplicateService.item.create(
                model.name,
                model.type,
                model.betId,
                model.cost,
                model.prizes,
                model.round,
                model.game,
                model.timeUnit,
                model.timeDuration,
                model.prepareSeconds,
                model.openSeconds,
                model.closeSeconds,
                model.rankingSeconds,
                model.publishTime.getTime(),
                model.unpublishTime?.getTime() ?? null,
                model.thumbnailFile
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push(DUPLICATE_ROUTER.ITEM.INDEX());
            }
        },

        changeBet: (betId: any) => {
            betList.value.forEach(item => {
                if (item.value == betId) {
                    model.cost = item.cost;
                    model.round = item.round;
                }
            });
        }
    };

    return { model, v$, beforeFetch, betList, actions, timeUnits, selectableValues };
}
