import { ARENA_ROUTER } from '@/modules/arena/router';
import { ITEM_ROUTER } from '@/modules/item/router';
import { FEATURES } from '@/modules/promotion/code/model/mixins';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { arenaService } from '@/services/arena';
import { useFetchList } from '@/utils/useFetch';
import { useToast } from 'vue-toastification';

export function useArenaTicketList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { itemList, total, fetchList } = useFetchList(arenaService.ticket.list, null);

    const actions = {
        remove: (type: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.ticket.remove(type);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => ARENA_ROUTER.TICKET.ADD(),
        edit: (type: any) => ARENA_ROUTER.TICKET.EDIT(type),
        specialShop: (type: any) => ITEM_ROUTER.SYSTEM.SPECIAL_SHOP(FEATURES.ARENA_TICKET, type),
        promoCode: (type: any) => PROMOTION_ROUTER.CODE.ADD_TO_FEATURE(FEATURES.ARENA_TICKET, type)
    };

    return { itemList, total, fetchList, actions, routes };
}
