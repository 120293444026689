import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarDefaultEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: route.params.avatarId,
        type: null,
        name: '',
        gender: 0,
        firstnames: '',
        isPublished: false,
        image: null,
        imageStatus: null,
        imageFile: null,
        canDelete: false
    });

    const validations = {
        imageFile: {},
        name: { required },
        type: { required },
        isPublished: { required },
        firstnames: { required: requiredIf(() => model.type === 1 || model.type === 4) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await avatarService.default.get(model.id);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(AVATAR_ROUTER.COLOR.INDEX());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await avatarService.default.update(
                model.id,
                model.name,
                model.firstnames,
                model.isPublished,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(AVATAR_ROUTER.COLOR.INDEX());
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.default.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(AVATAR_ROUTER.COLOR.INDEX());
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions, typeList, genderList };
}

const genderList = [
    { label: 'Female', value: 0 },
    { label: 'Male', value: 1 }
];

const typeList = [
    { label: 'Bot', value: 1 },
    { label: 'Admin', value: 2 },
    { label: 'DefaultAvatar', value: 3 },
    { label: 'FakeAvatar', value: 4 }
];
