import { PROGRESS_ROUTER } from '@/modules/progress/router';
import { progressService } from '@/services/progress';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useProgressEdit() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        name: ''
    });

    const validations = {
        name: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await progressService.item.get(route.params.id);
        if (response.code === 200) {
            model.id = response.data.id;
            model.name = response.data.name;
        } else {
            toast.error(response.data);
            router.push(PROGRESS_ROUTER.ITEM.INDEX());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await progressService.item.update(model.id, model.name);
            if (response.code === 200) {
                toast.success('Update successfully');
                router.push(PROGRESS_ROUTER.ITEM.INDEX());
            } else {
                toast.error(response.data);
            }
        }
    };

    return { model, v$, fetch, actions };
}
