import { PROMOTION_ROUTER } from '@/modules/promotion/router';

export const ProgressLevelType = {
    Totem: { value: 1, label: 'Totem' },
    CompleteGame: { value: 2, label: 'CompleteGame' },
    WinGame: { value: 3, label: 'WinGame' },
    Promotion: { value: 4, label: 'Promotion' },
    ArenaComplete: { value: 5, label: 'ArenaComplete' },
    ArenaWin: { value: 6, label: 'ArenaWin' },
    SlotComplete: { value: 7, label: 'SlotComplete' },
    SlotWin: { value: 8, label: 'SlotWin' },
    ScratchCardComplete: { value: 10, label: 'ScratchCardComplete' },
    ScratchCardWin: { value: 11, label: 'ScratchCardWin' },
    DuplicateGameComplete: { value: 12, label: 'DuplicateGameComplete' },
    PromotionBooster: { value: 13, label: 'PromotionBooster' }
};

export const progressLevelTypeOption = Object.entries(ProgressLevelType).map(([, data]) => data);

export function renderProgressLevelType(status: any) {
    const type = progressLevelTypeOption.find(item => item.value === status);
    return `<span class='badge badge-info'>${type?.label}</span>`;
}

export function getItemRouterLink(type: number, itemId: number) {
    switch (type) {
        case ProgressLevelType.Promotion.value:
            return PROMOTION_ROUTER.ITEM.DETAIL(itemId);
        case ProgressLevelType.PromotionBooster.value:
            return PROMOTION_ROUTER.BOOSTER.DETAIL(itemId);
        default:
            return '';
    }
}
