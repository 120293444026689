import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const ShopItemAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/shop/view/add.vue');
const ShopItemEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/shop/view/edit.vue');
const ShopItemList = () => import(/* webpackChunkName: "exoty" */ '@/modules/shop/view/index.vue');
const ShopItemTranslate = () => import(/* webpackChunkName: "exoty" */ '@/modules/shop/view/translate.vue');

export default [
    {
        path: '/app/shop',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'ShopItemList',
                component: ShopItemList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'ShopItemAdd',
                component: ShopItemAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/clone',
                name: 'ShopItemClone',
                component: ShopItemAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                name: 'ShopItemEdit',
                component: ShopItemEdit,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/translate',
                name: 'ShopItemTranslate',
                component: ShopItemTranslate,
                meta: { requiresAuth: true }
            }
        ]
    }
];

export const SHOP_ROUTER = {
    INDEX: () => router.resolve({ name: 'ShopItemList' }),
    ADD: () => router.resolve({ name: 'ShopItemAdd' }),
    EDIT: (id: any) => router.resolve({ name: 'ShopItemEdit', params: { id: id } }),
    CLONE: (id: any) => router.resolve({ name: 'ShopItemClone', params: { id: id } }),
    TRANSLATE: (id: any) => router.resolve({ name: 'ShopItemTranslate', params: { id: id } })
};
