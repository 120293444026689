
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useProgressLevelList } from '@/modules/progress/level/model/list';
import { getItemRouterLink, renderProgressLevelType } from '@/modules/progress/level/model/mixins';
import { RouterLink } from 'vue-router';
import { PROGRESS_ROUTER } from '@/modules/progress/router';

export default defineComponent({
    name: 'ProgressLevelList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { progressId, itemList, total, fetchList, actions } = useProgressLevelList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'Level', field: 'level' },
                { label: 'Name', field: 'name' },
                { label: 'Type', display: (row: any) => renderProgressLevelType(row.type) },
                { label: 'Reward', field: 'reward' },
                { label: 'Quantity', field: 'quantity' },
                {
                    label: 'Item Id',
                    component: (row: any) => ({
                        type: row.itemId ? markRaw(RouterLink) : 'span',
                        message: row.itemId,
                        props: { to: getItemRouterLink(row.type, row.itemId) }
                    })
                },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Actions', field: 'level' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: PROGRESS_ROUTER.LEVEL.EDIT(progressId, row.level)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        href: PROGRESS_ROUTER.LEVEL.TRANSLATE(progressId, row.level)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.level)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, progressId, PROGRESS_ROUTER };
    }
});
