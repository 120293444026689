import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const ActivityList = () => import(/* webpackChunkName: "exoty" */ './view/index.vue');
const ActivityDetail = () => import(/* webpackChunkName: "exoty" */ './view/detail.vue');

export default [
    {
        path: '/app/activity',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'ActivityList',
                component: ActivityList,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/detail',
                name: 'ActivityDetail',
                component: ActivityDetail,
                meta: { requiresAuth: true }
            }
        ]
    }
];

export const ACTIVITY_ROUTER = {
    INDEX: () => router.resolve({ name: 'ActivityList' }),
    DETAIL: (id: any) => router.resolve({ name: 'ActivityDetail', params: { id } })
};
