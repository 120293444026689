
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload } from '@/components';
import { useProgressLevelAdd } from '@/modules/progress/level/model/add';
import { progressLevelTypeOption, ProgressLevelType } from '@/modules/progress/level/model/mixins';

export default defineComponent({
    name: 'ProgressLevelAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload },
    setup() {
        const { model, v$, create, linkableItemOption, fetch } = useProgressLevelAdd();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, create, linkableItemOption, progressLevelTypeOption, ProgressLevelType };
    }
});
