import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const SystemAccountAdd = () => import(/* webpackChunkName: "exoty" */ './system/view/add.vue');
const SystemAccountPassword = () => import(/* webpackChunkName: "exoty" */ './system/view/password.vue');
const AppAccountActivity = () => import(/* webpackChunkName: "exoty" */ './app/view/activity.vue');
const AppAccountDetail = () => import(/* webpackChunkName: "exoty" */ './app/view/detail.vue');
const AppAccountList = () => import(/* webpackChunkName: "exoty" */ './app/view/index.vue');
const AppAccountTransaction = () => import(/* webpackChunkName: "exoty" */ './app/view/transaction.vue');

export default [
    {
        path: '/system/account',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'add',
                name: 'SystemAccountAdd',
                component: SystemAccountAdd,
                meta: { requiresAuth: true }
            },
            {
                path: 'password',
                name: 'SystemAccountPassword',
                component: SystemAccountPassword,
                meta: { requiresAuth: true }
            }
        ]
    },
    {
        path: '/app/account',
        name: 'AppAccount',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'AppAccountList',
                component: AppAccountList,
                meta: { requiresAuth: true }
            },
            {
                path: ':accountId/detail',
                name: 'AppAccountDetail',
                component: AppAccountDetail,
                meta: { requiresAuth: true }
            },
            {
                path: 'activity',
                name: 'AppAccountActivity',
                component: AppAccountActivity,
                meta: { requiresAuth: true }
            },
            {
                path: ':accountId/transaction',
                name: 'AppAccountTransaction',
                component: AppAccountTransaction,
                meta: { requiresAuth: true }
            }
        ]
    }
];

export const ACCOUNT_ROUTER = {
    SYSTEM: {
        ADD: () => router.resolve({ name: 'SystemAccountAdd' }),
        PASSWORD: () => router.resolve({ name: 'SystemAccountPassword' })
    },

    APP: {
        INDEX: () => router.resolve({ name: 'AppAccountList' }),
        DETAIL: (accountId: any) =>
            router.resolve({
                name: 'AppAccountDetail',
                params: { accountId: accountId }
            }),
        ACTIVITY: (type: number, criteria: string | number) =>
            router.resolve({
                name: 'AppAccountActivity',
                query: { type, criteria }
            }),
        TRANSACTION: (type: number, accountId: number) =>
            router.resolve({
                name: 'AppAccountTransaction',
                query: { type },
                params: { accountId }
            })
    }
};
