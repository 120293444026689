import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mx-auto mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_card, {
      label: "Function",
      classes: "pb-0"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_app_button, {
            name: "Search",
            icon: "fa-search",
            theme: "info",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.fetchList(1)), ["prevent"]))
          }),
          (_ctx.model.filter.type === _ctx.ActivityFilterType.AccountId.value && _ctx.model.accountId)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.model.isBanned)
                  ? (_openBlock(), _createBlock(_component_app_button, {
                      key: 0,
                      name: "Unban account",
                      icon: "fa-user-check",
                      theme: "info",
                      onClick: _withModifiers(_ctx.actions.unban, ["prevent"])
                    }, null, 8, ["onClick"]))
                  : (_openBlock(), _createBlock(_component_app_button, {
                      key: 1,
                      name: "Ban account",
                      icon: "fa-user-lock",
                      theme: "warning",
                      onClick: _withModifiers(_ctx.actions.ban, ["prevent"])
                    }, null, 8, ["onClick"]))
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_app_form_row, {
          label: "Type",
          class: "col-md-6 mx-auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.Options.ActivityFilterType,
              modelValue: _ctx.model.filter.type,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.filter.type) = $event)),
              modelModifiers: { number: true },
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.model.filter.criteria = ''))
            }, null, 8, ["options", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, {
          label: "Criteria",
          class: "col-md-6 mx-auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.filter.criteria,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.filter.criteria) = $event)),
              validations: _ctx.v$.filter
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_app_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_table, {
          "is-slot-mode": true,
          columns: _ctx.table.columns,
          rows: _ctx.table.rows,
          total: _ctx.table.totalRecordCount,
          page: _ctx.table.page,
          actions: _ctx.table.actions,
          onRefreshData: _ctx.fetchList
        }, null, 8, ["columns", "rows", "total", "page", "actions", "onRefreshData"])
      ]),
      _: 1
    })
  ], 64))
}