import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { avatarService } from '@/services/avatar';
import useVuelidate from '@vuelidate/core';
import { numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarBackgroundAdd() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        name: null,
        price: 0,
        isPublished: false,
        isDefault: false,
        isNew: false,
        imageFile: null
    });

    const validations = {
        name: { required },
        price: { required, numeric },
        imageFile: { required }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await avatarService.background.create(
            model.name,
            model.price,
            model.isPublished,
            model.isDefault,
            model.isNew,
            model.imageFile
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(AVATAR_ROUTER.BACKGROUND.INDEX());
        }
    };

    return { model, v$, create };
}
