
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppCard, AppButton, AppSwitch, AppSelect, AppFileUpload, AppAvatarBreadcrumb } from '@/components';
import { useAvatarImageAdd } from '@/modules/avatar/item/image/model/add';

export default defineComponent({
    name: 'AvatarItemImageAdd',
    components: { AppFormRow, AppCard, AppButton, AppSwitch, AppSelect, AppFileUpload, AppAvatarBreadcrumb },
    setup() {
        const { model, colorOption, layerOption, fetch, create } = useAvatarImageAdd();

        onMounted(() => {
            fetch();
        });

        return { model, colorOption, layerOption, create };
    }
});
