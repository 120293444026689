import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "card-footer" }
const _hoisted_4 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_switch = _resolveComponent("app-switch")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Add Bet"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Variant Id" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.model.variantOptions,
              modelValue: _ctx.model.variantId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.variantId) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.variantId
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event)),
              validations: _ctx.v$.name
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Type" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.options.betTypes,
              modelValue: _ctx.model.type,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.type) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.type,
              onChange: _ctx.actions.changeBetType
            }, null, 8, ["options", "modelValue", "validations", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Cost" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.cost,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.cost) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.cost
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Max Round" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.maxRound,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.maxRound) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.maxRound
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Max Point" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.maxPoint,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.maxPoint) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.maxPoint
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Table Pot Percent" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.tablePotPercent,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.tablePotPercent) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.tablePotPercent
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Table Credit Ratio" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.tableCreditRatio,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.tableCreditRatio) = $event)),
              validations: _ctx.v$.tableCreditRatio
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Level Timeout" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.levelTimeout,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.levelTimeout) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.levelTimeout
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        ([1, 3, 4].includes(_ctx.model.type))
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 0,
              label: "Finding Match Timeout"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  type: "number",
                  modelValue: _ctx.model.findingMatchTimeout,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.findingMatchTimeout) = $event)),
                  modelModifiers: { number: true },
                  validations: _ctx.v$.findingMatchTimeout
                }, null, 8, ["modelValue", "validations"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_app_form_row, { label: "Win/Lose Group" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_switch, {
              modelValue: _ctx.model.winLoseGroup,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.winLoseGroup) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Allow Invitation" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_switch, {
              modelValue: _ctx.model.allowInvitation,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.allowInvitation) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Lock Type" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              disabled: _ctx.model.type == 3,
              options: _ctx.options.lockTypes,
              modelValue: _ctx.model.lockType,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.lockType) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.lockType,
              onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.model.cycle = null))
            }, null, 8, ["disabled", "options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        (_ctx.model.lockType == 2 || _ctx.model.lockType == 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_app_form_row, { label: "Cycle" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_select, {
                    options: _ctx.options.cycles,
                    modelValue: _ctx.model.cycle,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.cycle) = $event)),
                    modelModifiers: { number: true },
                    validations: _ctx.v$.cycle,
                    onChange: _ctx.actions.changeCycle
                  }, null, 8, ["options", "modelValue", "validations", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "From subcycle" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    modelValue: _ctx.model.fromSubcycle,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.fromSubcycle) = $event)),
                    validations: _ctx.v$.fromSubcycle,
                    placeholder: _ctx.model.cycle == 1 ? 'MM' : 'HH:MM'
                  }, null, 8, ["modelValue", "validations", "placeholder"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "To subcycle" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    modelValue: _ctx.model.toSubcycle,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.toSubcycle) = $event)),
                    validations: _ctx.v$.toSubcycle,
                    placeholder: _ctx.model.cycle == 1 ? 'MM' : 'HH:MM'
                  }, null, 8, ["modelValue", "validations", "placeholder"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_app_button, {
              name: "Save",
              icon: "fa-save",
              theme: "info",
              onClick: _withModifiers(_ctx.actions.create, ["prevent"])
            }, null, 8, ["onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}