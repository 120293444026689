import { ARENA_ROUTER } from '@/modules/arena/router';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { arenaService } from '@/services/arena';
import { validateDateAfter, validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { integer, maxValue, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useArenaItemClone() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { fetchAllBets, betOptions } = useGameBetList();

    const model = reactive({
        id: null,
        name: null,
        betId: null,
        maxRejection: null,
        price: null,
        jackpotInit: null,
        jackpotWinPercent: null,
        jackpotDonation: null,
        prizes: null,
        ticketType: null,
        closeTime: null,
        startTime: null,
        endTime: null,
        publishTime: new Date(),
        unpublishTime: null
    });

    const validations = {
        name: { required },
        betId: { required },
        ticketType: { required },
        price: { required, integer, min: minValue(0) },
        maxRejection: { required, integer, min: minValue(0) },
        jackpotInit: { required, integer, min: minValue(0) },
        jackpotWinPercent: { required, integer, min: minValue(0) },
        jackpotDonation: { required, integer, min: minValue(0), max: maxValue(100) },
        prizes: { required, format: validateFormat(/^\d+(,\d+)*$/) },
        publishTime: { required },
        startTime: { required, validateDate: validateDateAfter() },
        closeTime: { required, validateDate: validateDateAfter('startTime') },
        endTime: { required, validateDate: validateDateAfter('closeTime') },
        unpublishTime: { required, validateDate: validateDateAfter('endTime') }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchAllBets();

        const response = await arenaService.item.get(route.params.id);
        if (response.code === 200) {
            const data = response.data;

            data.startTime = new Date(data.startTime);
            data.endTime = new Date(data.endTime);
            data.publishTime = new Date(data.publishTime);
            data.unpublishTime = new Date(data.unpublishTime);
            data.closeTime = new Date(data.closeTime);

            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(ARENA_ROUTER.ITEM.INDEX());
        }
    };
    const actions = {
        clone: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const publishTime = model.publishTime.getTime();
            const startTime = model.startTime.getTime();
            const closeTime = model.closeTime.getTime();
            const endTime = model.endTime.getTime();
            const unpublishTime = model.unpublishTime.getTime();

            const response = await arenaService.item.create(
                model.id,
                model.name,
                model.betId,
                model.maxRejection,
                model.price,
                model.jackpotInit,
                model.jackpotWinPercent,
                model.jackpotDonation,
                model.prizes,
                model.ticketType,
                startTime,
                endTime,
                closeTime,
                publishTime,
                unpublishTime
            );

            if (response.code === 200) {
                toast.success('Clone Successfully');
                router.push(ARENA_ROUTER.ITEM.INDEX());
            } else {
                toast.error(response.data);
            }
        }
    };

    return { model, v$, fetch, betOptions, actions };
}
