import { GAME_ROUTER } from '@/modules/game/router';
import { useLocale } from '@/modules/locale/model/locale';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { gameService } from '@/services/game';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useGameVariantTranslate() {
    const dialog = useVDialog();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const { localeSupportedOptions, getListSupported } = useLocale();
    const model = reactive({
        variantId: route.params.id as string,
        locale: null,
        hasData: false,
        title: ''
    });

    const beforeFetch = async () => {
        await getListSupported();
        if (!localeSupportedOptions.value.length) {
            router.push(GAME_ROUTER.VARIANT.INDEX());
            return;
        }
    };

    const fetch = async () => {
        if (model.locale) {
            const response = await gameService.variant.getText(model.variantId, model.locale);
            if (response.code === 200) {
                model.hasData = true;
                model.title = response.data.title;
            } else if (response.code === 404) {
                model.hasData = false;
                model.title = '';
            } else {
                toast.error(response.data);
                router.push(GAME_ROUTER.VARIANT.INDEX());
            }
        }
    };

    const actions = {
        update: async () => {
            const response = await gameService.variant.updateText(model.variantId, model.locale, model.title);
            if (response.code === 200) {
                toast.success('Update successfully', { timeout: 2000 });
                fetch();
            } else {
                toast.error(response.data, { timeout: 2000 });
            }
        },
        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await gameService.variant.remove(model.variantId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, localeSupportedOptions, beforeFetch, fetch, actions };
}
