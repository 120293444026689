
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSwitch, AppAvatarBreadcrumb } from '@/components';
import { useAvatarItemEdit } from '@/modules/avatar/item/model/edit';
import { AVATAR_ROUTER } from '@/modules/avatar/router';

export default defineComponent({
    name: 'AvatarItemEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSwitch, AppAvatarBreadcrumb },
    setup() {
        const { model, v$, fetch, actions } = useAvatarItemEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, AVATAR_ROUTER };
    }
});
