import { useLocale } from '@/modules/locale/model/locale';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePromotionItemTranslate() {
    const dialog = useVDialog();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const { localeSupportedOptions, getListSupported } = useLocale();

    const model = reactive({
        itemId: route.params.id,
        locale: null,
        title: '',
        description: '',
        hasData: false
    });

    const validations = {
        title: { required },
        locale: { required },
        description: {}
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        v$.value.$reset();

        const response = await promotionService.text.get(model.itemId, model.locale);
        if (response.code === 200) {
            model.hasData = true;
            model.title = response.data.title;
            model.description = response.data.description;
        } else if (response.code === 404) {
            model.hasData = false;
            model.title = '';
            model.description = '';
        } else {
            router.push(PROMOTION_ROUTER.ITEM.INDEX());
        }
    };

    const actions = {
        updateText: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await promotionService.text.update(
                model.itemId,
                model.locale,
                model.title,
                model.description
            );

            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },
        removeText: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.text.remove(model.itemId, model.locale);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    watch(() => model.locale, fetch);

    return { model, v$, fetch, getListSupported, actions, localeSupportedOptions };
}
