
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard, AppSelect, AppDateTimePicker } from '@/components';
import { usePromotionCodeAdd } from '@/modules/promotion/code/model/add';

export default defineComponent({
    name: 'PromotionCodeAdd',
    components: { AppFormRow, AppInput, AppButton, AppCard, AppSelect, AppDateTimePicker },
    setup() {
        const { model, v$, create, Options } = usePromotionCodeAdd();

        return { model, v$, create, Options };
    }
});
