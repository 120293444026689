
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppSimpleTable, AppButton } from '@/components';
import { usePromotionItemDetail } from '@/modules/promotion/item/model/detail';
import { formatDate, renderOption } from '@/utils/helpers';
import { Options } from '@/modules/promotion/item/model/mixins';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';

export default defineComponent({
    name: 'PromotionItemDetail',
    components: { AppCard, AppSimpleTable, AppButton },
    setup() {
        const { model, fetch, actions } = usePromotionItemDetail();

        onMounted(async () => {
            await fetch();
        });

        return { model, Options, renderOption, formatDate, actions, PROMOTION_ROUTER };
    }
});
