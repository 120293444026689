
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton } from '@/components';
import { useArenaItemDetail } from '@/modules/arena/item/model/detail';
import { formatDate, renderOption } from '@/utils/helpers';
import { ArenaTicketTypeList } from '@/modules/arena/ticket/model/mixins';
import { ArenaStatusList } from '@/modules/arena/item/model/mixins';

import { GAME_ROUTER } from '@/modules/game/router';
import { ARENA_ROUTER } from '@/modules/arena/router';

export default defineComponent({
    name: 'ArenaDetail',
    components: { AppCard, AppButton },
    setup() {
        const { model, fetch, actions } = useArenaItemDetail();

        onMounted(async () => {
            await fetch();
        });

        return {
            model,
            formatDate,
            actions,
            ArenaTicketTypeList,
            ArenaStatusList,
            renderOption,
            ARENA_ROUTER,
            GAME_ROUTER
        };
    }
});
