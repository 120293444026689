import { SUBSCRIPTION_ROUTER } from '@/modules/subscription/router';
import { subscriptionService } from '@/services/subscription';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export default function () {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: route.params.id as string,
        name: '',
        packageId: '',
        priceId: '',
        price: 0,
        maxReceivableTime: '',
        cycleChip: 0,
        timeUnit: 0,
        timeDuration: 0,
        totalLinked: null,
        startTime: new Date(),
        canDelete: false,
        canEdit: false
    });

    const fetch = async () => {
        const response = await subscriptionService().item.get(model.id);
        if (response.code === 200) {
            const result = response.data;
            result.startTime = new Date(result.startTime);
            Object.assign(model, result);
        } else {
            toast.error('Not found');
            router.push(SUBSCRIPTION_ROUTER.INDEX());
        }
    };

    const remove = async () => {
        const response = await subscriptionService().item.remove(model.id);
        if (response.code === 200) {
            toast.success('Delete successfully');
            router.push(SUBSCRIPTION_ROUTER.INDEX());
        }
    };

    return { model, fetch, remove };
}
