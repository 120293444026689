import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const NewsAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/news/view/add.vue');
const NewsContent = () => import(/* webpackChunkName: "exoty" */ '@/modules/news/view/content.vue');
const NewsEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/news/view/edit.vue');
const NewsList = () => import(/* webpackChunkName: "exoty" */ '@/modules/news/view/index.vue');
const NewsTranslate = () => import(/* webpackChunkName: "exoty" */ '@/modules/news/view/translate.vue');

export default [
    {
        path: '/app/news',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'NewsList',
                component: NewsList,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/edit',
                name: 'NewsEdit',
                component: NewsEdit,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/content',
                name: 'NewsContent',
                component: NewsContent,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'NewsAdd',
                component: NewsAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/clone',
                name: 'NewsClone',
                component: NewsAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/translate',
                name: 'NewsTranslate',
                component: NewsTranslate,
                meta: { requiresAuth: true }
            }
        ]
    }
];

export const NEWS_ROUTER = {
    INDEX: () => router.resolve({ name: 'NewsList' }),
    ADD: () => router.resolve({ name: 'NewsAdd' }),
    CLONE: (id: any) => router.resolve({ name: 'NewsClone', params: { id } }),
    EDIT: (id: any) => router.resolve({ name: 'NewsEdit', params: { id } }),
    CONTENT: (id: any) => router.resolve({ name: 'NewsContent', params: { id } }),
    TRANSLATE: (id: any) => router.resolve({ name: 'NewsTranslate', params: { id } })
};
