import { ITEM_ROUTER } from '@/modules/item/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { itemService } from '@/services/item';
import { App } from '@/store/applications';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useItemEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const toast = useToast();

    const model = reactive({
        linkableApps: [],
        itemId: route.params.itemId,
        selectedAppId: null,
        startTime: null,
        endTime: null,
        canLink: false,
        canUnlink: false,
        canLinkNext: false,
        canUnlinkNext: false
    });

    const beforeFetch = async () => {
        const response = await itemService.item.getLinkableApp(model.itemId);
        if (response.code === 200) {
            // TODO: get applications from server instead of
            store.getters['application/apps'].forEach((app: App) => {
                if (response.data.appIds.includes(app.id)) {
                    model.linkableApps.push({ label: app.name, value: app.id });
                }
            });
        } else {
            router.push({ name: 'Home' });
        }
    };

    const fetch = async () => {
        if (model.selectedAppId && model.linkableApps.length) {
            const response = await itemService.item.getApp(model.itemId, model.selectedAppId);
            if (response.code === 200) {
                const data = response.data;
                const now = new Date().getTime();

                model.canLink = false;
                model.canUnlink = false;
                model.canLinkNext = false;
                model.canUnlinkNext = false;
                model.startTime = null;
                model.endTime = null;

                if (data.startTime) {
                    model.startTime = new Date(data.startTime).toString();
                }

                if (data.endTime) {
                    if (data.endTime > now) {
                        model.canUnlink = true;
                    }

                    model.endTime = new Date(data.endTime).toString();
                } else {
                    model.canUnlink = true;
                    model.canUnlinkNext = true;
                }
            } else if (response.code === 404 || response.code === 400) {
                model.canLink = true;
                model.canUnlink = false;
                model.canLinkNext = true;
                model.canUnlinkNext = false;
                model.startTime = null;
                model.endTime = null;
            } else {
                toast.error(response.data);
                router.push(ITEM_ROUTER.SYSTEM.INDEX());
            }
        }
    };

    const actions = {
        link: () => {
            dialog.confirm({
                message: 'Are you sure you want to link this app?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await itemService.item.addForApp(model.itemId, model.selectedAppId);
                        if (response.code === 200) {
                            toast.success('Link successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        unlink: () => {
            dialog.confirm({
                message: 'Are you sure you want to unlink this app?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await itemService.item.removeFromApp(model.itemId, model.selectedAppId);
                        if (response.code === 200) {
                            toast.success('Unlink successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        linkNext: () => {
            dialog.confirm({
                message: 'Are you sure you want to link in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await itemService.item.addForAppNext(model.itemId, model.selectedAppId);
                        if (response.code === 200) {
                            toast.success('Link successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        unlinkNext: () => {
            dialog.confirm({
                message: 'Are you sure you want to unlink in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await itemService.item.removeFromAppNext(model.itemId, model.selectedAppId);
                        if (response.code === 200) {
                            toast.success('Unlink successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, beforeFetch, fetch, actions };
}
