import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { duplicateService } from '@/services/duplicate';
import { gameService } from '@/services/game';
import { timeUnits } from '@/utils/helpers';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useDuplicateItemDetail() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const bets = {} as any;

    const beforeFetch = async () => {
        const [variantResponse, betResponse] = await Promise.all([
            gameService.variant.list(),
            gameService.bet.list({}, null)
        ]);

        if (variantResponse.code !== 200 || betResponse.code !== 200) {
            const errMessage = variantResponse.code !== 200 ? variantResponse.data : betResponse.data;
            toast.error(errMessage);
            router.push({ name: 'Home' });
            return;
        }

        const variants = variantResponse.data;

        betResponse.data
            .filter((bet: any) => bet.type === 3)
            .forEach((bet: any) => {
                const variant = variants.find((vari: any) => vari.id === bet.variantId);
                if (variant) {
                    bets[bet.id] = `${variant.name} - ${bet.name}`;
                }
            });
    };

    const model = reactive({
        id: route.params.id,
        name: null,
        type: 0,
        betId: '',
        betName: '',
        cost: 0,
        prizes: '',
        round: 0,
        game: 1,
        timeUnit: 0,
        timeDuration: 0,
        prepareSeconds: 0,
        openSeconds: 0,
        closeSeconds: 0,
        rankingSeconds: 0,
        status: 0,
        publishTime: null,
        unpublishTime: null,
        thumbnail: null,
        thumbnailFile: null,
        thumbnailStatus: null,
        canDelete: false
    });

    const fetch = async () => {
        const response = await duplicateService.item.get(model.id);
        if (response.code === 200) {
            const data = response.data;
            data.betName = bets[data.betId];

            Object.assign(model, data);
        } else {
            toast.error(response.data);
            router.push(DUPLICATE_ROUTER.ITEM.INDEX());
        }
    };

    const actions = {
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await duplicateService.item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push({ name: 'DuplicateItemList' });
                        }
                    }
                }
            });
        }
    };

    return { model, beforeFetch, fetch, actions, timeUnits };
}
