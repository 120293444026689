import { useArenaItemMixins } from '@/modules/arena/item/model/mixins';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { arenaService } from '@/services/arena';
import { useFetchList } from '@/utils/useFetch';
import { useStore } from 'vuex';

export function useArenaList() {
    const store = useStore();
    const arenaItemStore = {
        getPage: () => store.getters['arenaItem/page'],
        setPage: (page: number) => store.dispatch('arenaItem/changePage', page)
    };

    const { fetchAllBets, betMap } = useGameBetList();
    const { itemList, total, currentPage, changePage } = useFetchList(arenaService.item.list, {
        pagingStore: arenaItemStore
    });

    const fetchListIncludedBetName = async (page: number = currentPage.value) => {
        await changePage(page);

        itemList.value = itemList.value.map(item => {
            return {
                ...item,
                betName: betMap.value.get(item.betId)?.label ?? ''
            };
        });
    };

    const { archive, disable, enable, remove } = useArenaItemMixins().arenaActionMixins;
    const actions = {
        archive: (id: any) => archive(id, fetchListIncludedBetName),
        disable: (id: any) => disable(id, fetchListIncludedBetName),
        enable: (id: any) => enable(id, fetchListIncludedBetName),
        remove: (id: any) => remove(id, fetchListIncludedBetName)
    };

    return { itemList, total, currentPage, fetchAllBets, fetchListIncludedBetName, actions };
}
