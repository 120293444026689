
import { defineComponent, onMounted } from 'vue';
import { AppCard } from '@/components';
import { useQuestBoostEdit } from '@/modules/quest/boost/model/edit';
import { formatDate, renderTimeUnit } from '@/utils/helpers';

export default defineComponent({
    name: 'QuestBoostDetail',
    components: { AppCard },
    setup() {
        const { model, fetch } = useQuestBoostEdit();

        onMounted(async () => {
            await fetch();
        });

        return { model, formatDate, renderTimeUnit };
    }
});
