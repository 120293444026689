import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const SystemItemAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/system/view/add.vue');
const SystemItemEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/system/view/edit.vue');
const SystemItemList = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/system/view/index.vue');
const SystemItemLink = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/system/view/link.vue');
const SystemItemTranslate = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/system/view/translate.vue');
const AppItemSettingAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/app/setting/view/add.vue');
const AppItemSettingEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/app/setting/view/edit.vue');
const AppItemSetting = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/app/setting/view/index.vue');

export default [
    {
        path: '/system/item',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'SystemItemList',
                component: SystemItemList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'SystemItemAdd',
                component: SystemItemAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':itemId/edit',
                name: 'SystemItemEdit',
                component: SystemItemEdit,
                meta: { requiresAuth: true }
            },
            {
                path: ':itemId/link',
                name: 'SystemItemLink',
                component: SystemItemLink,
                meta: { requiresAuth: true }
            },
            {
                path: ':itemId/translate',
                name: 'SystemItemTranslate',
                component: SystemItemTranslate,
                meta: { requiresAuth: true }
            }
        ]
    },
    {
        path: '/app/item/setting',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'AppItemSettingList',
                component: AppItemSetting,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'AppItemSettingAdd',
                component: AppItemSettingAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                name: 'AppItemSettingEdit',
                component: AppItemSettingEdit,
                meta: { requiresAuth: true }
            }
        ]
    }
];

export const ITEM_ROUTER = {
    SYSTEM: {
        INDEX: () => router.resolve({ name: 'SystemItemList' }),
        ADD: () => router.resolve({ name: 'SystemItemAdd' }),
        SPECIAL_SHOP: (feature: number, data: number) =>
            router.resolve({ name: 'SystemItemAdd', query: { feature, data } }),
        EDIT: (itemId: any) => router.resolve({ name: 'SystemItemEdit', params: { itemId } }),
        LINK: (itemId: any) => router.resolve({ name: 'SystemItemLink', params: { itemId } }),
        TRANSLATE: (itemId: any) => router.resolve({ name: 'SystemItemTranslate', params: { itemId } })
    },
    APP: {
        SETTING: {
            INDEX: () => router.resolve({ name: 'AppItemSettingList' }),
            ADD: () => router.resolve({ name: 'AppItemSettingAdd' }),
            EDIT: (id: any) => router.resolve({ name: 'AppItemSettingEdit', params: { id } })
        }
    }
};
