import { GAME_ROUTER } from '@/modules/game/router';
import { useGameVariantList } from '@/modules/game/variant/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { gameService } from '@/services/game';
import { validateFormat } from '@/utils/validatorHelpers';
import { helpers, integer, minValue, required, requiredIf } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useMixins() {
    const dialog = useVDialog();
    const toast = useToast();
    const router = useRouter();
    const { itemList: variantList, fetchList: getVariantList } = useGameVariantList();

    const model = reactive({
        id: null,
        variantId: null,
        variantOptions: [],
        name: '',
        type: 1,
        cost: 0,
        maxRound: 0,
        maxPoint: 0,
        tablePotPercent: 0,
        tableCreditRatio: '',
        levelTimeout: 0,
        findingMatchTimeout: 0,
        winLoseGroup: false,
        allowInvitation: false,
        lockType: 0,
        cycle: 0,
        fromSubcycle: '',
        toSubcycle: '',
        canDelete: false,
        canEdit: false
    });

    const editValidations = {
        variantId: { required },
        name: { required },
        type: { required },
        cost: { required, integer, minValue: minValue(0) },
        maxRound: { required, integer, minValue: minValue(0) },
        maxPoint: { required },
        winLoseGroup: { required },
        allowInvitation: { required },
        levelTimeout: { required, integer, minValue: minValue(0) },
        findingMatchTimeout: { integer, minValue: minValue(0) },
        lockType: { required },
        cycle: { required: requiredIf(() => model.lockType == 2 || model.lockType == 3) },
        fromSubcycle: {
            required: requiredIf(() => model.lockType == 2 || model.lockType == 3),
            validateFormat: helpers.withMessage(`Format incorrect`, (value: string) =>
                validateCycleFormat(model.cycle, value)
            ),
            validateTime: helpers.withMessage(`Value invalid`, (value: string) => validateCycleTime(model.cycle, value))
        },
        toSubcycle: {
            required: requiredIf(() => model.lockType == 2 || model.lockType == 3),
            validateFormat: helpers.withMessage(`Format incorrect`, (value: string) =>
                validateCycleFormat(model.cycle, value)
            ),
            validateTime: helpers.withMessage(`Value invalid`, (value: string) =>
                validateCycleTime(model.cycle, value, toSecond(model.fromSubcycle))
            )
        }
    };

    const addValidations = {
        ...editValidations,
        tablePotPercent: { required, integer, minValue: minValue(0) },
        tableCreditRatio: { format: validateFormat(/^\d+(;\d+)*$/) }
    };

    const beforeFetch = async () => {
        await getVariantList();
        model.variantOptions = variantList.value.map((item: any) => {
            return { label: item.name, value: item.id };
        });
    };

    const fetch = async (id: any) => {
        const response = await gameService.bet.get(id);
        if (response.code == 200) {
            const result = response.data;
            model.id = result.id;
            model.variantId = result.variantId;
            model.name = result.name;
            model.type = result.type;
            model.cost = result.cost;
            model.maxRound = result.maxRound;
            model.maxPoint = result.maxPoint;
            model.tablePotPercent = result.tablePotPercent;
            model.tableCreditRatio = result.tableCreditRatio;
            model.levelTimeout = result.levelTimeout;
            model.findingMatchTimeout = result.findingMatchTimeout;
            model.winLoseGroup = result.winLoseGroup;
            model.allowInvitation = result.allowInvitation;
            model.lockType = result.lockType;
            model.cycle = result.cycle;
            model.fromSubcycle = toTime(result.fromSubcycle, result.cycle);
            model.toSubcycle = toTime(result.toSubcycle, result.cycle);
            model.canDelete = result.canDelete;
            model.canEdit = result.canEdit;
        } else {
            toast.error(response.data, { timeout: 2000 });
            router.go(-1);
        }
    };

    const remove = async (id: any) => {
        dialog.confirm({
            message: 'Are you sure you want to remove this item?',
            onConfirm: async (result: boolean) => {
                if (result) {
                    const response = await gameService.bet.remove(id);
                    if (response.code === 200) {
                        router.push(GAME_ROUTER.BET.INDEX(model.variantId));
                        toast.success('Remove successfully', { timeout: 2000 });
                    }
                }
            }
        });
    };

    return { model, options, addValidations, editValidations, beforeFetch, fetch, remove, toTime, toSecond };
}

const options = {
    betTypes: [
        { label: 'Normal', value: 1, background: 'badge-info' },
        { label: 'Premium', value: 2, background: 'badge-warning' },
        { label: 'Duplicate', value: 3, background: 'badge-success' },
        { label: 'Scenario', value: 4, background: 'badge-primary' }
    ],
    lockTypes: [
        { value: 0, label: 'Visible', background: 'badge-info' },
        { value: 1, label: 'Hidden', background: 'badge-secondary' },
        { value: 2, label: 'TimeEvent', background: 'badge-success' },
        { value: 3, label: 'TimeVisible', background: 'badge-warning' }
    ],
    cycles: [
        { value: 1, label: 'Hour', background: 'badge-success' },
        { value: 2, label: 'Day', background: 'badge-success' }
    ],
    render: (dataList: Array<any>, value: any) => {
        const type = dataList.find(item => item.value === value);
        return `<span class='badge ${type.background}'>${type.label}</span>`;
    }
};

function toTime(value: any, mode: any) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    value = parseInt(value);

    let minute = Math.floor(value / 60);
    const hour = Math.floor(minute / 60);
    minute = minute - hour * 60;

    date.setUTCHours(hour);
    date.setUTCMinutes(minute);

    const minutes = date.getMinutes();
    if (Number(mode) === 1) {
        return (minutes < 10 ? '0' : '') + minutes;
    } else if (Number(mode) === 2) {
        return date.getHours() + ':' + (minutes < 10 ? '0' : '') + minutes;
    } else {
        return '';
    }
}

function toSecond(value: string, fromValue: any = null) {
    if (!value) {
        return 0;
    }

    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const arr = value.split(':');
    let result;
    if (arr.length === 1) {
        date.setMinutes(parseInt(arr[0]));

        result = date.getUTCMinutes() * 60;
    } else {
        date.setHours(parseInt(arr[0]));
        date.setMinutes(parseInt(arr[1]));

        result = (date.getUTCHours() * 60 + date.getUTCMinutes()) * 60;

        if (fromValue) {
            fromValue = parseInt(fromValue);

            if (result < fromValue) {
                date.setUTCDate(date.getUTCDate() + 1);
            }
        }

        result = (date.getUTCHours() * 60 + date.getUTCMinutes()) * 60;
    }

    return result;
}

function validateCycleFormat(cycle: number, value: string) {
    switch (cycle) {
        case 1:
            return /^[0-5]?[0-9]$/.test(value);
        case 2:
            return /^([01]?\d|2[0-3]):([0-5]?\d)?$/g.test(value);
    }
    return true;
}

function validateCycleTime(cycle: number, value: string, fromValue: number = -1) {
    if (cycle == 1 || cycle == 2) {
        const limitUnit = cycle == 2 ? 24 * 60 * 60 : 60 * 60;
        const secondValue = toSecond(value);
        if (secondValue < 0 || secondValue > limitUnit || secondValue <= fromValue) {
            return false;
        }
    }
    return true;
}
