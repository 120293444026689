import { useVDialog } from '@/plugins/dialog/v-dialog';
import versionService from '@/services/version';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useVersionList() {
    const store = useStore();
    const toast = useToast();
    const dialog = useVDialog();

    const versionStore = {
        getPage: () => store.getters['version/page'],
        setPage: (page: number) => store.dispatch('version/changePage', page),
        getStore: () => store.getters['version/store'],
        setStore: (storeId: number) => store.dispatch('version/changeStore', storeId)
    };
    const storeId = ref(versionStore.getStore());

    const { itemList, total, currentPage, changePage } = useFetchList(versionService.list, {
        pagingStore: versionStore,
        options: storeId,
        onSuccess: () => versionStore.setStore(storeId)
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await versionService.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        drop: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to drop this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await versionService.drop(id);
                        if (response.code === 200) {
                            toast.success('Drop successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        undrop: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to undrop this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await versionService.undrop(id);
                        if (response.code === 200) {
                            toast.success('Undrop successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        activate: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to active this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await versionService.activate(id);
                        if (response.code === 200) {
                            toast.success('Active successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    return { storeId, itemList, total, currentPage, changePage, actions };
}
