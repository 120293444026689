import { useVDialog } from '@/plugins/dialog/v-dialog';
import { surpriseService } from '@/services/surprise';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useSurpriseItemList() {
    const dialog = useVDialog();
    const route = useRoute();
    const toast = useToast();
    const store = useStore();

    const categoryId = ref(route.params.categoryId);

    const surpriseItemStore = {
        getPage: () => store.getters['surpriseItem/page'],
        setPage: (page: number) => store.dispatch('surpriseItem/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(surpriseService.item.list, {
        pagingStore: surpriseItemStore,
        options: categoryId
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await surpriseService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        } else {
                            toast.error(response.data, { timeout: 2000 });
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, changePage, actions, categoryId };
}
