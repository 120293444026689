import { HOME_PAGE_ROUTER } from '@/modules/main/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { piggybankService } from '@/services/piggybank';
import { validateDateAfter } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePiggybankBoost() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        ratio: 0,
        status: null,
        endTime: null,
        startTime: null,
        image: null,
        imageStatus: 0,
        imageFile: null,
        thumbnail: null,
        thumbnailStatus: 0,
        thumbnailFile: null,
        canDelete: false,
        canDisable: false,
        canEdit: true,
        canEnable: false
    });

    const validations = {
        startTime: { required },
        endTime: { required, validateDate: validateDateAfter('startTime') },
        ratio: { required, integer, min: minValue(101) },
        imageFile: {},
        thumbnailFile: {}
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await piggybankService.boost.get();
        if (response.code === 200) {
            response.data.startTime = new Date(response.data.startTime);
            response.data.endTime = new Date(response.data.endTime);
            Object.assign(model, response.data);
        } else if (response.code === 404) {
            model.canEdit = true;
            model.status = null;
            model.ratio = 0;
            model.image = null;
            model.imageStatus = 0;
            model.imageFile = null;
            model.thumbnail = null;
            model.thumbnailStatus = 0;
            model.thumbnailFile = null;
            model.endTime = null;
            model.startTime = null;
            model.canDelete = false;
            model.canDisable = false;
            model.canEdit = true;
            model.canEnable = false;
        } else {
            toast.error(response.data);
            router.push(HOME_PAGE_ROUTER());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            let response;
            if (model.canEdit) {
                response = await piggybankService.boost.update(
                    model.startTime.getTime(),
                    model.endTime.getTime(),
                    model.ratio,
                    model.imageFile,
                    model.thumbnailFile
                );
            } else {
                response = await piggybankService.boost.updateImage(model.imageFile, model.thumbnailFile);
            }

            if (response?.code === 200) {
                toast.success('Update successfully');
                fetch();
            } else {
                toast.error(response.data);
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await piggybankService.boost.remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetch();
                            v$.value.$reset();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        disable: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await piggybankService.boost.disable();
                        if (response.code === 200) {
                            toast.success('Disable successfully', { timeout: 2000 });
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enable: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await piggybankService.boost.enable();
                        if (response.code === 200) {
                            toast.success('Enable successfully', { timeout: 2000 });
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
