import { QUEST_ROUTER } from '@/modules/quest/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { questService } from '@/services/quest';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useQuestTypeDetail() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const typeId = route.params.typeId;

    const model = reactive({
        name: null,
        displayType: 0,
        timeUnit: 0,
        timeDuration: null,
        openCycle: null,
        restCycle: null,
        startTime: null,
        nextStartTime: null,
        canDelete: false,
        canEnable: false,
        canDisable: false,
        canDisableNext: false
    });

    const fetch = async () => {
        const response = await questService.type.get(typeId);
        if (response.code === 200) {
            response.data.startTime = new Date(response.data.startTime);
            response.data.nextStartTime = new Date(response.data.nextStartTime);
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(QUEST_ROUTER.TYPE.INDEX());
        }
    };

    const actions = {
        disable: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this type?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.type.disable(typeId);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        disableNext: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this type in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.type.disable(typeId);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enableNext: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this type in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.type.enableNext(typeId);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.type.remove(typeId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(QUEST_ROUTER.TYPE.INDEX());
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, fetch, actions };
}
