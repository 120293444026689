import { CREDIT_ROUTER } from '@/modules/credit/ticket/router';
import { creditService } from '@/services/credit';
import useVuelidate from '@vuelidate/core';
import { minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useCreditTicketEdit() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        name: '',
        amount: 0
    });

    const validations = {
        name: { required },
        amount: { required, numeric, min: minValue(1) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async (id: any) => {
        const response = await creditService.ticket.get(id);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data, { timeout: 2000 });
            router.push(CREDIT_ROUTER.TICKET.INDEX());
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await creditService.ticket.update(model.id, model.name, model.amount);
        if (response.code === 200) {
            toast.success('Create successfully', { timeout: 2000 });
            router.push(CREDIT_ROUTER.TICKET.INDEX());
        } else {
            toast.error(response.data, { timeout: 2000 });
        }
    };

    return { model, v$, update, fetch };
}
