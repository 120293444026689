
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppImageHolder, AppFileUpload, AppSelect, AppCard, AppButton } from '@/components';
import { useAvatarGender } from '@/modules/avatar/gender/model/gender';

export default defineComponent({
    name: 'GenderAvatar',
    components: { AppFormRow, AppImageHolder, AppFileUpload, AppSelect, AppCard, AppButton },
    setup() {
        const { model, v$, fetch, actions, genderList } = useAvatarGender();

        onMounted(() => {
            fetch();
        });

        return { v$, model, fetch, actions, genderList };
    }
});
