import { GAME_ROUTER } from '@/modules/game/router';
import { gameService } from '@/services/game';
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function useGameVariantEdit() {
    const toast = useToast();
    const router = useRouter();
    const { model, options, editValidations, beforeFetch, fetch, remove, toSecond } = useMixins();
    const v$ = useVuelidate(editValidations, model);

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const fromSubcycle = toSecond(model.fromSubcycle);
            const toSubcycle = toSecond(model.toSubcycle, fromSubcycle);
            const response = await gameService.bet.update(
                model.id,
                model.name,
                model.levelTimeout,
                model.findingMatchTimeout,
                model.winLoseGroup,
                model.allowInvitation,
                model.lockType,
                model.cycle,
                fromSubcycle,
                toSubcycle
            );

            if (response.code === 200) {
                toast.success('Update successfully', { timeout: 2000 });
                router.push(GAME_ROUTER.BET.INDEX(model.variantId));
            }
        },
        remove: () => remove(model.id),

        changeLockType: () => {
            if (Number(model.lockType) !== 2 && Number(model.lockType) !== 3) {
                model.cycle = null;
                model.fromSubcycle = '';
                model.toSubcycle = '';
            }
        },

        changeCycle: () => {
            model.fromSubcycle = '';
            model.toSubcycle = '';
        }
    };

    return { model, v$, options, beforeFetch, fetch, actions };
}
