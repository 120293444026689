import { useAppList } from '@/modules/apps/model/list';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { promotionService } from '@/services/promotion';
import { rfmService } from '@/services/rfm';
import { validateDateAfter } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePromotionItemAdd() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const { appOption, selectedAppId } = useAppList();

    const model = reactive({
        cloneId: route.params?.id ?? null,
        forAppId: selectedAppId.value,
        name: '',
        status: null,
        displayType: 0,
        fromTime: null,
        toTime: null,
        expectedPackage: 0,
        image: '',
        imageStatus: null,
        imageFile: null,
        prototypeId: null,
        prototypeOptions: [],
        prototypeList: [],
        personas: []
    });

    const validations = {
        name: { required },
        fromTime: { required },
        toTime: { required, validDate: validateDateAfter('fromTime') },
        displayType: { required },
        expectedPackage: { integer, minValue: minValue(0) },
        prototypeId: { required, integer, minValue: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await actions.loadPrototypes(selectedAppId.value);

        // clone case:
        if (model.cloneId) {
            const response = await promotionService.item.get(model.cloneId);
            if (response.code === 200) {
                const result = response.data;
                model.name = result.name;
                model.cloneId = result.id;
                model.displayType = result.displayType;
                model.fromTime = new Date(result.fromTime);
                model.toTime = new Date(result.toTime);
                model.expectedPackage = result.expectedPackage;
                model.image = result.image;
                model.prototypeId = result.usedPrototypeId;
            } else {
                toast.error('Not found');
                router.push(PROMOTION_ROUTER.ITEM.INDEX());
            }
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }
            const forAppId = model.cloneId ? model.forAppId : null;
            const response = await promotionService.item.create(
                model.name,
                model.cloneId,
                forAppId,
                model.fromTime.getTime(),
                model.toTime.getTime(),
                model.displayType,
                model.expectedPackage,
                model.prototypeId,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(PROMOTION_ROUTER.ITEM.INDEX());
            }
        },
        loadPrototypes: async (forAppId: number) => {
            if (!forAppId) {
                return;
            }

            model.personas = [];
            model.prototypeId = null;

            let personaList: Array<any> = [];
            const protoRequest = await promotionService.prototype.listAll(forAppId);
            if (protoRequest.code === 200) {
                model.prototypeList = protoRequest.data;
            }

            const personaRequest = await rfmService().persona.listForOtherApp(forAppId);
            if (personaRequest.code === 200) {
                personaList = personaRequest.data;
            }

            model.prototypeOptions = [];
            model.prototypeList.forEach(function (item: any) {
                item.personas.forEach(function (per1: any) {
                    per1.rfmPersonaName = personaList.find((per2: any) => per2.id === per1.rfmPersonaId)?.name ?? '';
                });
                model.prototypeOptions.push({ value: item.id, label: item.name });
            });
        },
        changePrototype: (selectedPrototype: number) => {
            model.personas = model.prototypeList.find(item => item.id === selectedPrototype)?.personas ?? [];
        }
    };

    watch(() => model.forAppId, actions.loadPrototypes);
    watch(() => model.prototypeId, actions.changePrototype);

    return { model, v$, fetch, appOption, actions };
}
