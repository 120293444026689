import { creditService } from '@/services/credit';
import { rubyService } from '@/services/ruby';
import { useFetchList } from '@/utils/useFetch';
import { reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { TransactionType } from './mixins';

export function useAccountTransaction() {
    const store = useStore();
    const route = useRoute();

    const model = reactive({
        accountId: route.params.accountId,
        type: route.query?.type ? Number(route.query?.type) : null,

        get displayInfo() {
            switch (this.type) {
                case TransactionType.Credit:
                    return { label: 'Credit', field: 'credit', title: 'Credit' };
                case TransactionType.Payment:
                    return { label: 'Credit', field: 'credit', title: 'Payment' };
                case TransactionType.Ruby:
                    return { label: 'Ruby', field: 'ruby', title: 'Ruby' };
                default:
                    return { label: '', field: '', title: '' };
            }
        }
    });

    const transactionStore = {
        getPage: () => store.getters['accountTransaction/page'],
        setPage: (page: number) => store.dispatch('accountTransaction/changePage', page)
    };

    const request = () => {
        if (!model.accountId || model.type == null) {
            return;
        }

        if (model.type === TransactionType.Credit) {
            return creditService.transaction.list;
        } else if (model.type === TransactionType.Payment) {
            return creditService.transaction.listPayment;
        } else if (model.type === TransactionType.Ruby) {
            return rubyService.transaction.list;
        }
    };

    const { itemList, total, currentPage, changePage } = useFetchList(request(), {
        pagingStore: transactionStore,
        options: toRef(model, 'accountId')
    });

    return { model, itemList, total, currentPage, changePage };
}
