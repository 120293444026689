
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppImageHolder } from '@/components';
import useChallenge from '@/modules/challenge/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { CHALLENGE_ROUTER } from '@/modules/challenge/router';

export default defineComponent({
    name: 'ChallengeList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, fetchList, actions } = useChallenge();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'Id', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Amount', field: 'amount' },
                { label: 'To App', field: 'toApp' },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Status', display: (row: any) => renderStatusColumn(row) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: CHALLENGE_ROUTER.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        href: CHALLENGE_ROUTER.TRANSLATE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable,
                        onclick: () => actions.enable(row.id)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, CHALLENGE_ROUTER };
    }
});

const statusType = [
    { label: 'Disable', value: 0, background: 'badge-secondary' },
    { label: 'Enable', value: 1, background: 'badge-info' },
    { label: 'Started', value: 2, background: 'badge-success' },
    { label: 'Ended', value: 3, background: 'badge-warning' }
];
const renderStatusColumn = (row: any) => {
    let status = statusType.find(item => item.value === row.status);
    return `<span class='badge ${status.background}'>${status.label}</span>`;
};
