import { CHAT_ROUTER } from '@/modules/chat/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { chatService } from '@/services/chat';
import useVuelidate from '@vuelidate/core';
import { integer, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useMixins() {
    const toast = useToast();
    const router = useRouter();
    const dialog = useVDialog();

    const model = reactive({
        id: null,
        name: '',
        position: null,
        canDelete: false
    });

    const validations = {
        name: { required },
        position: { required, integer }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async (id: any) => {
        const response = await chatService.sentence.get(id);
        if (response.code == 200) {
            model.id = response.data.id;
            model.name = response.data.name;
            model.position = response.data.position;
            model.canDelete = response.data.canDelete;
        } else {
            toast.error(response.data, { timeout: 2000 });
            router.push(CHAT_ROUTER.SENTENCE.INDEX());
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await chatService.sentence.create(model.name, model.position);
            if (response.code === 200) {
                toast.success('Create successfully', { timeout: 2000 });
                router.push(CHAT_ROUTER.SENTENCE.INDEX());
            }
        },

        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await chatService.sentence.update(model.id, model.name, model.position);
            if (response.code === 200) {
                toast.success('Update successfully', { timeout: 2000 });
                router.push(CHAT_ROUTER.SENTENCE.INDEX());
            }
        },

        remove: async (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.sentence.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
