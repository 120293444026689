
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { useStore } from 'vuex';
import { AppTable, AppCard, AppFormRow, AppSelect, AppButton } from '@/components';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { GAME_ROUTER } from '@/modules/game/router';

export default defineComponent({
    name: 'TranslationList',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppButton },
    setup() {
        const store = useStore();
        const { itemList, total, options, fetch, fetchVariantList, variantOptions, variantId, remove } =
            useGameBetList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Type', display: (row: any) => options.render(options.betTypes, row.type) },
                { label: 'Cost', field: 'cost' },
                { label: 'Max round', field: 'maxRound' },
                { label: 'Max point', field: 'maxPoint' },
                { label: 'Lock type', display: (row: any) => options.render(options.lockTypes, row.lockType) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: GAME_ROUTER.BET.DETAIL(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: GAME_ROUTER.BET.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchVariantList();
            await fetch();
        });

        return { table, fetch, store, options, variantOptions, variantId, GAME_ROUTER };
    }
});
