import { useVDialog } from '@/plugins/dialog/v-dialog';
import { activityService } from '@/services/activity';
import { applicationService } from '@/services/applications';
import { chatService } from '@/services/chat';
import { creditService } from '@/services/credit';
import { rfmService } from '@/services/rfm';
import { statisticsService } from '@/services/statistics';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAccountDetail() {
    const dialog = useVDialog();
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const model = reactive({
        accountId: Number(route.params.accountId),
        account: {} as any,
        lastLogin: {} as any,
        avgPayment: {} as any,
        rfmSegmentation: {} as any,
        onlineTime: 0
    });

    const fetch = async () => {
        const [accountResponse, avgPaymentResponse, segmentationResponse, loginResponse, statsResponse] =
            await Promise.all([
                applicationService.account.get(model.accountId),
                creditService.getAvgPayment(model.accountId),
                rfmService().segmentation.get(model.accountId),
                activityService.account.getLastLogin(model.accountId),
                statisticsService.stats(model.accountId)
            ]);

        if (accountResponse.code === 200) {
            model.account = accountResponse.data;
        } else {
            router.go(-1);
        }

        if (avgPaymentResponse.code === 200) {
            model.avgPayment = avgPaymentResponse.data;
        }

        if (segmentationResponse.code === 200) {
            model.rfmSegmentation = segmentationResponse.data;
        }

        if (loginResponse.code === 200) {
            model.lastLogin = loginResponse.data;
        }

        if (statsResponse.code === 200) {
            model.onlineTime = Number(statsResponse.data) * 1000;
        }
    };

    const actions = {
        delete: () => {
            dialog.confirm({
                message: 'Are you sure you want to delete this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.delete(model.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        undelete: () => {
            dialog.confirm({
                message: 'Are you sure you want to undelete this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.undelete(model.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        ban: () => {
            dialog.confirm({
                message: 'Are you sure you want to ban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.ban(model.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        unban: () => {
            dialog.confirm({
                message: 'Are you sure you want to unban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.unban(model.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        mute: () => {
            dialog.confirm({
                message: 'Are you sure you want to mute this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.mute(model.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        unmute: () => {
            dialog.confirm({
                message: 'Are you sure you want to unmute this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.unmute(model.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        banChat: () => {
            dialog.confirm({
                message: 'Are you sure you want to ban this account from chat?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.ban(model.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        unbanChat: () => {
            dialog.confirm({
                message: 'Are you sure you want to unban this account from chat?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.unban(model.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, fetch, actions };
}
