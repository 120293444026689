import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const SubscriptionItemAdd = () => import(/* webpackChunkName: "exoty" */ './item/view/add.vue');
const SubscriptionItemDetail = () => import(/* webpackChunkName: "exoty" */ './item/view/detail.vue');
const SubscriptionItemEdit = () => import(/* webpackChunkName: "exoty" */ './item/view/edit.vue');
const SubscriptionItemList = () => import(/* webpackChunkName: "exoty" */ './item/view/index.vue');
const SubscriptionItemTranslate = () => import(/* webpackChunkName: "exoty" */ './item/view/translate.vue');

export default [
    {
        path: '/app/subscription/item',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'SubscriptionItemList',
                component: SubscriptionItemList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'SubscriptionItemAdd',
                component: SubscriptionItemAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                name: 'SubscriptionItemEdit',
                component: SubscriptionItemEdit,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/clone',
                name: 'SubscriptionItemClone',
                component: SubscriptionItemAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/detail',
                name: 'SubscriptionItemDetail',
                component: SubscriptionItemDetail,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/translate',
                name: 'SubscriptionItemTranslate',
                component: SubscriptionItemTranslate,
                meta: { requiresAuth: true }
            }
        ]
    }
];

export const SUBSCRIPTION_ROUTER = {
    INDEX: () => router.resolve({ name: 'SubscriptionItemList' }),
    ADD: () => router.resolve({ name: 'SubscriptionItemAdd' }),
    EDIT: (id: any) => router.resolve({ name: 'SubscriptionItemEdit', params: { id } }),
    CLONE: (id: any) => router.resolve({ name: 'SubscriptionItemClone', params: { id } }),
    DETAIL: (id: any) => router.resolve({ name: 'SubscriptionItemDetail', params: { id } }),
    TRANSLATE: (id: any) => router.resolve({ name: 'SubscriptionItemTranslate', params: { id } })
};
