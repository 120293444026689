import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { Options } from './mixins';

export function useMixins() {
    const dialog = useVDialog();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const model = reactive({
        id: route.params.id,
        feature: null,
        data: null,
        name: '',
        code: '',
        status: null,
        quantity: 0,
        receiveLimit: -1,
        receiveCounter: 0,
        startTime: new Date(),
        endTime: new Date(),
        canEnable: false,
        canDisable: false,
        canDelete: false
    });

    const fetch = async () => {
        const response = await promotionService.code.get(model.id);
        if (response.code == 200) {
            const result = response.data;
            model.id = result.id;
            model.feature = result.feature;
            model.data = result.data;
            model.name = result.name;
            model.code = result.code;
            model.status = result.status;
            model.quantity = result.quantity;
            model.receiveLimit = result.receiveLimit;
            model.receiveCounter = result.receiveCounter;
            model.startTime = new Date(result.startTime);
            model.endTime = new Date(result.endTime);
            model.canEnable = result.canEnable;
            model.canDisable = result.canDisable;
            model.canDelete = result.canDelete;
        } else {
            toast.error(response.data);
            router.push(PROMOTION_ROUTER.CODE.INDEX());
        }
    };

    const actions = {
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(PROMOTION_ROUTER.CODE.INDEX());
                        }
                    }
                }
            });
        },
        enable: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.enable(model.id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            await fetch();
                        }
                    }
                }
            });
        },
        disable: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.disable(model.id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            await fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, Options, fetch, actions };
}
