import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');

const SysAvatarGender = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/gender/view/gender.vue');
const SysAvatarImport = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/import/view/import.vue');

const SysAvatarBundleAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/bundle/view/add.vue');
const SysAvatarBundleEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/bundle/view/edit.vue');
const SysAvatarBundleList = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/bundle/view/index.vue');
const SysAvatarBundleItem = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/bundle/view/item.vue');

const SysAvatarCategoryAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/category/view/add.vue');
const SysAvatarCategoryEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/category/view/edit.vue');
const SysAvatarCategoryList = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/category/view/index.vue');

const SysAvatarColorAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/color/view/add.vue');
const SysAvatarColorEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/color/view/edit.vue');
const SysAvatarColorList = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/color/view/index.vue');

const SysAvatarDefaultAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/default/view/add.vue');
const SysAvatarDefaultEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/default/view/edit.vue');
const SysAvatarDefaultList = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/default/view/index.vue');

const SysAvatarLayerAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/layer/view/add.vue');
const SysAvatarLayerEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/layer/view/edit.vue');
const SysAvatarLayerList = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/layer/view/index.vue');

const SysAvatarItemAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/item/view/add.vue');
const SysAvatarItemEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/item/view/edit.vue');
const SysAvatarItemList = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/item/view/index.vue');
const SysAvatarItemImageAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/item/image/view/add.vue');
const SysAvatarItemImageEdit = () => import(/* webpackChunkName:"exoty" */ '@/modules/avatar/item/image/view/edit.vue');
const SysAvatarItemImageList = () => import(/*webpackChunkName:"exoty" */ '@/modules/avatar/item/image/view/index.vue');

const SysAvatarBackgroundAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/avatar/background/view/add.vue');
const SysAvatarBackgroundEdit = () => import(/*webpackChunkName: "exoty"*/ '@/modules/avatar/background/view/edit.vue');
const SysAvatarBackgroundList = () => import(/*webpackChunkName:"exoty"*/ '@/modules/avatar/background/view/index.vue');

export default [
    {
        path: '/system/avatar/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'gender',
                name: 'SysAvatarGender',
                component: SysAvatarGender,
                meta: { requiresAuth: true }
            },
            {
                path: 'import',
                name: 'SysAvatarImport',
                component: SysAvatarImport,
                meta: { requiresAuth: true }
            },
            {
                path: 'category',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'SysAvatarCategoryList',
                        component: SysAvatarCategoryList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'SysAvatarCategoryAdd',
                        component: SysAvatarCategoryAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':categoryId/edit',
                        name: 'SysAvatarCategoryEdit',
                        component: SysAvatarCategoryEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'bundle',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'SysAvatarBundleList',
                        component: SysAvatarBundleList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'SysAvatarBundleAdd',
                        component: SysAvatarBundleAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':bundleId/edit',
                        name: 'SysAvatarBundleEdit',
                        component: SysAvatarBundleEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':bundleId/item',
                        name: 'SysAvatarBundleItem',
                        component: SysAvatarBundleItem,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'default',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'SysAvatarDefaultList',
                        component: SysAvatarDefaultList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'SysAvatarDefaultAdd',
                        component: SysAvatarDefaultAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':avatarId/edit',
                        name: 'SysAvatarDefaultEdit',
                        component: SysAvatarDefaultEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'color',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'SysAvatarColorList',
                        component: SysAvatarColorList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'SysAvatarColorAdd',
                        component: SysAvatarColorAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':colorId/edit',
                        name: 'SysAvatarColorEdit',
                        component: SysAvatarColorEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'layer',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'SysAvatarLayerList',
                        component: SysAvatarLayerList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'SysAvatarLayerAdd',
                        component: SysAvatarLayerAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':layerId/edit',
                        name: 'SysAvatarLayerEdit',
                        component: SysAvatarLayerEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'item',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'SysAvatarItemList',
                        component: SysAvatarItemList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'SysAvatarItemAdd',
                        component: SysAvatarItemAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':itemId/edit',
                        name: 'SysAvatarItemEdit',
                        component: SysAvatarItemEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':itemId/image',
                        component: EmptyRouterView,
                        meta: { requiresAuth: true },
                        children: [
                            {
                                path: '',
                                name: 'SysAvatarItemImageList',
                                component: SysAvatarItemImageList,
                                meta: { requiresAuth: true }
                            },
                            {
                                path: 'add',
                                name: 'SysAvatarItemImageAdd',
                                component: SysAvatarItemImageAdd,
                                meta: { requiresAuth: true }
                            },
                            {
                                path: ':imageId/edit',
                                name: 'SysAvatarItemImageEdit',
                                component: SysAvatarItemImageEdit,
                                meta: { requiresAuth: true }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'background',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'SysAvatarBackgroundList',
                        component: SysAvatarBackgroundList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'SysAvatarBackgroundAdd',
                        component: SysAvatarBackgroundAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'SysAvatarBackgroundEdit',
                        component: SysAvatarBackgroundEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const AVATAR_ROUTER = {
    GENDER: () => router.resolve({ name: 'SysAvatarGender' }),
    IMPORT: () => router.resolve({ name: 'SysAvatarImport' }),
    CATEGORY: {
        INDEX: (query?: { parentId?: any; name?: string }) =>
            router.resolve({ name: 'SysAvatarCategoryList', query: query }),
        SUB: (parentId: any) => router.resolve({ name: 'SysAvatarCategoryList', query: { parentId } }),
        ADD: (parentId?: any) => router.resolve({ name: 'SysAvatarCategoryAdd', query: { parentId } }),
        EDIT: (categoryId: any) => router.resolve({ name: 'SysAvatarCategoryEdit', params: { categoryId } })
    },
    BUNDLE: {
        INDEX: () => router.resolve({ name: 'SysAvatarBundleList' }),
        ADD: () => router.resolve({ name: 'SysAvatarBundleAdd' }),
        EDIT: (bundleId: any) => router.resolve({ name: 'SysAvatarBundleEdit', params: { bundleId } }),
        ITEM: (bundleId: any) => router.resolve({ name: 'SysAvatarBundleItem', params: { bundleId } })
    },
    DEFAULT: {
        INDEX: () => router.resolve({ name: 'SysAvatarDefaultList' }),
        ADD: () => router.resolve({ name: 'SysAvatarDefaultAdd' }),
        EDIT: (avatarId: any) => router.resolve({ name: 'SysAvatarDefaultEdit', params: { avatarId } })
    },
    COLOR: {
        INDEX: () => router.resolve({ name: 'SysAvatarColorList' }),
        ADD: () => router.resolve({ name: 'SysAvatarColorAdd' }),
        EDIT: (colorId: any) => router.resolve({ name: 'SysAvatarColorEdit', params: { colorId } })
    },
    LAYER: {
        INDEX: () => router.resolve({ name: 'SysAvatarLayerList' }),
        ADD: () => router.resolve({ name: 'SysAvatarLayerAdd' }),
        EDIT: (layerId: any) => router.resolve({ name: 'SysAvatarLayerEdit', params: { layerId } })
    },
    ITEM: {
        INDEX: (query?: { categoryId?: any; name?: string }) =>
            router.resolve({ name: 'SysAvatarItemList', query: query }),
        ADD: (categoryId: any) => router.resolve({ name: 'SysAvatarItemAdd', query: { categoryId } }),
        EDIT: (itemId: any) => router.resolve({ name: 'SysAvatarItemEdit', params: { itemId } }),
        IMAGE: {
            INDEX: (itemId?: any) => router.resolve({ name: 'SysAvatarItemImageList', params: { itemId } }),
            ADD: (itemId: any) => router.resolve({ name: 'SysAvatarItemImageAdd', params: { itemId } }),
            EDIT: (itemId: any, imageId: any) =>
                router.resolve({ name: 'SysAvatarItemImageEdit', params: { itemId, imageId } })
        }
    },
    BACKGROUND: {
        INDEX: () => router.resolve({ name: 'SysAvatarBackgroundList' }),
        ADD: () => router.resolve({ name: 'SysAvatarBackgroundAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'SysAvatarBackgroundEdit', params: { id } })
    }
};
