import { IAP_ROUTER } from '@/modules/iap/router';
import { iapService } from '@/services/iap';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export default function () {
    const router = useRouter();
    const toast = useToast();

    const { v$, model, features } = useMixins();

    const fetch = async (id: any) => {
        const response = await iapService.get(id);
        if (response.code === 200) {
            const result = response.data;
            model.id = result.id;
            model.name = result.name;
            model.packageId = result.packageId;
            model.priceId = result.priceId;
            model.price = result.price || 0;
            model.feature = result.feature || 0;
            model.credit = result.credit || 0;
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await iapService.update(
            model.id,
            model.name,
            model.feature,
            model.packageId,
            model.priceId,
            model.price,
            model.credit
        );

        if (response.code === 200) {
            toast.success('Update successfully');
            router.push(IAP_ROUTER.INDEX());
        }
    };

    return { v$, model, features, fetch, update };
}
