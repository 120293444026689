
import { defineComponent } from 'vue';
import { AppInput, AppButton } from '@/components';
import { useLogin } from '@/modules/login/login';
import { FORGOT_PASSWORD } from '@/modules/forgot-password/router';

export default defineComponent({
    name: 'Login',
    components: { AppInput, AppButton },

    setup() {
        const { model, v$, login } = useLogin();

        return { v$, model, login, FORGOT_PASSWORD };
    }
});
