import { useVDialog } from '@/plugins/dialog/v-dialog';
import { tournamentService } from '@/services/tournament';
import { useFetchList } from '@/utils/useFetch';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useTournamentLeaderboardList() {
    const dialog = useVDialog();
    const toast = useToast();
    const store = useStore();

    const tournamentLeaderboardStore = {
        getPage: () => store.getters['tournamentLeaderboard/page'],
        setPage: (page: number) => store.dispatch('tournamentLeaderboard/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(tournamentService.leaderboard.list, {
        pagingStore: tournamentLeaderboardStore
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await tournamentService.leaderboard.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            changePage(currentPage.value);
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, changePage, actions };
}
