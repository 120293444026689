import { LEAGUE_ROUTER } from '@/modules/league/router';
import { leagueService } from '@/services/league';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useLeagueTierAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        name: '',
        tier: 0,
        point: 0,
        imageFile: null
    });

    const validations = {
        name: { required },
        tier: { required, numeric, min: minValue(1), max: maxValue(3200) },
        point: { required, numeric, min: minValue(0), max: maxValue(3200) }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const itemId = route.params.itemId;

        const response = await leagueService.tier.create(itemId, model.tier, model.name, model.point, model.imageFile);

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(LEAGUE_ROUTER.TIER.INDEX(itemId));
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, create };
}
