import { LEAGUE_ROUTER } from '@/modules/league/router';
import { leagueService } from '@/services/league';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useLeagueRewardEdit() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        itemId: null,
        tier: null,
        position: null,
        tierReward: 0,
        starReward: 0,
        rubyReward: 0
    });

    const validations = {
        position: { required, numeric, min: minValue(0), max: maxValue(3200) },
        tierReward: { required, numeric, min: minValue(0), max: maxValue(3200) },
        starReward: { required, numeric, min: minValue(0), max: maxValue(3200) },
        rubyReward: { required, numeric, min: minValue(0), max: maxValue(3200) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const params = route.params;
        const response = await leagueService.reward.get(params.itemId, params.tier, params.position);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(LEAGUE_ROUTER.TIER.REWARD.INDEX(model.itemId, model.tier));
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await leagueService.reward.update(
            model.itemId,
            model.tier,
            model.position,
            model.tierReward,
            model.starReward,
            model.rubyReward
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(LEAGUE_ROUTER.TIER.REWARD.INDEX(model.itemId, model.tier));
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, fetch, update };
}
