import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer bg-white border border-light" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_ctx.model.id)
    ? (_openBlock(), _createBlock(_component_app_card, {
        key: 0,
        class: "col-lg-8 mx-auto",
        label: "Update Chat Sentence"
      }, {
        default: _withCtx(() => [
          _createElementVNode("form", _hoisted_1, [
            _createVNode(_component_app_form_row, { label: "Name" }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  modelValue: _ctx.model.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
                  validations: _ctx.v$.name
                }, null, 8, ["modelValue", "validations"])
              ]),
              _: 1
            }),
            _createVNode(_component_app_form_row, { label: "Position" }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  type: "number",
                  modelValue: _ctx.model.position,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.position) = $event)),
                  validations: _ctx.v$.position
                }, null, 8, ["modelValue", "validations"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_app_button, {
                  name: "Update",
                  theme: "info",
                  icon: "fa-save",
                  disabled: !_ctx.v$.$anyDirty,
                  onClick: _withModifiers(_ctx.actions.update, ["prevent"])
                }, null, 8, ["disabled", "onClick"]),
                _createVNode(_component_app_button, {
                  name: "Translate",
                  icon: "fa-language",
                  theme: "info",
                  href: _ctx.CHAT_ROUTER.SENTENCE.TRANSLATE(_ctx.model.id)
                }, null, 8, ["href"]),
                (_ctx.model.canDelete)
                  ? (_openBlock(), _createBlock(_component_app_button, {
                      key: 0,
                      name: "Delete",
                      icon: "fa-trash-alt",
                      theme: "danger",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.actions.remove(_ctx.model.id)))
                    }))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}