import { useAppList } from '@/modules/apps/model/list';
import { usePromotionPrototypeList } from '@/modules/promotion/prototype/model/list';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { useRfmPersonaList } from '@/modules/rfm/persona/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { PromotionItemFilter } from '@/store/promotion/item';
import { useFetchList } from '@/utils/useFetch';
import FileSaver from 'file-saver';
import { computed, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function usePromotionItemList() {
    const dialog = useVDialog();
    const toast = useToast();
    const store = useStore();

    const { currentAppInfo } = useAppList();
    const { personaOptions, fetchPersonaOptions } = useRfmPersonaList();
    const { itemAll: prototypeAll, fetchAll: fetchPrototypeAll } = usePromotionPrototypeList();

    const beforeFetch = async () => {
        await Promise.all([fetchPersonaOptions(), fetchPrototypeAll()]);
    };

    const promotionItemStore = {
        getPage: () => store.getters['promotionItem/page'],
        setPage: (page: number) => store.dispatch('promotionItem/changePage', page),
        getFilter: () => store.getters['promotionItem/filter'] as PromotionItemFilter,
        changeFilter: () => store.dispatch('promotionItem/changeFilter', { ...filter.value })
    };

    const filter = computed((): PromotionItemFilter => promotionItemStore.getFilter());

    const { itemList, total, currentPage, changePage } = useFetchList(promotionService.item.list, {
        pagingStore: promotionItemStore,
        options: filter,
        onSuccess: () => promotionItemStore.changeFilter()
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        export: async () => {
            const response = await promotionService.item.export();
            if (response.code === 200) {
                const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
                FileSaver.saveAs(blob, currentAppInfo.value?.name.replace(/\s/g, '') + 'Promotion.csv');
            }
        },
        resetFilter: () => {
            filter.value.status = -1;
            filter.value.rfmPersonaId = null;
        },
        getPrototypeLink: (itemId: number) => PROMOTION_ROUTER.PROTOTYPE.DETAIL(itemId)?.href ?? '',
        getPrototypeById: (itemId: number) => prototypeAll.value.find(item => item.id === itemId),
        getPrototypeName: (itemId: number) => {
            const prototype = actions.getPrototypeById(itemId);
            return itemId + (prototype ? ' - ' + prototype.name : '');
        }
    };

    watch(() => filter.value.type, actions.resetFilter);

    return {
        itemList,
        total,
        currentPage,
        changePage,
        actions,
        filter,
        beforeFetch,
        personaOptions,
        prototypeAll
    };
}
