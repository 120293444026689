import { GAME_ROUTER } from '@/modules/game/router';
import { gameService } from '@/services/game';
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function useGameVariantAdd() {
    const router = useRouter();
    const toast = useToast();
    const { model, options, addValidations, toSecond, beforeFetch } = useMixins();
    const v$ = useVuelidate(addValidations, model);

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await gameService.bet.create(
                model.variantId,
                model.name,
                model.type,
                model.cost,
                model.maxRound,
                model.maxPoint,
                model.tablePotPercent,
                model.tableCreditRatio,
                model.levelTimeout,
                model.findingMatchTimeout,
                model.winLoseGroup,
                model.allowInvitation,
                model.lockType,
                model.cycle,
                toSecond(model.fromSubcycle),
                toSecond(model.toSubcycle)
            );

            if (response.code === 200) {
                toast.success('Update successfully', { timeout: 2000 });
                router.push(GAME_ROUTER.BET.INDEX(model.variantId));
            }
        },

        changeBetType: () => {
            if (model.type == 3) {
                model.lockType = 1;
            }
        },

        changeCycle: () => {
            model.fromSubcycle = '';
            model.toSubcycle = '';
        }
    };

    return { model, v$, options, beforeFetch, actions };
}
