import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const PiggybankAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/piggybank/view/add.vue');
const PiggybankBoost = () => import(/* webpackChunkName: "exoty" */ '@/modules/piggybank/view/boost.vue');
const PiggybankEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/piggybank/view/edit.vue');
const PiggybankList = () => import(/* webpackChunkName: "exoty" */ '@/modules/piggybank/view/index.vue');
const PiggybankTranslate = () => import(/* webpackChunkName: "exoty" */ '@/modules/piggybank/view/translate.vue');

export default [
    {
        path: '/app/piggybank',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'boost',
                name: 'PiggybankBoost',
                component: PiggybankBoost,
                meta: { requiresAuth: true }
            },
            {
                path: 'item',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PiggybankList',
                        component: PiggybankList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PiggybankAdd',
                        component: PiggybankAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'PiggybankEdit',
                        component: PiggybankEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/clone',
                        name: 'PiggybankClone',
                        component: PiggybankAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/translate',
                        name: 'PiggybankTranslate',
                        component: PiggybankTranslate,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const PIGGYBANK_ROUTER = {
    BOOST: () => router.resolve({ name: 'PiggybankBoost' }),
    ITEM: {
        INDEX: () => router.resolve({ name: 'PiggybankList' }),
        ADD: () => router.resolve({ name: 'PiggybankAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'PiggybankEdit', params: { id } }),
        CLONE: (id: any) => router.resolve({ name: 'PiggybankClone', params: { id } }),
        TRANSLATE: (id: any) => router.resolve({ name: 'PiggybankTranslate', params: { id } })
    }
};
