import { SCRATCH_ROUTER } from '@/modules/scratch/router';
import { scratchTicketService } from '@/services/scratch';
import { timeUnits } from '@/utils/helpers';
import { validateDateAfter, validateFormat } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { minValue, numeric, required, requiredIf } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const { get: getTicketService, update: updateTicketService } = scratchTicketService();

export function useTicketEdit() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        freeLimit: null,
        type: null,
        cost: null,
        price: null,
        packageId: null,
        adsView: null,
        quantity: null,
        baseValue: 0,
        payoutRate: null,
        adsPayoutRate: null,
        displayRate: null,
        timeUnit: null,
        timeDuration: null,
        startTime: new Date(),
        endTime: null
    });

    const validations = {
        freeLimit: { required, numeric, minValue: minValue(0) },
        quantity: { required, numeric, minValue: minValue(0) },
        payoutRate: {
            required,
            validateFormat: validateFormat(
                /^\d+(.\d+)?:\d+(.\d+)?(,\d+(.\d+)?:\d+(.\d+)?)*$/g,
                `Please enter in the correct format: X1:Y1,X2:Y2... Ex: 0.3:10,4:100`
            )
        },
        adsPayoutRate: {
            requiredIf: requiredIf(() => model.type == 1),
            validateFormat: validateFormat(
                /^\d+(.\d+)?:\d+(.\d+)?(,\d+(.\d+)?:\d+(.\d+)?)*$/g,
                `Please enter in the correct format: X1:Y1,X2:Y2... Ex: 0.3:10,4:100`
            )
        },
        displayRate: {
            required,
            validateFormat: validateFormat(
                /^\d+(,\d+)*$/g,
                `Please enter in the correct format: Ex: 2,3,5 [in integer numbers]`
            )
        },
        endTime: { validateDateAfter: validateDateAfter('startTime') }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async (id: any) => {
        const response = await getTicketService(id);
        if (response.code === 200) {
            response.data.startTime = new Date(response.data.startTime);
            response.data.endTime = response.data.endTime ? new Date(response.data.endTime) : null;

            Object.assign(model, response.data);
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await updateTicketService(
            model.id,
            model.quantity,
            model.freeLimit,
            model.payoutRate,
            model.adsPayoutRate,
            model.displayRate,
            model.endTime ? model.endTime.getTime() : null
        );

        if (response.code === 200) {
            toast.success('Update successfully', { timeout: 2000 });
            router.push(SCRATCH_ROUTER.TICKET.INDEX());
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, timeUnits, fetch, update };
}
