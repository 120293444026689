
import { defineComponent, onMounted } from 'vue';
import { AppCard } from '@/components';
import { useActivityDetail } from '@/modules/activity/model/detail';
import { formatDate, renderOption } from '@/utils/helpers';
import { TaskOption } from '@/modules/activity/model/mixins';

export default defineComponent({
    name: 'ActivityDetail',
    components: { AppCard },
    setup() {
        const { model, fetch } = useActivityDetail();

        onMounted(async () => {
            await fetch();
        });

        return { model, formatDate, renderOption, TaskOption };
    }
});
