import { BONUS_ROUTER } from '@/modules/bonus/router';
import { bonusService } from '@/services/bonus';
import { validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useBonusLoginAds() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        name: '',
        value: '',
        adsBoost: ''
    });

    const validations = {
        adsBoost: { required, format: validateFormat(/^\d+:\d+(,\d+:\d+)*$/) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await bonusService.login.get(route.params.id);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push({ name: 'BonusLoginList' });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await bonusService.login.updateAdsBoost(model.id, model.adsBoost);
            if (response.code === 200) {
                toast.success('Update successfully');
                router.push(BONUS_ROUTER.LOGIN.INDEX());
            } else {
                toast.error(response.data);
            }
        }
    };

    return { model, v$, fetch, actions };
}
