
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { usePiggybankList } from '@/modules/piggybank/model/list';
import { PIGGYBANK_ROUTER } from '@/modules/piggybank/router';

export default defineComponent({
    name: 'PiggybankList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, fetchList, actions } = usePiggybankList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Price', field: 'price' },
                { label: 'Value', field: 'value' },
                { label: 'From Avg Payment (c)', field: 'fromAvgPayment' },
                { label: 'To Avg Payment (c)', field: 'toAvgPayment' },
                { label: 'Current Uses', field: 'totalLinked' },
                {
                    label: 'Thumbnail',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.thumbnail, status: row.imageStatus }
                    })
                },
                { label: 'Status', display: (row: any) => renderStatus(row.status) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: PIGGYBANK_ROUTER.ITEM.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-copy',
                        theme: 'info',
                        tooltip: 'Clone',
                        href: PIGGYBANK_ROUTER.ITEM.CLONE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        href: PIGGYBANK_ROUTER.ITEM.TRANSLATE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable,
                        onclick: () => actions.enable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, PIGGYBANK_ROUTER };
    }
});

const statusList = [
    { label: 'Disabled', value: 3, background: 'warning' },
    { label: 'Cleaning', value: 2, background: 'warning' },
    { label: 'Enabled', value: 1, background: 'success' },
    { label: 'None', value: 0, background: 'secondary' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
