import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const ProgressList = () => import(/* webpackChunkName: "exoty" */ '@/modules/progress/view/index.vue');
const ProgressEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/progress/view/edit.vue');
const ProgressAddOrClone = () => import(/* webpackChunkName: "exoty" */ '@/modules/progress/view/add.vue');
const ProgressLevelAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/progress/level/view/add.vue');
const ProgressLevelEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/progress/level/view/edit.vue');
const ProgressLevelList = () => import(/* webpackChunkName: "exoty" */ '@/modules/progress/level/view/index.vue');
const ProgressLevelTranslate = () =>
    import(/* webpackChunkName: "exoty" */ '@/modules/progress/level/view/translate.vue');

export default [
    {
        path: '/app/progress',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'ProgressList',
                component: ProgressList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'ProgressAdd',
                component: ProgressAddOrClone,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/clone',
                name: 'ProgressClone',
                component: ProgressAddOrClone,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/edit',
                name: 'ProgressEdit',
                component: ProgressEdit,
                meta: { requiresAuth: true }
            },
            {
                path: ':progressId/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: 'level',
                        name: 'ProgressLevelList',
                        component: ProgressLevelList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'item/add',
                        name: 'ProgressLevelAdd',
                        component: ProgressLevelAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'item/:level',
                        name: 'ProgressLevelEdit',
                        component: ProgressLevelEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'item/:level/translate',
                        name: 'ProgressLevelTranslate',
                        component: ProgressLevelTranslate,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const PROGRESS_ROUTER = {
    ITEM: {
        INDEX: () => router.resolve({ name: 'ProgressList' }),
        ADD: () => router.resolve({ name: 'ProgressAdd' }),
        CLONE: (id: any) => router.resolve({ name: 'ProgressClone', params: { id } }),
        EDIT: (id: any) => router.resolve({ name: 'ProgressEdit', params: { id } })
    },
    LEVEL: {
        INDEX: (progressId: any) => router.resolve({ name: 'ProgressLevelList', params: { progressId } }),
        ADD: (progressId: any) => router.resolve({ name: 'ProgressLevelAdd', params: { progressId } }),
        EDIT: (progressId: any, level: any) =>
            router.resolve({ name: 'ProgressLevelEdit', params: { progressId, level } }),
        TRANSLATE: (progressId: any, level: any) =>
            router.resolve({ name: 'ProgressLevelTranslate', params: { progressId, level } })
    }
};
