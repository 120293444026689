import { TRANSLATION_ROUTER } from '@/modules/translation/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { translationService } from '@/services/translation';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useTranslationAdd() {
    const router = useRouter();
    const toast = useToast();
    const dialog = useVDialog();

    const model = reactive({
        id: null,
        name: ''
    });

    const validations = {
        name: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async (id: any = null) => {
        // update case:
        if (id) {
            const response = await translationService.get(id);
            if (response.code === 200) {
                const result = response.data;
                model.id = result.id;
                model.name = result.name.toString();
            } else {
                toast.error(response.data);
                router.push(TRANSLATION_ROUTER.INDEX());
            }
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await translationService.create(model.name);
            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(TRANSLATION_ROUTER.INDEX());
            }
        },

        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await translationService.update(model.id, model.name);
            if (response.code === 200) {
                toast.success('Update successfully');
                router.push(TRANSLATION_ROUTER.INDEX());
            }
        },

        submit: async () => {
            if (model.id) {
                await actions.update();
            } else {
                await actions.create();
            }
        },

        remove: async (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async result => {
                    if (result) {
                        const response = await translationService.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(TRANSLATION_ROUTER.INDEX());
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
