import { PROGRESS_ROUTER } from '@/modules/progress/router';
import { progressService } from '@/services/progress';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useProgressLevelEdit() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        progressId: route.params.progressId,
        level: route.params.level,
        name: '',
        image: null,
        imageStatus: null,
        imageFile: null
    });

    const validations = {
        name: { required },
        imageFile: {}
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await progressService.level.get(model.progressId, model.level);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(PROGRESS_ROUTER.LEVEL.INDEX(model.progressId));
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await progressService.level.update(model.progressId, model.level, model.name, model.imageFile);
        if (response.code === 200) {
            toast.success('Update successfully');
            router.push(PROGRESS_ROUTER.LEVEL.INDEX(model.progressId));
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, update, fetch };
}
