import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const GameBetAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/game/bet/view/add.vue');
const GameBetDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/game/bet/view/detail.vue');
const GameBetEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/game/bet/view/edit.vue');
const GameBetList = () => import(/* webpackChunkName: "exoty" */ '@/modules/game/bet/view/index.vue');
const GameVariantAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/game/variant/view/add.vue');
const GameVariantList = () => import(/* webpackChunkName: "exoty" */ '@/modules/game/variant/view/index.vue');
const GameVariantTranslate = () => import(/*webpackChunkName:"exoty"*/ '@/modules/game/variant/view/translate.vue');
const GameParameter = () => import(/* webpackChunkName: "exoty" */ '@/modules/game/parameter/view/edit.vue');
const GameReport = () => import(/* webpackChunkName: "exoty" */ '@/modules/game/report/view/edit.vue');

export default [
    {
        path: '/app/game/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'report',
                name: 'GameReport',
                component: GameReport,
                meta: { requiresAuth: true }
            },
            {
                path: 'parameter',
                name: 'GameParameter',
                component: GameParameter,
                meta: { requiresAuth: true }
            },
            {
                path: 'variant',
                name: 'Variant',
                component: EmptyRouterView,
                children: [
                    {
                        path: '',
                        name: 'GameVariantList',
                        component: GameVariantList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'GameVariantAdd',
                        component: GameVariantAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/translate',
                        name: 'GameVariantTranslate',
                        component: GameVariantTranslate,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'bet',
                name: 'Bet',
                component: EmptyRouterView,
                children: [
                    {
                        path: '',
                        name: 'GameBetList',
                        component: GameBetList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'GameBetAdd',
                        component: GameBetAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'GameBetEdit',
                        component: GameBetEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'GameBetDetail',
                        component: GameBetDetail,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const GAME_ROUTER = {
    REPORT: () => router.resolve({ name: 'GameReport' }),
    PARAMETER: () => router.resolve({ name: 'GameParameter' }),
    BET: {
        INDEX: (variantId?: any) => router.resolve({ name: 'GameBetList', query: { variantId } }),
        ADD: (variantId?: any) => router.resolve({ name: 'GameBetAdd', query: { variantId } }),
        EDIT: (id: any) => router.resolve({ name: 'GameBetEdit', params: { id } }),
        DETAIL: (id: any) => router.resolve({ name: 'GameBetDetail', params: { id } })
    },
    VARIANT: {
        INDEX: () => router.resolve({ name: 'GameVariantList' }),
        ADD: () => router.resolve({ name: 'GameVariantAdd' }),
        TRANSLATE: (id: any) => router.resolve({ name: 'GameVariantTranslate', params: { id } })
    }
};
