
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useArenaList } from '@/modules/arena/item/model/list';
import { RouterLink } from 'vue-router';
import { formatDate, renderOption } from '@/utils/helpers';
import { ARENA_ROUTER } from '@/modules/arena/router';
import { GAME_ROUTER } from '@/modules/game/router';
import { ArenaStatusList } from '@/modules/arena/item/model/mixins';

export default defineComponent({
    name: 'ArenaItemList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, currentPage, fetchAllBets, fetchListIncludedBetName, actions } = useArenaList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                {
                    label: 'Bet',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.betName,
                        props: { to: GAME_ROUTER.BET.DETAIL(row.betId) }
                    })
                },
                { label: 'Price', field: 'price' },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'End Time', display: (row: any) => formatDate(row.endTime) },
                { label: 'Status', display: (row: any) => renderOption(ArenaStatusList, row.status) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        href: ARENA_ROUTER.ITEM.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-copy',
                        theme: 'info',
                        tooltip: 'Clone',
                        href: ARENA_ROUTER.ITEM.CLONE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: ARENA_ROUTER.ITEM.DETAIL(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-medal',
                        theme: 'info',
                        tooltip: 'Show medal',
                        href: ARENA_ROUTER.ITEM.MEDAL(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-crown',
                        theme: 'info',
                        tooltip: 'Show ranking',
                        href: ARENA_ROUTER.ITEM.RANK(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable && row.status === 0,
                        onclick: () => actions.enable(row.id)
                    }),
                    group: 2
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    }),
                    group: 3
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-archive',
                        theme: 'warning',
                        tooltip: 'Archive',
                        isShow: row.canArchive,
                        onclick: () => actions.archive(row.id)
                    }),
                    group: 4
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchAllBets();
            await fetchListIncludedBetName(currentPage.value);
        });

        return { table, fetchListIncludedBetName, ARENA_ROUTER };
    }
});
