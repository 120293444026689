
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker } from '@/components';
import { useArenaItemClone } from '@/modules/arena/item/model/clone';
import { ArenaTicketTypeList } from '@/modules/arena/ticket/model/mixins';

export default defineComponent({
    name: 'ArenaItemClone',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker },
    setup() {
        const { model, v$, fetch, betOptions, actions } = useArenaItemClone();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, betOptions, ArenaTicketTypeList };
    }
});
