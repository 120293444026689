import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isSuperUser)
      ? (_openBlock(), _createBlock(_component_app_card, {
          key: 0,
          label: "Function",
          classes: "mx-auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_button, {
              name: "Create New App",
              icon: "fa-plus",
              theme: "info",
              href: _ctx.APPLICATION_ROUTER.ADD()
            }, null, 8, ["href"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_app_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_table, {
          "is-slot-mode": true,
          columns: _ctx.table.columns,
          rows: _ctx.table.rows,
          total: _ctx.table.totalRecordCount,
          actions: _ctx.table.actions,
          "page-size": Number.MAX_SAFE_INTEGER,
          onRefreshData: _ctx.fetchList
        }, null, 8, ["columns", "rows", "total", "actions", "page-size", "onRefreshData"])
      ]),
      _: 1
    })
  ], 64))
}