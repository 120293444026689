
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppCard, AppTable, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useGameVariantList } from '@/modules/game/variant/model/list';
import { GAME_ROUTER } from '@/modules/game/router';

export default defineComponent({
    name: 'GameVariants',
    components: { AppTable, AppButton, AppCard },
    setup() {
        const { itemList, total, fetchList, actions } = useGameVariantList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Min player', field: 'minPlayer' },
                { label: 'Max player', field: 'maxPlayer' },
                { label: 'Has team', display: (row: any) => renderBooleanValue(row.hasTeam) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        href: GAME_ROUTER.VARIANT.TRANSLATE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-dollar-sign',
                        theme: 'info',
                        tooltip: 'Bet',
                        href: GAME_ROUTER.BET.INDEX(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable,
                        onclick: () => actions.enable(row.id)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, GAME_ROUTER };
    }
});

function renderBooleanValue(value: any) {
    return `<span class="badge badge-${value ? 'success' : 'secondary'}">${value ? 'Yes' : 'No'}</span>`;
}
