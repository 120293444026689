
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppCard, AppTable, AppButton, AppSelect, AppFormRow } from '@/components';
import { useRfmMonetaryList } from '@/modules/rfm/monetary/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { RFM_ROUTER } from '@/modules/rfm/router';

export default defineComponent({
    name: 'RfmMonetary',
    components: { AppTable, AppButton, AppSelect, AppFormRow, AppCard },
    setup() {
        const { itemList, total, fetchList, rfmGroupOptions, selectedGroupId, beforeFetch, actions } =
            useRfmMonetaryList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Rfm Group', display: row => rfmGroupOptions.value.find(g => g.value == row.groupId)?.label },
                { label: 'From Payment', field: 'fromPayment' },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: RFM_ROUTER.MONETARY.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await beforeFetch();
            await fetchList();
        });

        return { table, fetchList, rfmGroupOptions, selectedGroupId, RFM_ROUTER };
    }
});
