
import { computed, defineComponent } from 'vue';
import { AppDropdown } from '@/components';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { App } from '@/store/applications';
import { APPLICATION_ROUTER } from '@/modules/apps/router';
import { useAppList } from '@/modules/apps/model/list';

export default defineComponent({
    name: 'ApplicationsDropdown',
    components: { AppDropdown },
    setup() {
        const router = useRouter();
        const store = useStore();

        const isMaintained = computed(() => store.getters['maintenance/isMaintained']);

        const { allApps, selectedAppId, currentAppInfo } = useAppList();
        const appList = computed(() => [...allApps.value, { id: null, name: 'All Application' }] as Array<App>);
        const getApplicationName = computed(() => currentAppInfo.value?.name ?? '');

        async function changeApplication(appId: number) {
            if (appId) {
                store.dispatch('application/changeAppId', appId);
                router.go(0);
            } else {
                router.push(APPLICATION_ROUTER.INDEX());
            }
        }

        return { appList, selectedAppId, getApplicationName, isMaintained, changeApplication };
    }
});
