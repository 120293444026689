import { PROGRESS_ROUTER } from '@/modules/progress/router';
import { progressService } from '@/services/progress';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { ProgressLevelType } from './mixins';

export function useProgressLevelAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const linkableItems = ref([] as Array<{ type: number; itemId: number; itemName: string }>);
    const linkableItemOption = computed(() =>
        linkableItems.value
            .filter(item => model.type && item.type === model.type)
            .map(item => ({ value: item.itemId, label: item.itemName }))
    );

    const model = reactive({
        progressId: route.params.progressId,
        progressName: null,
        name: '',
        type: null,
        reward: null,
        quantity: null,
        itemId: null,
        imageFile: null
    });

    const validations = {
        name: { required },
        type: { required },
        itemId: {
            requiredIf: requiredIf(
                () =>
                    model.type === ProgressLevelType.Promotion.value ||
                    model.type === ProgressLevelType.PromotionBooster.value
            )
        }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        if (!model.progressId) {
            return;
        }

        const [progressItemResponse, linkableItemResponse] = await Promise.all([
            progressService.item.get(model.progressId),
            progressService.level.listLinkableItem(model.progressId)
        ]);

        if (progressItemResponse.code === 200 && linkableItemResponse.code === 200) {
            model.progressName = progressItemResponse.data?.name ?? '';
            linkableItems.value.push(...linkableItemResponse.data);
        } else {
            const message = progressItemResponse.code !== 200 ? progressItemResponse.data : linkableItemResponse.data;
            toast.error(message);
            router.push(PROGRESS_ROUTER.ITEM.INDEX());
            return;
        }
    };

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await progressService.level.create(
            model.progressId,
            model.name,
            model.type,
            model.reward,
            model.quantity,
            model.itemId,
            model.imageFile
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(PROGRESS_ROUTER.LEVEL.INDEX(model.progressId));
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, create, fetch, linkableItemOption };
}
