import { defaultStorePagingProps } from '@/store/mixins';

export type PromotionItemFilter = {
    type: number;
    status: number;
    rfmPersonaId: number;
};

const promotionItemModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: { type: 0, status: -1, rfmPersonaId: null } as PromotionItemFilter
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: { type: number; status: number; rfmPersonaId: number }): void => {
            state.filter = filter;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        changeFilter: (context: any, filter: { type: number; status: number; rfmPersonaId: number }): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): PromotionItemFilter => state.filter
    }
};

export default promotionItemModule;
