import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_avatar_breadcrumb = _resolveComponent("app-avatar-breadcrumb")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_avatar_breadcrumb, {
      "category-id": _ctx.model.categoryId
    }, null, 8, ["category-id"]),
    _createVNode(_component_app_card, {
      label: _ctx.model.itemName,
      classes: "mx-auto"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_app_button, {
          name: "Add image",
          icon: "fa-plus",
          theme: "info",
          href: _ctx.AVATAR_ROUTER.ITEM.IMAGE.ADD(_ctx.model.itemId)
        }, null, 8, ["href"]),
        _createVNode(_component_app_button, {
          name: "Edit item",
          icon: "fa-edit",
          theme: "info",
          href: _ctx.AVATAR_ROUTER.ITEM.EDIT(_ctx.model.itemId)
        }, null, 8, ["href"])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_app_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_table, {
          columns: _ctx.table.columns,
          rows: _ctx.table.rows,
          total: _ctx.table.totalRecordCount,
          page: _ctx.table.page,
          actions: _ctx.table.actions,
          onRefreshData: _ctx.changePage
        }, null, 8, ["columns", "rows", "total", "page", "actions", "onRefreshData"])
      ]),
      _: 1
    })
  ], 64))
}