import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { duplicateService } from '@/services/duplicate';
import { gameService } from '@/services/game';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useDuplicateCycleList() {
    const router = useRouter();
    const toast = useToast();

    const bets = {} as any;
    const store = useStore();
    const duplicateCycleStore = {
        getPage: () => store.getters['duplicateCycle/page'],
        setPage: (page: number) => store.dispatch('duplicateCycle/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(duplicateService.cycle.list, {
        pagingStore: duplicateCycleStore
    });

    const beforeFetch = async () => {
        const [variantResponse, betResponse] = await Promise.all([
            gameService.variant.list(),
            gameService.bet.list({}, null)
        ]);

        if (variantResponse.code !== 200 || betResponse.code !== 200) {
            const errMessage = variantResponse.code !== 200 ? variantResponse.data : betResponse.data;
            toast.error(errMessage);
            router.push(DUPLICATE_ROUTER.ITEM.INDEX());
            return;
        }

        const tmpVariants = variantResponse.data;
        const tmpBets = betResponse.data;

        for (let i = 0; i < tmpBets.length; i++) {
            const bet = tmpBets[i];
            for (let j = 0; j < tmpVariants.length; j++) {
                const variant = tmpVariants[j];
                if (bet.variantId === variant.id) {
                    bets[bet.id] = variant.name + ' - ' + bet.name;
                    break;
                }
            }
        }
    };

    const fetchList = async (page: number) => {
        await changePage(page);

        const itemIds = new Set(itemList.value.map(cycle => cycle.duplicateItemId));
        const itemResponse = await duplicateService.item.getList(Array.from(itemIds).join(','));
        if (itemResponse.code !== 200) {
            router.push(DUPLICATE_ROUTER.ITEM.INDEX());
            return;
        }

        const itemInfo = {} as any;
        itemResponse.data.forEach(
            (item: any) =>
                (itemInfo[item.id] = {
                    itemName: item.name,
                    betName: bets[item.betId],
                    betId: item.betId,
                    itemGame: item.game
                })
        );

        itemList.value = itemList.value.map(cycle => {
            const info = itemInfo[cycle.duplicateItemId];

            return {
                ...cycle,
                betName: info.betName,
                betId: info.betId,
                itemName: info.itemName,
                itemGame: info.itemGame
            };
        });
    };

    return { itemList, total, currentPage, beforeFetch, fetchList };
}
